<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">계약 비용 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="py-4">
            <div class="x_div_end">
              <select class="custom-select ff-eng1 fs-085" v-model="searchType" style="width:200px; height:37px; margin-right: 20px">
                <option value="EM">건축주명 또는 이메일 검색</option>
                <option value="PJ">프로젝트명 검색</option>
              </select>
              <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" @keydown.enter="findPrjList()" v-model="searchKeyword"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem" placeholder="프로젝트 A" ></div>
                <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="findPrjList()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              </div>
            </div>
            <div class="x_div_end">
              <div id="auto_frm" v-show="isSearch" class="p-2" style="display:none; position:absolute; top:235px; width:245px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center ls_bg1">
                  <div class="w-100 text-start py-1 px-2">
                    <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.prjId" class="fa fa-spinner fa-spin" style="color:#333"></i>
                    <a v-else href="javascript:void(0)" @click="callFindPrjWrttnCntrct(item.prjId, item.prjNm)">{{item.prjNm.split(';')[0]}} <br>&nbsp;&nbsp;&nbsp;&nbsp; - {{ item.crrntStts }}</a>
                  </div>
                  <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-3">

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>사업 파트너</strong></span></td>
                  <td v-for="(prc, i) in prcCdList" :key="i" class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{ processNm[i] }}사</strong></span></td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{prjNm ? prjNm.split(';')[0] : ''}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{ownrNm}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{bsnssPrtnrNm}}</span></td>
                  <td v-for="(prc, i) in prcCdList" :key="i" class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{ prtnrNm[i] }}</span></td>
                </tr>
              </table>
            </div>
          </div>
          <div style="height:50px"></div>

          <div v-for="(process, i) in prcCdList" :key="i">
            <div class="d-flex" style="border-bottom:1px solid #d9d9d9">
              <div class="px-2 py-2"><span class="fa-Kor-Noto" style="font-weight:700; font-size:16pt; color:#333333">{{ processNm[i] }}</span></div>
            </div>

            <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>

            <div class="pt-3" v-else>

              <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                <table class="table-borderless w-100">

                  <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>단계</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>비율(%)</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>금액</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>대금요청날짜</strong></span></td>
                    <td class="text-center" ><span class="ff-kor1" style="font-size:12pt"><strong>입금요청날짜</strong></span></td>
                  </tr>

                  <tr v-for="(item, index) in expnsDtoList[i]" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <!-- <tr :key="process[i]+`${index}`" style="height:55px; border-bottom:1px solid #c9c9c9;"> -->
                    <td class="text-center br_0">
                      <span class="ff-kor1" style="font-size:12pt">
                        <strong v-if="index == 0">선급금</strong>
                        <strong v-else-if="index == expnsDtoList[i].length-1">잔금</strong>
                        <strong v-else>{{item.pymntType}} 차 중도금</strong>
                      </span>
                    </td>
                    <td class="text-center br_0">
                      <div v-if="isSaved[i]">
                        <div v-if="!isRateSaved[i] && index != expnsDtoList[i].length-1">
                          <input v-if="item.rates ? expnsDtoList[i][index].rates = item.rates : null" type="text" @input="bindRates($event, index, i)" v-model="expnsDtoList[i][index].rates" class="form-control ff-eng1 text-center" style="width:60px; height:36px; display:block; margin: 0 auto;">
                          <input v-else type="text" @input="bindRates($event, index, i)" v-model="expnsDtoList[i][index].rates" class="form-control ff-eng1 text-center" style="width:60px; height:36px; display:block; margin: 0 auto;">
                        </div>
                        <div v-else>
                          <span class="ff-eng1" style="font-size:10pt">{{expnsDtoList[i][index].rates ? expnsDtoList[i][index].rates : 0}}</span>
                        </div>
                      </div>
                      <div v-else>
                        <span class="ff-eng1" style="font-size:10pt">0</span>
                      </div>
                    </td>
                    <td class="text-center br_0">
                      <span class="ff-eng1" style="font-size:10pt">{{expnsDtoList[i][index].amounts ? Number(expnsDtoList[i][index].amounts).toLocaleString() : 0}}</span>
                    </td>
                    <td class="text-center br_0">
                      <div v-if="index == 0">
                        <span class="ff-eng1" style="font-size:10pt">-</span>
                      </div>
                      <div v-else>
                        <span class="ff-eng1" style="font-size:10pt">{{item.pymntRqstDt ? item.pymntRqstDt.split('T')[0] : ''}}</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <div v-if="index == 0">
                        <span class="ff-eng1" style="font-size:10pt">-</span>
                      </div>
                      <div v-else>
                        <button v-if="item.pymntRqstDt && !item.dpstRqstDt" @click="dpstRqstDt(index, i)" class="fkr-set2 fs-08" style="width:60px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">입금 요청</button>
                        <span v-else class="ff-eng1" style="font-size:10pt">{{item.dpstRqstDt ? item.dpstRqstDt.split('T')[0] : ''}}</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

            </div>

            <div class="x_div_end">
              <div v-if="totalAmounts[i] && !isSaved[i]" class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="addIntrPymnt(i)" style="width:100px; height:37px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000">중도금추가</button></div>
              <div v-if="totalAmounts[i] && !isSaved[i]" class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="dltIntrPymnt(i)" style="width:100px; height:37px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000">중도금삭제</button></div>
              <div v-if="totalAmounts[i] && !isSaved[i]" class="py-2 ps-1"><button class="fkr-set2 fs-095" @click="saveSteps(i)" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">단계저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
              <div v-if="totalAmounts[i] && isSaved[i] && !isRateSaved[i]" class="py-2 ps-1"><button class="fkr-set2 fs-095" @click="saveCntnt(i)" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">내용저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
            </div>

            <div style="height:20px"></div>

          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Api from "@/api";
import Auth from "@/api/auth";

export default {
  name: "CntrctExpnsMng",
  components:{
    AdminLNB,
  },
  data(){
    return{
      isSearch:false,
      prjNm:'',
      prjList:[],
      searchKeyword : '', // 검색 키워드
      searchType:'PJ',
      prcCdList: ['DS','MA','CN','IN','DE'],
      processNm: ['설계', '제조', '시공', '인테리어', '운송'],
      prjId:0,
      processId:[], // default: 0
      isSaved:[], // default: false
      isRateSaved:[], // default: true
      selectDpstDt:[],
      totalAmounts:[],  //총 금액, default: 0
      expnsDtoList:[],  //계약 비용 리스트
      fetching: true,
      submitting: false,
      ownrNm:'',
      bsnssPrtnrNm:'',
      prtnrNm: []
    }
  },
  created() {
    this.prcCdList.forEach(() => {
      this.processId.push(0);
      this.isSaved.push(false);
      this.isRateSaved.push(true);
      this.totalAmounts.push(0);
      this.selectDpstDt.push([]);
      this.expnsDtoList.push([]);
      this.prtnrNm.push('');
    });
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();

    this.fetching = false;
    var searchedPrjId = sessionStorage.getItem('searchedPrjId');
    this.prjNm = sessionStorage.getItem('prjNm');
    if(searchedPrjId) {
      sessionStorage.removeItem('searchedPrjId');
      sessionStorage.removeItem('prjNm');
      await this.findCntrctExpns(searchedPrjId);
    }
  },

  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    callFindPrjWrttnCntrct(prjId, prjNm) {
      sessionStorage.setItem('searchedPrjId', prjId);
      sessionStorage.setItem('prjNm', prjNm);
      location.reload()
    },

    //프로젝트 조회
    async findPrjList(){

      if(!this.searchKeyword){
        alert('프로젝트 검색어를 입력해주세요.');
        return;
      }

      this.isSearch = true;
      this.prjList = [{prjId:null}];
      const rtnval = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: false})
      if(rtnval.result) {
          this.prjList = rtnval.data.findPrjDtoList.sort((a, b) => {
                  return Number(Boolean(b.lastUpdatedPrcInfo.totalAmounts)) - Number(Boolean(a.lastUpdatedPrcInfo.totalAmounts))
                });
          const rtnvalue = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: true})
          if(rtnvalue.result) {
            this.prjList = this.prjList.concat(rtnvalue.data.findPrjDtoList);
          }
      }
    },

    // //대금지급 승인 요청
    // async apprvlRqstDt(index, i){
    //   var rtnval = await Api.post('/api/v1/admin/cntrct/expns/mng/save/' + process[i] + '/apprvl/rqst', {prjId : this.prjId, [process[i]+'Id'] : this.processId[i], pymntType : index});
    //   if(rtnval.result) {
    //     this.expnsDtoList[i][index].apprvlRqstDt = rtnval.data.apprvlRqstDt;
    //     this.$forceUpdate();
    //   }
    // },

    // //대금 지급 확인
    // async pymntDt(index, i){
    //   var rtnval = await Api.post('/api/v1/admin/cntrct/expns/mng/save/' + process[i] + '/pymnt/cnfrm', {prjId : this.prjId, [process[i]+'Id'] : this.processId[i], pymntType : index});
    //   if(rtnval.result) {
    //     this.expnsDtoList[i][index].pymntDt = rtnval.data.pymntDt;
    //     this.$forceUpdate();
    //   }
    // },

    //대금 입금 요청
    async dpstRqstDt(index, i){
      var request = {
          pymntList: []
      }
      const formData = new FormData();
      formData.append("attchmntList", null);
      request.pymntList.push({
          pymntId: this.expnsDtoList[i][index].pymntId,
          dpstRqstDt: new Date().toISOString(),
          editing: false
      })
      formData.append(
          "request",
          new Blob([JSON.stringify(request)], { type: "application/json" })
      );

      const rtnval = await Api.post('/api/v1/payment/set', formData);
      if(rtnval.result) {
        this.findCntrctExpns(this.prjId);
      }
      else {
          alert(rtnval.errormsg)
      }
    },

    // //대금 입금날짜 지정
    // setDpstDt(index, i, date){
    //   this.selectDpstDt[i][index] = new Date(date).toISOString();
    // },

    //대금 비율 계산
    bindRates(event, index, kintex){
      if(event.target.value < 0 || event.target.value > 100){
        alert("0부터 100까지 숫자를 입력해 주세요.");
        this.expnsDtoList[kintex][index].rates = 0;
        return;
      }

      this.expnsDtoList[kintex][index].rates = event.target.value;

      let sumRates = 0;
      let isAutoCalculate = false;
      for(let i = 0; i < this.expnsDtoList[kintex].length-1; i++){
        if(this.expnsDtoList[kintex][i].rates){
          isAutoCalculate = true;
        }
        if(!isAutoCalculate){
          break;
        }
        sumRates += (this.expnsDtoList[kintex][i].rates*1);
      }

      if(isAutoCalculate){
        this.expnsDtoList[kintex][this.expnsDtoList[kintex].length-1].rates = 100-sumRates;

        if(this.totalAmounts[kintex]){
          for(let j = 0; j < this.expnsDtoList[kintex].length; j++){
            this.expnsDtoList[kintex][j].amounts = this.totalAmounts[kintex] / 100 * this.expnsDtoList[kintex][j].rates;
          }
        }
      }
      this.$forceUpdate();
    },

    //계약 비용 관리 조회
    async findCntrctExpns(prjId){
      this.prjId = prjId;
      this.fetching = true;
      let returnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + prjId);
      if(returnvalue.result) {
        this.bsnssPrtnrNm = returnvalue.data.prj.bsnssPrtnrEmail;
        for(var i=0; i < this.prcCdList.length; i++) {
          this.isRateSaved[i] = true;
          const rtnvalue = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.prcCdList[i]});
          if(rtnvalue.result) {
            this.processId[i] = rtnvalue.data.prcId;
            if(!this.ownrNm) {
              this.ownrNm = rtnvalue.data.ownrInfo? rtnvalue.data.ownrInfo.fullName : '';
            }
            this.prtnrNm[i] = rtnvalue.data.prtnrInfo? rtnvalue.data.prtnrInfo.fullName : '';
            this.totalAmounts[i] = rtnvalue.data.totalAmounts;
    
            if(this.processId[i]) {
              const rtnval = await Api.get('/api/v1/payment/get?prcId=' + this.processId[i]);
              
              if(rtnval.result){
                this.expnsDtoList[i] = rtnval.data.pymntList;
      
                if(this.expnsDtoList[i].length == 0){
                  this.isSaved[i] = false;
                  this.addIntrPymnt(i);
                  this.addIntrPymnt(i);
                }else{
                  this.isSaved[i] = true;
                }
    
                for(let j = 0; j < this.expnsDtoList[i].length; j++){
                  if(!this.expnsDtoList[i][j].rates){
                    this.expnsDtoList[i][j].rates = 0;
                    this.isRateSaved[i] = false;
                  }
    
                  if(!this.expnsDtoList[i][j].amounts){
                    this.expnsDtoList[i][j].amounts = 0;
                  }
    
                  // this.selectDpstDt[i].push(this.expnsDtoList[i][j].dpstDt);
                }
              }    
            }
          }
          else {
            alert(rtnvalue.errormsg)
          }
        }
      }
      else {
        alert(returnvalue.errormsg)
      }
      this.fetching = false;
    },

    //중도금 추가
    addIntrPymnt(i){
      if(!this.prjId){
        alert('프로젝트를 선택해주세요.');
        return;
      }

      if(this.expnsDtoList[i].length == 0 || this.expnsDtoList[i].length == 1){
        this.expnsDtoList[i].push({prcId: this.processId[i], pymntId: null, pymntType : this.expnsDtoList[i].length, rates : 0, amounts : 0, pymntRqstDt : '', dpstRqstDt : ''});
      }else if(this.expnsDtoList[i].length >= 2){
        let lastElement = this.expnsDtoList[i].splice(this.expnsDtoList[i].length - 1);
        this.expnsDtoList[i].push({prcId: this.processId[i], pymntId: null, pymntType : this.expnsDtoList[i].length, rates : 0, amounts : 0, pymntRqstDt : '', dpstRqstDt : ''});
        lastElement.pymntType = this.expnsDtoList[i].length;
        this.expnsDtoList[i].push(lastElement);
      }
      this.$forceUpdate();
    },

    //중도금 삭제
    dltIntrPymnt(i){
      if(!this.prjId){
        alert('프로젝트를 선택해주세요.');
        return;
      }

      if(this.expnsDtoList[i].length == 0 || this.expnsDtoList[i].length == 1 || this.expnsDtoList[i].length == 2){
        alert('삭제할 중도금이 없습니다.');
        return;
      }else if(this.expnsDtoList[i].length >= 3){
        let lastElement = this.expnsDtoList[i].splice(this.expnsDtoList[i].length - 1);
        this.expnsDtoList[i].splice(this.expnsDtoList[i].length - 1);
        lastElement.pymntType = this.expnsDtoList[i].length;
        this.expnsDtoList[i].push(lastElement);
        this.$forceUpdate();
      }
    },

    //대금지급 단계 저장
    async saveSteps(i){
      var request = {
          pymntList: []
      }
      const formData = new FormData();
      formData.append("attchmntList", null);
      for(var index = 0; index < this.expnsDtoList[i].length; index++) {
        request.pymntList.push({
            prcId: this.processId[i],
            pymntType: this.expnsDtoList[i][index].pymntType,
            editing: false
        })
      }
      formData.append(
          "request",
          new Blob([JSON.stringify(request)], { type: "application/json" })
      );
      this.submitting = true;
      const rtnval = await Api.post('/api/v1/payment/set', formData);
      if(rtnval.result) {
        await this.findCntrctExpns(this.prjId);
      }
      else {
          alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    //대금지급 내용 저장
    async saveCntnt(i){
      var request = {
          pymntList: []
      }
      const formData = new FormData();
      formData.append("attchmntList", null);
      for(var index = 0; index < this.expnsDtoList[i].length; index++) {
        request.pymntList.push({
            pymntId: this.expnsDtoList[i][index].pymntId,
            rates: this.expnsDtoList[i][index].rates,
            amounts: this.expnsDtoList[i][index].amounts,
            pymntRqstDt: this.expnsDtoList[i][index].pymntRqstDt,
            dpstRqstDt: this.expnsDtoList[i][index].dpstRqstDt,
            editing: false
        })
      }
      formData.append(
          "request",
          new Blob([JSON.stringify(request)], { type: "application/json" })
      );
      this.submitting = true;
      const rtnval = await Api.post('/api/v1/payment/set', formData);
      if(rtnval.result) {
        await this.findCntrctExpns(this.prjId);
      }
      else {
          alert(rtnval.errormsg)
      }
      this.submitting = false;
    }
  }
}
</script>

<style scoped>

</style>