import Api from "./index"
import store from "@/store"

const partnerRoleTypes = ['DS', 'MF', 'CT', 'IN', 'DE'];

async function logIn(email, password) {
    const result = await Api.post('/api/v1/user/login', {email: email, psswrd: password});
    if(result.result){
        localStorage.setItem("loginFlag", 1);
        const rtnval = await Api.get('/api/v1/user/find/usr/info');
        if(rtnval.result){
            store.commit('setUserInfo', {id: result.data.id, info: rtnval.data})
            return {result: true, msg: 'log in success!'};
        }
    }
    else{
        return {result: false, errorcode: result.errorcode, errormsg: result.errormsg};
    }
}

async function isLogedIn() {
    const result = await Api.get('/api/v1/user/find/usr/info');
    if(result.result){
        if(result.data.usrRole == 'M3') {
            result.data.usrRole = 'OW'
        }
        store.commit('setUserInfo', {id: store.state.user.id, info: result.data})

        var pass = true;
        var url = location.href;
        if(url.indexOf('/admin') >= 0) {
            if(result.data.adminYn != 'S') {
                pass = false;
            }
            if(result.data.adminRole){
                result.data.adminRole.menuPolicyList.forEach((policy) => {
                    const allowedPath = policy.menuPage.routePath.indexOf(':') >=0? policy.menuPage.routePath.split(':')[0] : policy.menuPage.routePath;
                    if(location.href.indexOf(allowedPath) >=0) {
                        pass = true;
                    }       
                })
            }
        }
        if(!pass) {
            alert('접근권한이 없습니다.');
            if(url.indexOf('/proxypage?') >= 0) {
                window.close();
            }
            else {
                location.href = '/project/dashboard'
            }
        }
        return true;
    }
    else{
        const loginFlag = localStorage.getItem("loginFlag");
        if(loginFlag) {
            alert('로그인 세션이 만료되었습니다.\n다시 로그인해주세요.');
            localStorage.removeItem("loginFlag");
        }
        return false;
    }
}

async function logOut() {
    localStorage.removeItem('vuex');
    localStorage.removeItem("loginFlag");
    sessionStorage.clear();
    await Api.get('/api/v1/user/logout');
}

export default {logIn, isLogedIn, logOut, partnerRoleTypes};