<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">임원진 프로필</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>프로필 {{ $route.params.memberId > 0? '수정' : '등록' }}</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>이름(국문/영문)</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="memberName">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 40px;" v-model="memberNameEn">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>직함(국문/영문)</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="memberTitle">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 40px;" v-model="memberTitleEn">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>사진</strong><br><p style="font-size: small; color:salmon;">※배경 투명의 png파일로 등록요망</p></span></td>
                  <td class="">
                    <img v-if="picturePath" class="picture" style="width: 50%; margin: 20px" :src="picturePath">
                    <img v-else class="picture" style="width: 50%; margin: 20px">
                    <div style="display:flex">
                      <input type="file" @change="fileHandling" class="form-control fkr-set2 fc-w5 text-center" style="width:40%; height:36px; margin: 0 0 10px 20px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                      <a href="javascript:void(0)" style="margin-left:20px; padding-top: 8px;" @click="removePicture()"><i class="fa fa-trash"/></a>
                    </div>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>요약문(국문)</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:auto; height:36px; margin-left: 20px;" v-model="memberSummary">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>요약문(영문)</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:auto; height:36px; margin-left: 20px;" v-model="memberSummaryEn">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>설명(국문)</strong></span></td>
                  <td class="text-center" width="70%"><textarea class="form-control fkr-set2" style="height:200px; margin: 10px" v-model="memberDesc"></textarea></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>설명(영문)</strong></span></td>
                  <td class="text-center" width="70%"><textarea class="form-control fkr-set2" style="height:200px; margin: 10px" v-model="memberDescEn"></textarea></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>약력(국문)</strong></span></td>
                  <td class="text-center" width="50%" style="padding: 10px !important">
                    <div class="x_div_center ls_bg1" style="height:55px" v-for="(career, index) in careerList" :key="index">
                        <div class="x_div_center px-1" style="width:90%">
                          <input type="text" v-model="career.cntnt" class="form-control fkr-set2 fc-w5" style="width:100%; height:36px">
                        </div>
                        <div class="x_div_center px-1" style="width:10%">
                          <a href="javascript:void(0)" class="rm_frm" @click="careerList = careerList.filter((v,i) => i != index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a>
                        </div>
                    </div>
                  </td>
                  <td class="text-center" width="20%" style="padding: 20px !important">
                    <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="careerList.push({cntnt: ''})"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>약력(영문)</strong></span></td>
                  <td class="text-center" width="50%" style="padding: 10px !important">
                    <div class="x_div_center ls_bg1" style="height:55px" v-for="(career, index) in careerListEn" :key="index">
                        <div class="x_div_center px-1" style="width:90%">
                          <input type="text" v-model="career.cntnt" class="form-control fkr-set2 fc-w5" style="width:100%; height:36px">
                        </div>
                        <div class="x_div_center px-1" style="width:10%">
                          <a href="javascript:void(0)" class="rm_frm" @click="careerListEn = careerListEn.filter((v,i) => i != index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a>
                        </div>
                    </div>
                  </td>
                  <td class="text-center" width="20%" style="padding: 20px !important">
                    <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="careerListEn.push({cntnt: ''})"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>
                  </td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" onclick="window.$('#preview-kr').modal('show')" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">미리보기(국문)</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" onclick="window.$('#preview-en').modal('show')" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">미리보기(영문)</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveProfile()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
            <div v-if="$route.params.memberId > 0" class="py-2"><button class="fkr-set2 fs-095" @click="deleteProfile()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="moveToList()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">{{ $route.params.memberId > 0? '목록' : '취소' }}</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
    <div id="preview-kr" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w1">{{ memberName.split('').join(' ') + (memberTitle? ' | ' + memberTitle : '') }}</span></div>
                        <div class="text-end pe-4" style="width:100px"><i style="cursor:pointer" @click="closeModal()" class="fa-solid fa-xmark fs-15 fc-w1"></i></div>
                    </div>

                    <div v-if="picturePath" style="display:flex; justify-content: space-between; align-items: flex-end; background-color:#E7E6E6">
                        <div style="display:grid; margin:25px">
                            <span class="fkr-set1 fs-20">{{ memberName.split('').join(' ') + (memberTitle? ' | ' + memberTitle : '') }}</span>
                            <span class="fkr-set1 fs-13">{{ memberSummary }}</span>
                        </div>
                        <img class="picture" :src="picturePath" width="50%">
                    </div>

                    <div v-if="memberDesc" class="x_div_center py-2 px-4">
                        <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div style="line-height:180%">
                              <span class="fkr-set2">{{ memberDesc }}</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="careerList.length" class="x_div_center py-2 px-4">
                        <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div style="line-height:180%">
                                <ul>
                                    <li v-for="(career, index) in careerList" :key="index" class="fkr-set2">{{ career.cntnt }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div style="height:20px"></div>

                </div>
            </div>
        </div>
    </div>
    <div id="preview-en" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w1">{{ memberNameEn + (memberTitleEn? ' | ' + memberTitleEn : '') }}</span></div>
                        <div class="text-end pe-4" style="width:100px"><i style="cursor:pointer" @click="closeModal()" class="fa-solid fa-xmark fs-15 fc-w1"></i></div>
                    </div>

                    <div v-if="picturePath" style="display:flex; justify-content: space-between; align-items: flex-end; background-color:#E7E6E6">
                        <div style="display:grid; margin:25px">
                            <span class="fkr-set1 fs-20">{{ memberNameEn + (memberTitleEn? ' | ' + memberTitleEn : '') }}</span>
                            <span class="fkr-set1 fs-13">{{ memberSummaryEn }}</span>
                        </div>
                        <img class="picture" :src="picturePath" width="50%">
                    </div>

                    <div v-if="memberDescEn" class="x_div_center py-2 px-4">
                        <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div style="line-height:180%">
                              <span class="fkr-set2">{{ memberDescEn }}</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="careerListEn.length" class="x_div_center py-2 px-4">
                        <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                            <div style="line-height:180%">
                                <ul>
                                    <li v-for="(career, index) in careerListEn" :key="index" class="fkr-set2">{{ career.cntnt }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div style="height:20px"></div>

                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "MemberEdit",
  components: {
		AdminLNB
	},
  data(){
    return {
      memberName: '',
      memberNameEn: '',
      memberTitle: null,
      memberTitleEn: null,
      memberSummary: null,
      memberSummaryEn: null,
      memberDesc: null,
      memberDescEn: null,
      careerList: [],
      careerListEn: [],
      pictureFileToUpload: null,
      pictureNameToUpload: null,
      pictureFileIdToUpload: null,
      picturePath: null,
      fetching: true,
      submitting: false,
      s3region: process.env.VUE_APP_S3_REGION,
      s3bucket: process.env.VUE_APP_S3_BUCKET
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    if(this.$route.params.memberId > 0) {
      const returnvalue = await Api.get('/api/v1/member/get?memberId=' + this.$route.params.memberId);
      if(returnvalue.result) {
        this.memberName = returnvalue.data.memberName;
        this.memberNameEn = returnvalue.data.memberNameEn;
        this.memberTitle = returnvalue.data.memberTitle;
        this.memberTitleEn = returnvalue.data.memberTitleEn;
        this.memberSummary = returnvalue.data.memberSummary;
        this.memberSummaryEn = returnvalue.data.memberSummaryEn;
        this.memberDesc = returnvalue.data.memberDesc;
        this.memberDescEn = returnvalue.data.memberDescEn;
        var careerListRaw = returnvalue.data.memberCareer? returnvalue.data.memberCareer.split(';') : [];
        careerListRaw.forEach((item) => {
          this.careerList.push({cntnt: item});
        })
        var careerListEnRaw = returnvalue.data.memberCareerEn? returnvalue.data.memberCareerEn.split(';') : [];
        careerListEnRaw.forEach((item) => {
          this.careerListEn.push({cntnt: item});
        })
        this.picturePath = returnvalue.data.thumbnailWebPath? 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + returnvalue.data.thumbnailWebPath : null;
      }
      else {
        alert(returnvalue.errormsg)
      }
    }

    this.fetching = false;
  },

  methods:{
    fileHandling(e) {
      this.pictureFileToUpload = e.target.files[0];
      this.pictureNameToUpload = e.target.files[0].name;

      window.$('.picture').attr('src', URL.createObjectURL(e.target.files[0]));
      this.picturePath = URL.createObjectURL(e.target.files[0]);
    },
    removePicture() {
      this.pictureFileToUpload = null;
      this.pictureNameToUpload = null;
      this.pictureFileIdToUpload = this.$route.params.memberId > 0? 0 : null;
      this.picturePath = null

      window.$('input[type="file"]').val('');
      window.$('.picture').attr('src', null);
    },

    async saveProfile() {
      if(!this.memberName || !this.memberNameEn) {
        alert('이름은 필수 입력사항입니다.');
        return;
      }

      var memberCareer = '';
      if(this.careerList.length > 0) {
        for(var i=0; i<this.careerList.length; i++) {
          if(this.careerList[i].cntnt.indexOf(';') >= 0) {
            alert('약력 내용에는 ";" 문자가 포함될 수 없습니다.');
            return;
          }
          memberCareer += this.careerList[i].cntnt + (i+1 == this.careerList.length? '' : ';');
        }
      }

      var memberCareerEn = '';
      if(this.careerListEn.length > 0) {
        for(i=0; i<this.careerListEn.length; i++) {
          if(this.careerListEn[i].cntnt.indexOf(';') >= 0) {
            alert('약력 내용에는 ";" 문자가 포함될 수 없습니다.');
            return;
          }
          memberCareerEn += this.careerListEn[i].cntnt + (i+1 == this.careerListEn.length? '' : ';');
        }
      }

      this.submitting = true;

      if(this.pictureFileToUpload) {
        const formData = new FormData();
        var attchFlCtgry = [];
        var attchOrgNmList = [];
        var dirNmList = [];

        formData.append("attchmntList", this.pictureFileToUpload);
        attchFlCtgry.push(null);
        attchOrgNmList.push(this.pictureNameToUpload);
        dirNmList.push('platform/members');

        var request = {
            attchFlCtgry: attchFlCtgry,
            attchOrgNmList: attchOrgNmList,
            dirNmList: dirNmList,
        }
        formData.append(
            "request",
            new Blob([JSON.stringify(request)], { type: "application/json" })
        );
        const rtnval = await Api.post('/api/v1/common/set/attachments', formData)
        if(rtnval.result) {
          this.pictureFileIdToUpload = rtnval.data[0];
        }
        else {
          alert(rtnval.errormsg)
          this.submitting = false;
          return;
        }
      }

      const rtnval = await Api.post('/api/v1/member/' + (this.$route.params.memberId > 0? 'update' : 'create'), {
        memberId: this.$route.params.memberId > 0? this.$route.params.memberId : null,
        memberName: this.memberName,
        memberNameEn: this.memberNameEn,
        memberTitle: this.memberTitle,
        memberTitleEn: this.memberTitleEn,
        memberSummary: this.memberSummary,
        memberSummaryEn: this.memberSummaryEn,
        memberDesc: this.memberDesc,
        memberDescEn: this.memberDescEn,
        memberCareer: memberCareer,
        memberCareerEn: memberCareerEn,
        thumbnailFlId: this.pictureFileIdToUpload
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        this.moveToList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    async deleteProfile() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        const rtnvalue = await Api.get('/api/v1/member/delete?memberId=' + this.$route.params.memberId)
        if(rtnvalue.result) {
          alert('정상적으로 삭제되었습니다.')
          this.moveToList();
        }
        else {
          alert(rtnvalue.errormsg)
        } 
      }
    },

    closeModal() {
      window.$('.modal').modal('hide');
    },

    moveToList(){
      location.href = '/admin/site/member-list';  
    }
  }
}
</script>

<style scoped>
.td-flex {
  display: flex;
  padding-top: 8px;
}
</style>