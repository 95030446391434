<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">실시간 견적산출</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>자재정보 관리</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <!-- <span class="fkr-set2 fs-10" style="padding: 5px">{{ currentCtgry }} 게시물 수: {{ mtrlList.length }} 건</span> -->
            <!-- <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select> -->
            
            <div class="ps-2" style="margin-left: auto; padding-left: 650px !important"><button class="fkr-set2 fs-095" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;" @click="editPysclSpec(null)">새 건축사양</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findArticle(1)"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findArticle(1)" style="border:0; width:170px; height:30px; padding:0 .3rem"></div>
              </div>
            </div>
          </div>
          
          <div class="mib_box_c w-100 pt-3">
            <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
              <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">사양명</span></div>
              <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">단위</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">수정일</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">수정자</span></div>
              <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
            </div>

            <div class="pg_m_set" v-if="fetching" style="padding: 20px">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else>
              <div v-for="(item, index) in specListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9">{{item.archiSpcNm}}</span></div>
                <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9">{{item.unit}}</span></div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.recentUpdateDt.split('T')[0]}}</span></div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.recentUpdater}}</span></div>
                <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><i class="fa fa-edit" style="cursor:pointer" @click="editPysclSpec(item)"/></span></div>
              </div>
            </div>
          </div>

          <!-- 페이징 -->
          <div class="x_div_center py-3" v-if="!fetching">
              <a class="px-2" @click="switchPageSpec(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
              <a class="px-2" @click="switchPageSpec(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
              <span v-if="pageQueueSpec[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
              <a v-for="index in pageQueueSpec" :key="index" class="px-2" @click="currentPageSpec = index; renderSpecList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPageSpec == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
              <span v-if="pageQueueSpec[pageQueueSpec.length-1] < numOfPageSpec" style="cursor:pointer;" class="fen-set2">...</span>
              <a class="px-2" @click="switchPageSpec(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
              <a class="px-2" @click="switchPageSpec(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
          </div>

          <div style="height:50px"></div>

          <div class="w-100" style="display: flex;">
            <select class="custom-select ff-eng1 fs-085" v-model="ctgryFilter" @change="switchCtgry()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in ctgryTypeList" :key="i" :value="item.mtrlCtgryTypeId">{{ item.mtrlCtgryTypeNm }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setCtgry(0)" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setCtgry(1)" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeCtgry()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="ps-2" :style="'margin-left: auto; padding-left: ' + (ctgryFilter? '350px' : '500px') + ' !important'"><button class="fkr-set2 fs-095" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;" onclick="location.href = '/admin/estmt/unit-price/0'" >새 자재정보</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findArticle(0)"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findArticle(0)" style="border:0; width:170px; height:30px; padding:0 .3rem"></div>
              </div>
            </div>
          </div>

          <div class="mib_box_c w-100 pt-3">
            <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">자재명</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">분류</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">관련건축사양</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">단가</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">수정일</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">수정자</span></div>
              <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
            </div>

            <div class="pg_m_set" v-if="fetching" style="padding: 20px">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else>
              <div v-for="(item, index) in mtrlListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.stdMtrlNm}}</span></div>
                <div class="x_div_center m_line" style="width:15%; display: flex;">
                  <span class="fkr-set2 fs-9" v-for="(ctgry, kintex) in item.listMtrlCtgryList" :key="kintex" v-html="ctgry.mtrlCtgryTypeNm + (kintex+1 < item.listMtrlCtgryList.length? ',&nbsp;' : '')"></span>
                </div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.archiSpcNm}}</span></div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.price.toLocaleString()}}</span></div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.recentUpdateDt.split('T')[0]}}</span></div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.recentUpdater}}</span></div>
                <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><i class="fa fa-edit" style="cursor:pointer" @click="editMtrlInfo(item)"/></span></div>
              </div>
            </div>
          </div>

          <!-- 페이징 -->
          <div class="x_div_center py-3" v-if="!fetching">
              <a class="px-2" @click="switchPageMtrl(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
              <a class="px-2" @click="switchPageMtrl(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
              <span v-if="pageQueueMtrl[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
              <a v-for="index in pageQueueMtrl" :key="index" class="px-2" @click="currentPageMtrl = index; renderListMtrl();" style="margin-right: 10px"><span :class="'fen-set' + (currentPageMtrl == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
              <span v-if="pageQueueMtrl[pageQueueMtrl.length-1] < numOfPageMtrl" style="cursor:pointer;" class="fen-set2">...</span>
              <a class="px-2" @click="switchPageMtrl(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
              <a class="px-2" @click="switchPageMtrl(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="editSpec" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">건축사양 편집</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                  <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div class="w-100" v-else>
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">사양명</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="specNameEditting"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">단위</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="specUnitEditting"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">아이콘이미지</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <img style="margin: 20px" :src="iconImgPath">
                        <input type="file" id="file" @change="fileHandling" class="form-control fkr-set2 fc-w5 text-center" style="width:40%; height:36px; margin: 0 0 10px 20px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                      </div>
                  </div>
                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2" v-if="editMode"><button @click="removeSpec()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">삭제</span></button></div>
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                <div class="px-2"><button @click="setSpec()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">{{ editMode? '수정' : '등록' }}<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "UnitPriceList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        searchWord: '',
        ctgryTypeList: [],
        specList:[],
        specListOrig:[],
        specListPaged: [],
        pageQueueSpec: [],
        currentPageSpec: 1,
        numOfPageSpec: 0,
        mtrlList:[],
        mtrlListOrig:[],
        mtrlListPaged: [],
        pageQueueMtrl: [],
        currentPageMtrl: 1,
        numOfPageMtrl: 0,
        pageSize: 5,
        ctgryFilter: '',
        specEditting: null,
        specNameEditting: '',
        specUnitEditting: '',
        iconImgPath: null,
        iconImgFileToUpload: null,
        iconImgNameToUpload: null,
        editMode: false
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.get('/api/v1/mtrl/list/mtrlctgrytype');
    // const rtnval = {result: true, data: [{mtrlCtgryTypeId: 1, mtrlCtgryTypeNm: '창호', crtDt: null}, {mtrlCtgryTypeId: 2, mtrlCtgryTypeNm: '마감', crtDt: null}, {mtrlCtgryTypeId: 3, mtrlCtgryTypeNm: '내장', crtDt: null}, {mtrlCtgryTypeId: 4, mtrlCtgryTypeNm: '외장', crtDt: null}]}
    ///////////////////////////////////////////////////////////
    if(rtnval.result) {
        this.ctgryTypeList = rtnval.data.findMtrlCtgryTypeDtoList.sort((a,b) => b.mtrlCtgryTypeId - a.mtrlCtgryTypeId);
        this.ctgryTypeList = [{mtrlCtgryTypeId: '', mtrlCtgryTypeNm: '분류', crtDt: null}].concat(this.ctgryTypeList);
        await this.fetchSpecs(null);
        await this.fetchMtrls(null, null);
        this.setPagingSpec();
        this.renderListSpec();
        this.setPagingMtrl();
        this.renderListMtrl();
    }
    else {
        alert(rtnval.errormsg);
    }
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'N'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchSpecs(keyword) {
      this.fetching = true;
      if(!keyword) {
        this.specList = [];
        const returnvalue = await Api.get('/api/v1/mtrl/list/archispc');
        // const returnvalue = {result: true, data: {findArchiSpcDtoList: [
        //     {archiSpcId: 1, archiSpcNm: '총창길이', unit: '자', recentUpdateDt: '2024-05-31T12:12:12', recentUpdater: '엠몽이'},
        //     {archiSpcId: 2, archiSpcNm: '벽량', unit: '㎡', recentUpdateDt: '2024-05-25T12:12:12', recentUpdater: '강백훈'}
        //   ]}}
        //////////////////////////////////////////////////////////////////////////////////////////////////////
        if(returnvalue.result) {
            this.specList = returnvalue.data.findArchiSpcDtoList.sort((a,b) => b.archiSpcId - a.archiSpcId);
            this.specListOrig = this.specList;
        }
        else {
            alert(returnvalue.errormsg);
        }
      }
      else {
        this.specList = this.specListOrig.filter((spec) => spec.archiSpcNm.indexOf(keyword) >= 0 );
      }
      this.fetching = false;
    },

    async fetchMtrls(ctgryId, keyword) {
      this.fetching = true;
      if(ctgryId || keyword) {
        if(ctgryId) {
          var filteredList = [];
          this.mtrlListOrig.forEach((mtrl) => {
            mtrl.listMtrlCtgryList.forEach((ctgry) => {
              if(ctgry.mtrlCtgryTypeId == ctgryId) {
                filteredList.push(mtrl);
                return;
              }
            })
          })
          this.mtrlList = filteredList;
        }
        if(keyword) {
          this.mtrlList = ctgryId? this.mtrlList.filter((mtrl) => mtrl.stdMtrlNm.indexOf(keyword) >= 0 ) : this.mtrlListOrig.filter((mtrl) => mtrl.stdMtrlNm.indexOf(keyword) >= 0 );
        }
      }
      else {
        this.mtrlList = [];
        const returnvalue = await Api.get('/api/v1/mtrl/list/stdmtrl');
        // const returnvalue = {result: true, data: {resultList: [
        //     {stdMtrlId: 1, stdMtrlNm: '살라만더', ctgryList: [{mtrlCtgryTypeNm: '창호'}], archiSpcNm: '총창길이', price: 10000, recentUpdateDt: '2024-05-31T12:12:12', recentUpdater: '엠몽이'},
        //     {stdMtrlId: 2, stdMtrlNm: '실크벽지', ctgryList: [{mtrlCtgryTypeNm: '마감'}, {mtrlCtgryTypeNm: '내장'}], archiSpcNm: '벽량', price: 1000, recentUpdateDt: '2024-05-25T12:12:12', recentUpdater: '강백훈'}
        //   ]}}
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if(returnvalue.result) {
            this.mtrlList = returnvalue.data.findStdMtrlDtoList.sort((a,b) => b.stdMtrlId - a.stdMtrlId);
            this.mtrlListOrig = this.mtrlList;
        }
        else {
            alert(returnvalue.errormsg);
        }
      }
      this.fetching = false;
    },

    async findArticle(option) {
      if(option) {
        await this.fetchSpecs(this.searchWord);
        this.setPagingSpec();
        this.renderListSpec();
      }
      else {
        await this.fetchMtrls(null, this.searchWord);
        this.setPagingMtrl();
        this.renderListMtrl();
      }
    },

    renderListSpec() {
      this.specListPaged = [];
      for(var j = (this.currentPageSpec-1) * this.pageSize; j < (this.specList.length > this.currentPageSpec * this.pageSize? this.currentPageSpec * this.pageSize : this.specList.length); j++) {
        this.specListPaged.push(this.specList[j]);
      }
    },

    renderListMtrl() {
      this.mtrlListPaged = [];
      for(var j = (this.currentPageMtrl-1) * this.pageSize; j < (this.mtrlList.length > this.currentPageMtrl * this.pageSize? this.currentPageMtrl * this.pageSize : this.mtrlList.length); j++) {
        this.mtrlListPaged.push(this.mtrlList[j]);
      }
    },

    setPagingSpec() {
      this.numOfPageSpec = Math.ceil(this.specList.length / this.pageSize);
      this.pageQueueSpec = [];
      if(this.currentPageSpec < 11) {
        for(var i = 1; i <= (this.numOfPageSpec > 10 ? 10 : this.numOfPageSpec) ; i++) {
          this.pageQueueSpec.push(i);
        }
      }
      else {
        for(i = this.currentPageSpec - 9; i <= this.currentPageSpec; i++) {
          this.pageQueueSpec.push(i);
        }
      }
    },

    setPagingMtrl() {
      this.numOfPageMtrl = Math.ceil(this.mtrlList.length / this.pageSize);
      this.pageQueueMtrl = [];
      if(this.currentPageMtrl < 11) {
        for(var i = 1; i <= (this.numOfPageMtrl > 10 ? 10 : this.numOfPageMtrl) ; i++) {
          this.pageQueueMtrl.push(i);
        }
      }
      else {
        for(i = this.currentPageMtrl - 9; i <= this.currentPageMtrl; i++) {
          this.pageQueueMtrl.push(i);
        }
      }
    },

    async switchPageSpec(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPageSpec > 1) {
                    this.currentPageSpec = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPageSpec > 1) {
                    this.currentPageSpec--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPageSpec < this.numOfPageSpec) {
                    this.currentPageSpec++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPageSpec < this.numOfPageSpec) {
                    this.currentPageSpec = this.numOfPageSpec;
                    render = true;
                  } 
      }
      if(render) {
        this.setPagingSpec();
        this.renderListSpec();
      }
    },

    async switchPageMtrl(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPageMtrl > 1) {
                    this.currentPageMtrl = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPageMtrl > 1) {
                    this.currentPageMtrl--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPageMtrl < this.numOfPageMtrl) {
                    this.currentPageMtrl++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPageMtrl < this.numOfPageMtrl) {
                    this.currentPageMtrl = this.numOfPageMtrl;
                    render = true;
                  } 
      }
      if(render) {
        this.setPagingMtrl();
        this.renderListMtrl();
      }
    },

    async switchPageSize() {
      this.currentPageSpec = 1;
      this.currentPageMtrl = 1;
      this.setPagingSpec();
      this.renderListSpec();
      this.setPagingMtrl();
      this.renderListMtrl();
    },

    async switchCtgry() {
      this.currentPageMtrl = 1;
      await this.fetchMtrls(this.ctgryFilter, null);
      this.setPagingMtrl();
      this.renderListMtrl();
    },

    async setCtgry(option) {
      var mtrlCtgryTypeNm = prompt((option? '수정할' : '등록할') + ' 분류명을 입력하세요.');
      if(!mtrlCtgryTypeNm) {
        alert('분류명은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/mtrl/' + (option? 'update' : 'set') + '/mtrlctgrytype', {
        mtrlCtgryTypeNm: mtrlCtgryTypeNm,
        mtrlCtgryTypeId: option? this.ctgryFilter : null
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removeCtgry() {
      if(this.mtrlList.length > 0) {
        alert('해당 분류에 속한 자재정보가 존재합니다.\n분류를 삭제할 수 없습니다.')
        return;
      }

      const rtnvalue = await Api.get('/api/v1/mtrl/delete/mtrlctgrytype?mtrlCtgryTypeId=' + this.ctgryFilter)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    editMtrlInfo(mtrl) {
      location.href = '/admin/estmt/unit-price/' + mtrl.stdMtrlId;
    },

    fileHandling(e) {
        var tokens = e.target.files[0].name.split('.');
        var fileType = tokens[tokens.length-1].toLowerCase();
        var allowed = ['jpg', 'jpeg', 'png']
        if(allowed.indexOf(fileType) < 0){
            alert('이미지파일만 업로드 가능합니다.');
            window.$('#file').val('')
            return 1;
        }

        this.iconImgPath = URL.createObjectURL(e.target.files[0]);
        this.iconImgFileToUpload = e.target.files[0];
        this.iconImgNameToUpload = e.target.files[0].name;
    },

    async removeSpec() {
      const rtnvalue = await Api.get('/api/v1/mtrl/delete/archispc?archiSpcId=' + this.specEditting)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },
    
    async editPysclSpec(spec) {
      if(spec == null) {
        this.specEditting = null;
        this.specNameEditting = '';
        this.specUnitEditting = '';
        this.iconImgPath = null;
        this.iconImgFileToUpload = '';
        this.iconImgNameToUpload = '';
        this.editMode = false;
        window.$('#file').val('');
      }
      else {
        this.fetching = true;
        const rtnvalue = await Api.get('/api/v1/mtrl/find/archispc?archiSpcId=' + spec.archiSpcId)
        if(rtnvalue.result) {
          this.specEditting = spec.archiSpcId;
          this.specNameEditting = rtnvalue.data.archiSpcNm;
          this.specUnitEditting = rtnvalue.data.unit;
          this.iconImgFileToUpload = '';
          this.iconImgNameToUpload = '';
          this.editMode = true;
          window.$('#file').val('');

          if(rtnvalue.data.imgFileNm) {
            var tokens = rtnvalue.data.imgFileNm.split('.');
            var mimeType = tokens[tokens.length-1].toLowerCase();
            var fileType = 'image/' + mimeType;
  
            const byteCharacters = atob(rtnvalue.data.img);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: fileType});
            this.iconImgPath = URL.createObjectURL(blob);
          }
        }
        else {
          alert(rtnvalue.errormsg)
        }
        this.fetching = false;
      }
      window.$('#editSpec').modal('show');
    },

    closeModal() {
			window.$('#editSpec').modal('hide');
		},

    async setSpec() {
      if(!this.specNameEditting) {
        alert("사양명을 입력하세요.")
        return;
      }
      if(!this.specUnitEditting) {
        alert("단위를 입력하세요.")
        return;
      }

      const formData = new FormData();
      formData.append("attchmnt", this.iconImgFileToUpload);
      var request = {
        archiSpcId: this.specEditting,
        archiSpcNm: this.specNameEditting,
        unit: this.specUnitEditting,
        imgFileNm: this.iconImgNameToUpload
      }

      formData.append(
          "request",
          new Blob([JSON.stringify(request)], { type: "application/json" })
      );

      this.submitting = true;
      const rtnvalue = await Api.post('/api/v1/mtrl/' + (this.editMode? 'update' : 'set') + '/archispc', formData)
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
      this.submitting = false;
    }
  }
}
</script>

