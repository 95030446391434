<template>
    <div class="d-flex">
        <AdminLNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="d-flex pt-5">
                        <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
                        <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold">해피콜문자 템플릿 등록/수정</span></span></div></div>
                    </div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:20px"></div>

                    <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                      <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div v-else>
                      <div class="pg_m_set3 w-100">
                          <div class="pg_d_set3a" style="border-right: 0px">
                            <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">담당자</h3></div>
                              <div style="padding: 15px">
                                  <select class="custom-select ff-eng1 fs-085" v-model="cnslr" style="width:200px; height:37px;">
                                    <option v-for="(item, i) in cnslrList" :key="i" :value="item.id">{{ item.fullName }}</option>
                                  </select>
                              </div>
                          </div>
                          <div class="pg_d_set3b">
                          </div>
                      </div>
  
                      <div class="mib_box_c w-100 pt-3" style="padding-left: 15px"><h3 class="fkr-set1 fs-11">내용</h3></div>
  
                      <div style="padding: 15px"><textarea class="w-100 form-control" v-model="contents" style="height:250px; border:1px solid #c9c9c9; border-radius:4px"></textarea></div>
  
                      <div class="x_div_end">
                          <div class="py-2 pe-1"><button class="fkr-set2 fs-095" onclick="location.href='/admin/site/template-list'" style="width:100px; height:37px; border:0; background-color:#fff; color:#4e0000; border-radius:4px; border:1px solid #4e0000">취소</button></div>
                          <div class="py-2 ps-1"><button class="fkr-set2 fs-095" @click="saveTemplate()" style="width:100px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                          <div class="py-2 ps-1" v-if="$route.params.templateId >0"><button class="fkr-set2 fs-095" @click="deleteTemplate()" style="width:100px; height:37px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                      </div>
                    </div>

                    <div style="height:20px"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'TextTemplateEdit',
    components: {
		AdminLNB
	},
  data() {
      return {
          fetching: true,
          submitting: false,
          contents: '',
          cnslrList: [],
          cnslr: 1,
      }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/all?option=1');
    if(rtnval.result){
        this.cnslrList = rtnval.data.findAllUsrDtoList;
        this.cnslrList = this.cnslrList.filter((val) => val.cnslrLv == 1).sort((a,b) => a.id - b.id);
    }
    else {
        alert(rtnval.errormsg);
    }

    if(this.$route.params.templateId > 0) {
      const rtnval = await Api.get('/api/v1/primprj/get/template?templateId=' + this.$route.params.templateId);
      if(rtnval.result) {
        this.cnslr = rtnval.data.cnslrId;
        this.contents = rtnval.data.template;
      }
      else {
        alert(rtnval.errormsg);
      }
    }
    this.fetching = false;
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
    async saveTemplate() {
      if(!this.contents) {
        alert('내용을 입력하세요.');
        return 1;
      }

      this.submitting = true;
      var rtnval = null;
      rtnval = await Api.post('/api/v1/primprj/set/template', {cnslrId: this.cnslr, template: this.contents, textTemplateId: this.$route.params.templateId >0? this.$route.params.templateId : null});
      if(rtnval.result) {
        if(rtnval.data == -1) {
          alert('담당자 데이터가 없습니다.');
        }
        else if(rtnval.data == -2) {
          alert('이미 해피콜문자 템플릿 등록이 완료된 담당자입니다.');
        }
        else {
          alert('정상적으로 등록되었습니다.')
          location.href='/admin/site/template-list'
        }
      }
      else {
        alert(rtnval.errormsg);
      }
      this.submitting = false;
    },
    async deleteTemplate() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        this.submitting = true;
        var rtnval = await Api.get('/api/v1/primprj/delete/template?templateId=' + this.$route.params.templateId);
        if(rtnval.result) {
          alert('정상적으로 삭제되었습니다.')
          location.href='/admin/site/template-list'
        }
        else {
          alert(rtnval.errormsg);
        }
        this.submitting = false;
      }
    }
  }
}
</script>