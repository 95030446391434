<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩관련 설정</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>비딩 참여희망 요청기간</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">영업일기준</span>
                    <input type="number" class="form-control fkr-set2" style="width:90px; height:36px; margin-left: 20px;" v-model="daysForJoin">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">일</span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>비딩 후보업체 선정기간</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">영업일기준</span>
                    <input type="number" class="form-control fkr-set2" style="width:90px; height:36px; margin-left: 20px;" v-model="daysForNominate">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">일</span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>입찰제안서 제출기간</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">영업일기준</span>
                    <input type="number" class="form-control fkr-set2" style="width:90px; height:36px; margin-left: 20px;" v-model="daysForProposal">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">일</span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약대상순위 선정기간</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">영업일기준</span>
                    <input type="number" class="form-control fkr-set2" style="width:90px; height:36px; margin-left: 20px;" v-model="daysForEvaluate">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">일</span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>최종계약대상 선정기간</strong></span></td>
                  <td class="text-center td-flex" width="70%">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">영업일기준</span>
                    <input type="number" class="form-control fkr-set2" style="width:90px; height:36px; margin-left: 20px;" v-model="daysForFinalEvaluate">
                    <span class="ff-kor1 span-for-flex" style="font-size:12pt">일</span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>기획설계 요청비용 입금계좌</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:380px; height:36px; margin-left: 20px;" v-model="accountBankAndNumber">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>신규프로젝트 지원담당자</strong></span></td>
                  <td class="text-center" width="70%">
                    <span class="ff-kor1" style="display:flex;">
                      <p v-if="newPrjSupporter" style="margin: 0 15px 0 20px">{{ newPrjSupporter.fullName }}&nbsp;
                        <a href="javascript:void(0)" @click="deleteMember(newPrjSupporter)"><i class="fas fa-backspace"></i></a>
                      </p>
                      <a v-else href="javascript:void(0)" @click="openMemberSearchModal(0)" style="margin-left: 20px"><i class="fas fa-plus-circle"></i></a>
                    </span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>신규비딩 지원담당자</strong></span></td>
                  <td class="text-center" width="70%">
                    <span class="ff-kor1" style="display:flex;">
                      <p v-if="biddingSupporter" style="margin: 0 15px 0 20px">{{ biddingSupporter.fullName }}&nbsp;
                        <a href="javascript:void(0)" @click="deleteMember(biddingSupporter)"><i class="fas fa-backspace"></i></a>
                      </p>
                      <a v-else href="javascript:void(0)" @click="openMemberSearchModal(1)" style="margin-left: 20px"><i class="fas fa-plus-circle"></i></a>
                    </span>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>신규매칭 지원담당자</strong></span></td>
                  <td class="text-center" width="70%">
                    <span class="ff-kor1" style="display:flex;">
                      <p v-if="matchingSupporter" style="margin: 0 15px 0 20px">{{ matchingSupporter.fullName }}&nbsp;
                        <a href="javascript:void(0)" @click="deleteMember(matchingSupporter)"><i class="fas fa-backspace"></i></a>
                      </p>
                      <a v-else href="javascript:void(0)" @click="openMemberSearchModal(2)" style="margin-left: 20px"><i class="fas fa-plus-circle"></i></a>
                    </span>
                  </td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveCompanyInfo()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
    <FindUsrModal @selectedUsrId="enlistMember"/>
  </div>
</template>

<script>
import FindUsrModal from '@/components/FindUsrModal.vue'
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "BddngSettingMng",
  components: {
		AdminLNB, FindUsrModal
	},
  data(){
    return {
      companyInfoId: null,
      accountBankAndNumber: '',
      daysForJoin: 0,
      daysForNominate: 0,
      daysForProposal: 0,
      daysForEvaluate: 0,
      daysForFinalEvaluate: 0,
      newPrjSupporter: null,
      biddingSupporter: null,
      matchingSupporter: null,
      edittingSupporterOption: null,
      newList: [],
      delList: [],
      fetching: true,
      submitting: false
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    const returnvalue = await Api.get('/api/v1/companyinfo/list');
    if(returnvalue.result) {
      this.companyInfoId = returnvalue.data[0].id;
      this.accountBankAndNumber = returnvalue.data[0].accountBankAndNumber;
      this.daysForJoin = returnvalue.data[0].daysForJoin;
      this.daysForNominate = returnvalue.data[0].daysForNominate;
      this.daysForProposal = returnvalue.data[0].daysForProposal;
      this.daysForEvaluate = returnvalue.data[0].daysForEvaluate;
      this.daysForFinalEvaluate = returnvalue.data[0].daysForFinalEvaluate;
      if(returnvalue.data[0].supporterList) {
        returnvalue.data[0].supporterList.forEach((member) => {
          if(member.templateCode == "new_prj_noti") {
            this.newPrjSupporter = member;
          }
          else if(member.templateCode == "new_bddng_noti") {
            this.biddingSupporter = member;
          }
          else if(member.templateCode == "new_matching_noti") {
            this.matchingSupporter = member;
          }
        })
      }
    }
    else {
      alert(returnvalue.errormsg)
    }

    this.fetching = false;
  },

  methods:{
    openMemberSearchModal(option) {
      this.edittingSupporterOption = option;
      window.$('#modalForm').modal('show');
    },
    enlistMember(member) {
      if(this.edittingSupporterOption == 0) {
        this.newPrjSupporter = {id: null, usrId: member.usrId, fullName: member.usrNm, templateCode: "new_prj_noti"};
        this.newList.push(this.newPrjSupporter);
      }
      else if(this.edittingSupporterOption == 1) {
        this.biddingSupporter = {id: null, usrId: member.usrId, fullName: member.usrNm, templateCode: "new_bddng_noti"};
        this.newList.push(this.biddingSupporter);
      }
      else {
        this.matchingSupporter = {id: null, usrId: member.usrId, fullName: member.usrNm, templateCode: "new_matching_noti"}
        this.newList.push(this.matchingSupporter);
      }
      this.edittingSupporterOption = null;
    },
    deleteMember(member) {
      if(member.id) {
        this.delList.push(member);
      }
      else {
        this.newList = this.newList.filter((v) => v.templateCode != member.templateCode);
      }

      if(member.templateCode == "new_prj_noti") {
        this.newPrjSupporter = null;
      }
      else if(member.templateCode == "new_bddng_noti") {
        this.biddingSupporter = null;
      }
      else {
        this.matchingSupporter = null;
      }
    },
    async saveCompanyInfo() {
      this.submitting = true;
      const rtnval = await Api.post('/api/v1/companyinfo/update', {
        companyInfoId: this.companyInfoId,
        accountBankAndNumber: this.accountBankAndNumber,
        daysForJoin: this.daysForJoin,
        daysForNominate: this.daysForNominate,
        daysForProposal: this.daysForProposal,
        daysForEvaluate: this.daysForEvaluate,
        daysForFinalEvaluate: this.daysForFinalEvaluate,
        supporterListNew: this.newList? this.newList : null,
        supporterListDel: this.delList? this.delList : null
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        location.reload();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    }
  }
}
</script>

<style scoped>
.td-flex {
  display: flex;
  padding-top: 8px;
}
.span-for-flex {
  padding: 5px 0 0 20px;
}
</style>