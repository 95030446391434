<template>
    <div class="m3_contetns" style="margin-top: 50px">

    <!-- 로그인 box 시작 { -->
    <div class="m_login_box">

        <!-- 로그인 좌측 시작 { -->
        <div class="mlb_left">
            <div style="width:80%">
                <div class="x_div_center"><div class="h3">LOGIN</div></div>
                <div v-if="isMobile && fetching" class="x_div_center"><div class="h3"><i class='fa fa-spinner fa-spin'></i></div></div>
                <div>
                    <div class="text-start" style="padding-bottom:.5rem"><span class="fkr-set1">아이디</span></div>
                    <div class="x_div_center" style="border:1px solid #d9d9d9">
                        <div class="w-100"><input type="text" v-model="email" class="form-control ff-eng1" style="width:100%; height:34px; border:0"></div>
                        <div class="x_div_center text-center" style="width:40px; height:36px; border-left:1px solid #d9d9d9"><img src="@/assets/img_legacy/member/m_user.png"></div>
                    </div>
                    <div class="text-start" style="padding:1rem 0 .5rem 0"><span class="fkr-set1">비밀번호</span></div>
                    <div class="x_div_center" style="border:1px solid #d9d9d9; margin-bottom: 10px">
                        <div class="w-100"><input type="password" v-model="password" @keydown.enter="login()" class="form-control ff-eng1" style="width:100%; height:34px; border:0"></div>
                        <div class="x_div_center text-center" style="width:40px; height:36px; border-left:1px solid #d9d9d9"><img src="@/assets/img_legacy/member/m_lock.png"></div>
                    </div>
                    <div>
                        <div class="py-1">
                            <ul class="custom-control custom-checkbox">
                                <li class="chk_box"><input type="checkbox" id="id_maintain" name="id_maintain" class="custom-control-input" v-model="id_maintain" >
                                <label class="custom-control-label" for="id_maintain"><span class="ff-kor1 fs-08 fw-bold">아이디 저장</span></label></li>
                            </ul>
                        </div>
                    </div>
                    <div class="text-end"><a href="/member/findid"><span class="ff-kor1 fs-085">아이디 / 비밀번호찾기</span></a></div>
                    <div class="py-2"><button class="fkr-set1" style="width:100%; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff" @click="login()">로그인<i v-if="fetching" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                </div>
                <div class="x_div_center py-3" style="border-top:1px solid #d9d9d9"><span class="fkr-set1">SNS 계정으로 로그인 (건축주 전용)</span></div>
                <div class="x_div_center" style="margin-bottom: 10px">
                    <div id="naverIdLogin"><a id="naverIdLogin_loginButton" href="#" @click="confirmOauthSignin()"><img src="@/assets/img_legacy/member/btnW_naver.png"></a></div>
                </div>
                <div class="x_div_center" style="margin-bottom: 10px">
                    <div><a href="#" @click="openKakaoLogin()"><img src="@/assets/img_legacy/member/kakao_login_medium_narrow.png"></a></div>
                </div>
                <div class="x_div_center">
                    <div id="appleid-signin" class="signin-button" @click="openAppleLogin()" data-color="black" data-border="true" data-type="sign in"></div>
                </div>
                <div class="x_div_center py-3"><a href="/"><span class="fkr-set1">메인으로 돌아가기</span></a></div>
            </div>
        </div>
        <!-- } 로그인 좌측 끝 -->

        <!-- 로그인 우측 시작 { -->
        <div class="mlb_right">
            <div style="width:85%">
                <div class="w-100">
                    <div class="x_div_center py-2"><span class="ff-kor1 fs-13 fw-bold" style="color:#4e0000">아직 회원이 아니신가요 ?</span></div>
                    <div class="px-3" style="border-left:1px solid #555;">
                        <div><span class="ff-kor1 fs-085" >M3 시스템즈 방문을 환영합니다. 회원가입 및 로그인후 좀 더 다양한 회원 혜택과 다양한 서비스를 이용하실 수 있습니다.</span></div>
                        <div style="padding:2rem 0 0 0"><button class="fkr-set1" style="width:155px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff" @click="moveToSignin()">지금 가입하세요 》</button></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- } 로그인 우측 끝 -->

    </div>
    <!-- } 로그인 box 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
import { mapMutations } from "vuex"
export default {
    name: 'LoginPage',
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        isMobileDevice: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            email: '',
            password: '',
            fetching: false,
            id_maintain: false
        }
    },
    mounted() {

        var savedId = localStorage.getItem('id_maintain');
        if(savedId) {
            this.email = savedId;
            this.id_maintain = true;
        }

        window.AppleID.auth.init({
            clientId : 'M3systems',
            scope : 'name email',
            redirectURI : process.env.VUE_APP_APPLE_OAUTH_REDIRECT_URI,
            state : 'test',
            nonce : 'nonce',
            usePopup : true
        });

        document.addEventListener('AppleIDSignInOnSuccess', async (event) => {
        // Handle successful response.
            console.log("addEventListener: ", event.detail);
            this.appleLoginHandler();
            var rtnval = await Api.post('/api/v1/user/apple/oauth/token', {code: event.detail.authorization.code});
            if(rtnval.result){
                const email = rtnval.data.email;
                const phone = '';
                const username = (event.detail.user? event.detail.user.name.lastName : '') + ' ' + (event.detail.user? event.detail.user.name.firstName : '');
                var returnValue = await Api.post('/api/v1/user/apple/oauth/login', {email: email, phone: phone, nickname: username});
                if(returnValue.result){
                    const returval = await Api.get('/api/v1/user/find/usr/info');
                    if(returval.result){
                        this.setUserInfo({id: returnValue.data.id, info: returval.data});
                        if(!returval.data.phone){
                            alert('회원정보 수정 페이지에서 휴대폰번호를 등록하세요.\n휴대폰번호를 등록하지 않으시면 서비스에 지장이 있을 수 있습니다.');
                            location.href = '/member/modify';
                        }
                        else {
                            location.reload();
                        }
                    }
                }
                else {
                    alert(returnValue.errormsg);
                }
            }
        });

        // Listen for authorization failures.
        document.addEventListener('AppleIDSignInOnFailure', (event) => {
            // Handle error.
            console.log(event.detail.error);
        });

        window.name='opener';
        var naverLogin = new window.naver.LoginWithNaverId(
			{
				clientId: process.env.VUE_APP_NAVER_OAUTH_CLIENT_ID,
				callbackUrl: process.env.VUE_APP_NAVER_OAUTH_REDIRECT_URI,
				isPopup: this.isMobileDevice? false : true,
			}
		);
        
		naverLogin.init();

        const intervalId = window.setInterval(function () {
			naverLogin.getLoginStatus(async function (status) {
				if (status) {
                    const result = await Api.get('/api/v1/user/find/usr/info');
                    if(result.result){
                        window.clearInterval(intervalId);
                        location.href = '/';
                    }
                    else {
                        location.reload();
                    }
                }
            });
        }, 1000);
    },
    methods: {
        ...mapMutations(['setUserInfo']),
        async login() {
            this.fetching = true;
            var result = await Auth.logIn(this.email, this.password);
            if(result.result){
                if(this.id_maintain) {
                    localStorage.setItem('id_maintain', this.email);
                }
                else {
                    localStorage.removeItem('id_maintain');
                }
                var rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId=' + this.user.id);
                if(rtnval.result && (rtnval.data.adminYn == 'Y' || rtnval.data.adminYn == 'S') && !this.isMobileDevice){
                    location.href = '/proxypage'
                }
                else {
                    if(this.$route.query.fromSuggestion){
                        location.href = '/plan'
                    }
                    else if(this.$route.query.fromProfile){
                        location.href = '/member/profile'
                    }
                    else if(this.$route.query.fromScheduleBoard){
                        location.href = '/ganda'
                    }
                    else if(this.$route.query.fromBidding){
                        location.href = '/project/bidding/partner/bidding-participate'
                    }
                    else if(this.$route.query.task){
                        location.href = '/panda/task/' + this.$route.query.task + '/?order=' + this.$route.query.order;
                    }
                    else if(this.$route.query.usdMtrl){
                        location.href = this.$route.query.usdMtrl == 'enlist'? '/usdmtrl/edit' : '/usdmtrl/store/' + this.$route.query.usdMtrl;
                    }
                    else if(this.$route.query.vote){
                        location.href = '/sicksha/' + this.$route.query.vote;
                    }
                    else if(this.$route.query.voteComplete){
                        location.href = '/sicksha/complete/poll/' + this.$route.query.voteComplete;
                    }
                    else if(this.$route.query.fromTalk){
                        location.href = decodeURIComponent(this.$route.query.fromTalk);
                    }
                    else{
                        if(this.user.info.usrRole == 'OW'){
                            var prjCount = 0;
                            rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: false})
                            prjCount += rtnval.data.findPrjDtoList.length;
                            rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: true})
                            prjCount += rtnval.data.findPrjDtoList.length;
                            if(prjCount == 0) {
                                location.href = '/';
                            }
                            else {
                                location.href = '/project/dashboard';
                            }
                        }
                        else {
                            location.href = '/project/dashboard';
                        }
                    }
                }
            }
            else{
                alert(result.errormsg);
                this.fetching = false;
            }
        },
        openKakaoLogin(){
            if(this.confirmOauthSignin()) {
                this.fetching = true;
                var url = "https://kauth.kakao.com/oauth/authorize?client_id="+process.env.VUE_APP_KAKAO_OAUTH_CLIENT_ID+"&redirect_uri="+process.env.VUE_APP_KAKAO_OAUTH_REDIRECT_URI+"&response_type=code";
                if(this.isMobileDevice) {
                    location.href = url;
                }
                else {
                    var popup = window.open(url, 'Kakao Log In', 'width=400, height=600, scrollbars=yes');
                    window.setInterval(function() {
                        if(popup.closed){
                        location.reload();
                        }
                    }, 1000)
                }
            }
        },
        openAppleLogin(){
            if(this.confirmOauthSignin()) {
                alert("이메일은 필수 제공항목입니다.\n애플ID연동 회원가입시 이메일 공유 항목에 필히 체크해주세요.");
            }
        },
        async appleLoginHandler() {
            try {
                const data = await window.AppleID.auth.signIn()
                console.log("appleLoginHandler: ", data);
            } catch ( error ) {
                console.error(error);
            }
        },
        moveToSignin() {
            if(this.$route.query.fromSuggestion){
                this.$router.push({path: '/member/signin', query: {fromSuggestion: 1}});
            }
            else {
                this.$router.push({path: '/member/signin'});
            }
        },
        confirmOauthSignin() {
            return confirm("소셜계정으로는 파트너기관 회원가입이 불가능합니다.\n일반회원(건축주)일 경우에만 진행해주세요.");
        }
    },
    async created() {
        if(await Auth.isLogedIn()){
            this.fetching = true;
            if(this.$route.query.fromSuggestion){
                location.href = '/plan'
            }
            else if(this.$route.query.fromTalk){
                location.href = decodeURIComponent(this.$route.query.fromTalk);
            }
            else if(this.$route.query.task){
                location.href = '/panda/task/' + this.$route.query.task + '/?order=' + this.$route.query.order;
            }
            else if(this.$route.query.vote){
                location.href = '/sicksha/' + this.$route.query.vote;
            }
            else{
                if(this.user.info.usrRole == 'OW'){
                    var prjCount = 0;
                    var rtnval = null;
                    rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: false})
                    prjCount += rtnval.data.findPrjDtoList.length;
                    rtnval = await Api.post('/api/v1/project/find/prj/list', {usrId: this.user.id, completionYn: true})
                    prjCount += rtnval.data.findPrjDtoList.length;
                    if(prjCount == 0) {
                        location.href = '/';
                    }
                    else {
                        location.href = '/project/dashboard';
                    }
                }
                else {
                    location.href = '/project/dashboard';
                }
            }
        }
    }
}
</script>

<style scoped>
.signin-button {
    width: 183px;
    height: 45px;
}
</style>