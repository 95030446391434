<template>
    <div>
        <!-- 상세정보 레이어 시작 { -->
        <div id="modalDetail0" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
                      <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div class="w-100" v-else>
                        <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                            <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">상세정보</span></div>
                            <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                        </div>

                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">M3제공 추천안 이미지</span></div>

                        <div class="pg_a_set3" style="padding: 10px">
                            <div class="pg_a_set3a">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                    <div class="x_div_center"><img style="width: 100%; border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + detailForDesign.pdImg1"></div>
                                </div>
                            </div>
                            <div class="pg_a_set3c"></div>
                            <div class="pg_a_set3b">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                    <div class="x_div_center"><img style="border:0px solid #c9c9c9; border-radius:8px" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + detailForDesign.pdImg2" width="100%"></div>
                                </div>
                            </div>
                        </div>

                        <div v-show="planImg.length">
                            <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">M3제공 추천안 평면도</span></div>
    
                            <div class="pg_a_set3" style="padding: 10px">
                                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" style="margin-top: 60px">
                                    <div class="carousel-indicators">
                                        <button v-for="(v, i) in planImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                                    </div>
                                    <div class="carousel-inner">
                                        <div v-for="(v, i) in planImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                            <div class="iw-1" style="display:flex; justify-content:center">
                                                <img :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + v" :style="isMobile? 'width:90%' : 'width:100%; overflow-y:hidden'">
                                            </div>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">건축주가 첨부한 참고자료</span></div>

                        <div v-for="(bucket, i) in attachmentsFetchedList" :key="i" class="pg_a_set3" style="padding: 10px">
                            <div class="pg_a_set3a">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                    <div class="x_div_center">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[0].mimeType) < 0" style="width: 100%; border:0px solid #c9c9c9; border-radius:8px;" :src="bucket[0].fileLink">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[0].fileLink" :download="bucket[0].attchNm">{{ bucket[0].attchNm }}</a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="pg_a_set3c"></div>
                            <div class="pg_a_set3b">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                    <div class="x_div_center">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[1].mimeType) < 0" style="border:0px solid #c9c9c9; border-radius:8px;" :src="bucket[1].fileLink" width="100%">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[1].fileLink" :download="bucket[1].attchNm">{{ bucket[1].attchNm }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">건축주 요구사항</span></div>

                        <div class="x_div_center py-2 px-4">
                            <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                <div class="py-2 px-2" v-html="detailForDesign.dsgnRqst"></div>
                            </div>
                        </div>

                        <div class="x_div_center py-4">
                            <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                        </div>

                        <div style="height:20px"></div>

                    </div>

                </div>
            </div>
        </div>
        <!-- } 상세정보 레이어 끝 -->

        <!-- 상세정보2 레이어 시작 { -->
        <div id="modalDetail1" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">

                    <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
                      <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div class="w-100" v-else>
                        <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                            <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">상세정보</span></div>
                            <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                        </div>

                        <div class="pt-3 ps-4" v-if="prcCd != 'DE'"><span class="fkr-set2 fs-11 fw-bold">실시설계 내역</span></div>

                        <div class="x_div_center py-2 px-4" v-if="prcCd != 'DE'">
                            <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                    <table class="table-borderless w-100" style="table-layout: fixed;">
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                            <td class="text-center br_0" width="10%"></td>
                                            <td class="text-center br_0" width="30%" v-for="(type, i) in dsgnFilesTypeList" :key = i><span class="fkr-set1 fs-09"><strong>{{ type.name }}</strong></span></td>
                                        </tr>
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9;" v-for="(item, index) in fileList" :key="index">
                                            <td class="text-center br_0" width="10%"><span class="fkr-set1 fs-09"><strong>{{item.ctgryNm}}</strong></span></td>
                                            <td class="text-center br_0" v-for="(file, i) in item.subList" :key="i" width="30%">
                                                <span class="fkr-set2" style="width:80%; display: block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; position: relative; left: 10%">
                                                    <a :href="file.fileLink" :download="file.name">{{file.name}}</a>
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">건축주가 첨부한 참고자료</span></div>

                        <div class="x_div_center py-2 px-4">
                            <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                <div v-for="(bucket, i) in attachmentsFetchedList" :key="i" class="pg_a_set3" style="padding: 10px">
                                    <div class="pg_a_set3a">
                                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                            <div class="x_div_center">
                                                <img v-if="['pdf', 'zip'].indexOf(bucket[0].mimeType) < 0" style="width: 100%; border:0px solid #c9c9c9; border-radius:8px;" :src="bucket[0].fileLink">
                                                <span v-else class="fen-set2 fs-10"><a :href="bucket[0].fileLink" :download="bucket[0].attchNm">{{ bucket[0].attchNm }}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pg_a_set3c"></div>
                                    <div class="pg_a_set3b">
                                        <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                            <div class="x_div_center">
                                                <img v-if="['pdf', 'zip'].indexOf(bucket[1].mimeType) < 0" style="border:0px solid #c9c9c9; border-radius:8px;" :src="bucket[1].fileLink" width="100%">
                                                <span v-else class="fen-set2 fs-10"><a :href="bucket[1].fileLink" :download="bucket[1].attchNm">{{ bucket[1].attchNm }}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="pt-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">건축주 요구사항</span></div>

                        <div class="x_div_center py-2 px-4">
                            <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                <div class="py-2 px-2" v-html="detailForDesign.dsgnRqst"></div>
                            </div>
                        </div>

                        <div class="x_div_center py-4">
                            <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                        </div>

                        <div style="height:20px"></div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'BiddingPrjDetail',
    data() {
        return {
            dsgnFilesCtgryList : [],
			dsgnFilesCtgryIndexMap : [],
			dsgnFilesTypeList : [],
			dsgnFilesTypeIndexMap : [],
			detailForDesign : {
				pdImg1: null,
				pdImg2: null,
				pdImg3: null,
				dsgnRqst: ''
			},
			attachmentsFetchedList: [],
			fileList: [],
            planImg: [],
            fetching: false,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
        }
    },
    props: {
        prcCd: {
            type: String,
            default: 'DS'
        },
        commPolicy: {
            type: String,
            default: null
        }
    },
    async created() {
        var rtnval = await Api.post('/api/v1/common/get/code/family', {codeCtgry: 'PRSTFC', ascByOrderNum: true});
		if(rtnval.result) {
			var index = 0;
			rtnval.data.forEach((code) => {
				if(!code.upperCode) {
					this.dsgnFilesCtgryList.push(code);
					this.dsgnFilesCtgryIndexMap[code.orderNum] = index++;
				}
			})
		}
		else {
			alert(rtnval.errormsg);
		}

		rtnval = await Api.post('/api/v1/common/get/code/family', {codeCtgry: 'PRSTFT', ascByOrderNum: true});
		if(rtnval.result) {
			rtnval.data.forEach((code, index) => {
				this.dsgnFilesTypeList.push(code);
				this.dsgnFilesTypeIndexMap[code.orderNum] = index;
			})
		}
		else {
			alert(rtnval.errormsg);
		}

        this.initDsgnFileList();
        this.fetchBiddableProjects();
    },
    methods: {
        initDsgnFileList() {
			this.fileList = [];
			this.dsgnFilesCtgryList.forEach((code) => {
				var subList = [];
				this.dsgnFilesTypeList.forEach(() => {
					subList.push({id: null, name: null, nameNew: null, file:null, fileLink: null});
				})
				this.fileList.push(
					{	
						ctgryNm: code.name,
						subList: subList
					}
				)
			})
		},
        async fetchBiddableProjects() {
            var prjList = [];
			var rqstDdlnRemainedList = [];
			var rqstDdlnOverList = [];
            const rtnval = await Api.get('/api/v1/project/list/bddng/prj');
            if(rtnval.result) {
				prjList = rtnval.data.bddngPrcList.sort((a, b) => { return new Date(a.bddngJoinDdln) - new Date(b.bddngJoinDdln) })
				const curruntDate = new Date();
				prjList.forEach((item) => {
                    item.maplinkMobile = 'https://m.map.naver.com/search2/search.naver?query=' + item.addr + '#/map';
                    item.maplink = 'https://map.naver.com/search?query=' + item.addr;
					if(new Date(item.bddngJoinDdln) < curruntDate) {
						item.isRqstDdlnOver = true;
						rqstDdlnOverList.push(item);
					}
					else {
						item.isRqstDdlnOver = false;
						rqstDdlnRemainedList.push(item);
					}

                    if(new Date(item.bddngProposalDdln) < curruntDate) {
                        item.isProposalDdlnOver = true;
                    }
                    else {
                        item.isProposalDdlnOver = false;
                    }
				})
				rqstDdlnOverList = rqstDdlnOverList.sort((a, b) => { return new Date(b.bddngCrtDt) - new Date(a.bddngCrtDt) });
				rqstDdlnRemainedList = (this.commPolicy == 'agreed'? rqstDdlnRemainedList.concat(rqstDdlnOverList) : rqstDdlnOverList);
                this.$emit('prjList', rqstDdlnRemainedList);
            }
            else {
                alert(rtnval.errormsg);
            }
        },
		async showDetail(prjId) {
            this.fetching = true;
			const rtnval = await Api.post('/api/v1/process/get', {prjId: prjId, prcCd: this.prcCd});
			if(rtnval.result) {
				await this.fetchAttachedFiles(rtnval.data.attachFiles);
				if(this.prcCd == 'DS') {
					window.$('#modalDetail0').modal('show');
					const rtnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + prjId);
					this.detailForDesign.pdImg1 = rtnvalue.data.dsgnRprsnttvImgPath[0];
					this.detailForDesign.pdImg2 = rtnvalue.data.dsgnRprsnttvImgPath[1];
					this.detailForDesign.dsgnRqst = rtnval.data.bddngRqst + '<br><br>희망 연면적: ' + rtnvalue.data.prj.totalGroundArea + '㎡' + '<br><br>예상공사비용: ' + rtnvalue.data.prj.expectedCost + ' 억원';
                    this.fetching = false;
				}
				else {
					this.initDsgnFileList();
                    window.$('#modalDetail1').modal('show');
					const rtnvalue = await Api.post('/api/v1/prcess/step/get', {prjId: prjId, code: 'DI'});
					if(rtnvalue.result) {
						const returnvalue = await Api.post('/api/v1/common/get/attachments', {
							parentId: rtnvalue.data.prcStpId,
							parentUnit: 'PRCSTP',
							ascByCrtDt: true,
							limit: -1
						});
						if(returnvalue.result) {
                            if(returnvalue.data.attchments) {
                                returnvalue.data.attchments.forEach((item) => {
                                    var tokens = item.attchOrgNm.split('.');
                                    var mimeType = tokens[tokens.length-1].toLowerCase();
                                    var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
    
                                    const byteCharacters = atob(item.attchImg);
                                    const byteNumbers = new Array(byteCharacters.length);
                                    for (let i = 0; i < byteCharacters.length; i++) {
                                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                                    }
                                    const byteArray = new Uint8Array(byteNumbers);
                                    const blob = new Blob([byteArray], {type: fileType});
                                    const fileLink = URL.createObjectURL(blob);
                                    this.fileList[this.dsgnFilesCtgryIndexMap[item.attchFlCtgry.orderNum]].subList[this.dsgnFilesTypeIndexMap[item.attchFlTyp.orderNum]] = {id: item.id, name: item.attchOrgNm, nameNew: null, file:null, fileLink: fileLink};
                                })
                            }
							this.detailForDesign.dsgnRqst = rtnval.data.bddngRqst;
						}
						else {
							alert(returnvalue.errormsg);
						}
                        this.fetching = false;
					}
					else {
						alert(rtnvalue.errormsg);
					}
				}
			}
			else {
                alert(rtnval.errormsg);
            }
            this.fetching = false;
		},
		async fetchAttachedFiles(attachFiles) {
			this.attachmentsFetchedList = [];
            this.planImg = [];
			if(attachFiles) {
				var tempBucket = [];
				var k=0;
				for(var i=0; i<attachFiles.length; i++) {
					var prjAttchFlCtgry = attachFiles[i].prcAttchFlCtgry.code;
					if( prjAttchFlCtgry == 'BD' ) {
						var tokens = attachFiles[i].attchNm.split('.');
						var mimeType = tokens[tokens.length-1].toLowerCase();
						var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;

						const byteCharacters = atob(attachFiles[i].attchData);
						const byteNumbers = new Array(byteCharacters.length);
						for (let j = 0; j < byteCharacters.length; j++) {
							byteNumbers[j] = byteCharacters.charCodeAt(j);
						}
						const byteArray = new Uint8Array(byteNumbers);
						const blob = new Blob([byteArray], {type: fileType});
						const fileLink = URL.createObjectURL(blob);

						tempBucket.push({id: attachFiles[i].id, attchNm: attachFiles[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false})
						if((k++)%2 == 1) {
							this.attachmentsFetchedList.push(tempBucket);
							tempBucket = [];
						}
						else {
							if(i == attachFiles.length -1) {
								tempBucket.push({id: null, attchNm: null, fileLink: "/./files/img_legacy/nofileimage.jpg", deleted: false});
								this.attachmentsFetchedList.push(tempBucket);
							}
						}
					}
                    else if( prjAttchFlCtgry == "SP") {
                        this.planImg.push(attachFiles[i].attchWebPath)
                    }
                    else {
                        //계약서 업로드 이후의 예외처리?
                    }
				}
			}
		},
		closeModal() {
			window.$('#modalDetail0').modal('hide');
			window.$('#modalDetail1').modal('hide');
		}
    }
}
</script>