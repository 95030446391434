<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 과정 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:14px"></div>

          <div class="w-100 x_div_center" style="border-bottom:1px solid #c9c9c9">
            <div v-for="(prc, i) in prcCdList" :key="i" :style="'width:' + Math.floor(100/prcCdList.length) + '%'">
              <div :class="'x_div_center tap' + (currentTap == i ? ' active' : '')"><a href="#" @click="onClickTap(i)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == i ? ' fc-w1' : '')">{{ renderPrcType(prc) }}</span></a></div>
            </div>
          </div>

          <div class="py-4">
            <div class="x_div_end d-flex">
              <select class="custom-select ff-eng1 fs-085" v-model="searchType" style="width:200px; height:37px; margin-right: 20px">
                <option value="EM">건축주명 또는 이메일 검색</option>
                <option value="PJ">프로젝트명 검색</option>
              </select>
              <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" @keydown.enter="findPrjList()" v-model="searchKeyword"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem" placeholder="프로젝트 A" ></div>
                <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="findPrjList()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              </div>
            </div>
            <div class="x_div_end">
              <div id="auto_frm" v-show="isSearch" class="p-2" style="display:none; position:absolute; top:235px; width:245px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center ls_bg1">
                  <div class="w-100 text-start py-1 px-2">
                    <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.prjId" class="fa fa-spinner fa-spin" style="color:#333"></i>
                    <a v-else href="javascript:void(0)" @click="callFindPrjWrttnCntrct(item.prjId)">{{item.prjNm.split(';')[0]}} <br>&nbsp;&nbsp;&nbsp;&nbsp; - {{ item.crrntStts }}</a>
                  </div>
                  <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-3">

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>사업 파트너</strong></span></td>
                  <td v-for="(prc, i) in prcCdList" :key="i" class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{ renderPrcType(prc) }}사</strong></span></td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" ><a href="javascript:void(0)" @click="moveToPrj();"><span class="ff-kor2" style="font-size:12pt">{{prjNm ? prjNm.split(';')[0] : ''}}</span></a></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{ownrNm}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{bsnssPrtnrNm}}</span></td>
                  <td v-for="(name, i) in prtnrNm" :key="i" class="text-center br_0" ><span class="ff-kor2" :style="'font-size:12pt;' + (name == '탭 클릭시 표시'? 'color: lightgrey' : '')">{{name}}</span></td>
                </tr>
              </table>
            </div>
          </div>
          <div style="height:50px"></div>

          <div class="pg_m_set" v-if="fetching">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
          </div>
          <div v-else>
            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="20%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>{{prjNm ? prjNm.split(';')[0] : ''}}</strong></span></td>
                  <td colspan="4" class="text-center" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>고객 연락처 {{(ownrPhone) ? '('+(ownrPhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))+')' : ''}}</strong></span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"><strong>요청사항</strong></span></td>
                  <td colspan="4" class="text" style="padding: 15px;"><span class="ff-kor1" style="font-size:12pt;" v-html="dsgnRqst ? dsgnRqst : ''"></span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"></span></td>
                  <td class="text-center br_0" width="20%"><span class="ff-kor1" style="font-size:12pt"></span></td>
                  <td class="text-center br_0" width="20%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>등록일</strong></span></td>
                  <td class="text-center br_0" width="20%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>파트너기관</strong></span></td>
                  <td class="text-center" width="20%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>연락처</strong></span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td :rowspan="getCandidateRowspan()" class="text-center br_0" width="20%" style="background-color:#eaeaea">
                    <span class="ff-kor1" style="font-size:12pt"><strong>참여 의향 요청</strong></span>
                    <span class="ff-kor1" style="font-size:12pt" v-if="currentTap == 0"><br><strong>및 기획설계비용 납부</strong></span>
                    <span class="ff-kor1" style="font-size:12pt" v-if="bddngJoinDdln && (!wllngRegDtoList || wllngRegDtoList.length == 0)"><br>{{ '(참여의향등록마감: ' + bddngJoinDdln.split('T')[0] + ')' }}</span>
                  </td>
                  <td class="text-center br_0" width="20%"><span class="ff-eng1" style="font-size:12pt"></span></td>
                  <td class="text-center br_0" width="20%">
                    <span class="ff-eng1" style="font-size:12pt">{{wllngRegDt ? wllngRegDt.split('T')[0] : ''}}</span>
                  </td>
                  <td class="text-center br_0" width="20%"><span class="ff-eng1" style="font-size:12pt"></span></td>
                  <td class="text-center" width="20%"><span class="ff-eng1" style="font-size:12pt"></span></td>
                </tr>
                <tr v-if="currentTap == 0" style="border-bottom:1px solid #c9c9c9">
                    <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt"><strong>납부현황</strong></span>
                      <div class="x_div_center py-2">
                        <input type="file" @change="fileHandling($event)" class="form-control ff-eng1 text-center" style="width:200px; height:36px" />
                      </div>
                      <div class="x_div_center py-2">
                        <button @click="uploadRcpt()" class="x_div_center" style="width:120px; height:30px; border:0; background-color:#4e0000; border-radius:4px">
                          <span class="fa-kor-Noto fs-08 fc-w1">영수증 업로드<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span>
                        </button>
                      </div>
                    </td>
                    <td class="text-center br_0">
                      <div class="x_div_center py-2">
                        <span class="ff-eng1" style="font-size:12pt">{{(attchRegDt) ? attchRegDt.split('T')[0] : ''}}</span>
                      </div>
                      <div v-if="attchRegDt" class="x_div_center py-2">
                        <button class="x_div_center" style="width:120px; height:30px; border:0; background-color:#4e0000; border-radius:4px">
                          <a :href="rcptFileLink" :download="rcptOrgNm"><span class="fa-kor-Noto fs-08 fc-w1">영수증 다운로드</span></a>
                        </button>
                      </div>
                    </td>
                    <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                    <td class="text-center"><span class="ff-eng1" style="font-size:12pt"></span></td>
                </tr>          
                <template v-for="(item, index) in wllngRegDtoList">
                  <tr :key="index" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <template v-if="index == 0">
                      <td :rowspan="getRowspan(item)" class="text-center br_0" style="background-color:#eaeaea">
                        <span class="ff-kor1" style="font-size:12pt"><strong>제안 참여 의향 등록</strong></span>
                        <span class="ff-kor1" style="font-size:12pt" v-if="bddngJoinDdln"><br>{{ '(마감일: ' + bddngJoinDdln.split('T')[0] + ')' }}</span>
                      </td>
                      <td :rowspan="getRowspan(item)" class="text-center br_0"><span class="ff-eng1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{item.crtDt ? item.crtDt.split('T')[0] : ''}}</span></td>
                      <td class="text-center br_0"><a href="javascript:void(0)" @click="showDetail(item.bddngId, item.ceoMessage)"><span class="ff-kor1" style="font-size:12pt"><u>{{item.candidateName}}</u></span></a></td>
                      <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.candidatePhone ? (item.candidatePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)) : ''}}</span></td>
                    </template>
                    <template v-else>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{item.crtDt ? item.crtDt.split('T')[0] : ''}}</span></td>
                      <td class="text-center br_0"><a href="javascript:void(0)" @click="showDetail(item.bddngId, item.ceoMessage)"><span class="ff-kor1" style="font-size:12pt"><u>{{item.candidateName}}</u></span></a></td>
                      <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.candidatePhone ? (item.candidatePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)) : ''}}</span></td>
                    </template>
                  </tr>
                </template>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea">
                    <span class="ff-kor1" style="font-size:12pt"><strong>후보 업체 선정</strong></span>
                    <span class="ff-kor1" style="font-size:12pt" v-if="bddngNominateDdln"><br>{{ '(마감일: ' + bddngNominateDdln.split('T')[0] + ')' }}</span>
                  </td>
                  <td class="text-center br_0" style="padding:1.0rem 0;"><span class="ff-kor1" style="font-size:11pt"><strong>업체 선정 현황</strong></span>
                    <template v-for="(item, index) in bddngCandidateInfoDtoList">
                      <span :key="index">
                        <template v-if="index == 0">
                          <br>① {{item.candidateName}}
                        </template>
                        <template v-else-if="index == 1">
                          <br>② {{item.candidateName}}
                        </template>
                        <template v-else-if="index == 2">
                          <br>③ {{item.candidateName}}
                        </template>
                      </span>
                    </template>
                  </td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{sbmsnDt ? sbmsnDt.split('T')[0] : ''}}</span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                  <td class="text-center"><span class="ff-eng1" style="font-size:12pt"></span></td>
                </tr>
                <template v-if="registerOfferBddngDtoList.length == 0">
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td class="text-center br_0" style="background-color:#eaeaea">
                      <span class="ff-kor1" style="font-size:12pt"><strong>제안 등록</strong></span>
                      <span class="ff-kor1" style="font-size:12pt" v-if="bddngProposalDdln && (currentTap != 0 || attchRegDt)"><br>{{ '(마감일: ' + bddngProposalDdln.split('T')[0] + ')' }}</span>
                    </td>
                    <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                    <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                    <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                    <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                  </tr>
                </template>
                <template v-for="(item, index) in registerOfferBddngDtoList">
                  <tr :key="`${item.candidatePhone}`+`${index}`" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <template v-if="item.candidateId == registerOfferBddngDtoList[0].candidateId">
                      <td :rowspan="getRowspan(item)" class="text-center br_0" style="background-color:#eaeaea">
                        <span class="ff-kor1" style="font-size:12pt"><strong>제안 등록</strong></span>
                        <span class="ff-kor1" style="font-size:12pt"><br>{{ '(마감일: ' + bddngProposalDdln.split('T')[0] + ')' }}</span>
                      </td>
                      <td :rowspan="getRowspan(item)" class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{item.proposalDt ? item.proposalDt.split('T')[0] : ''}}</span></td>
                      <td class="text-center br_0" style="display:block; justify-content: center; padding:5px">
                        <div class="x_div_center py-2">
                          <span class="ff-kor1" :style="'font-size:12pt; color:' + item.proposalStatus">{{item.candidateName}}</span>
                        </div>
                        <div class="x_div_center py-2">
                          <a :href="item.fileLink" :download="item.candidateName + '_' + item.attchNm" ><span class="ff-kor1" style="font-size:12pt"><u>{{ item.attchNm }}</u></span></a>
                        </div>
                        <div class="x_div_center py-2" v-if="item.webPath">
                          <a :href="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + item.webPath" target="_blank">
                            <img style="width:100%;" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + item.webPath">
                          </a>
                        </div>
                        <div class="x_div_center py-2">
                          <button @click="openConfirmationModal(item)" class="x_div_center" style="width:120px; height:30px; border:0; background-color:#4e0000; border-radius:4px">
                            <span class="fa-kor-Noto fs-08 fc-w1">공개여부 심사</span>
                          </button>
                        </div>
                      </td>
                      <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.candidatePhone ? (item.candidatePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)) : ''}}</span></td>
                    </template>
                    <template v-else>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{item.proposalDt ? item.proposalDt.split('T')[0] : ''}}</span></td>
                      <td class="text-center br_0" style="display:block; justify-content: center; padding:5px">
                        <div class="x_div_center py-2">
                          <span class="ff-kor1" :style="'font-size:12pt; color:' + item.proposalStatus">{{item.candidateName}}</span>
                        </div>
                        <div class="x_div_center py-2">
                          <a :href="item.fileLink" :download="item.candidateName + '_' + item.attchNm" ><span class="ff-kor1" style="font-size:12pt"><u>{{ item.attchNm }}</u></span></a>
                        </div>
                        <div class="x_div_center py-2" v-if="item.webPath">
                          <a :href="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + item.webPath" target="_blank">
                            <img style="width:100%;" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + item.webPath">
                          </a>
                        </div>
                        <div class="x_div_center py-2">
                          <button @click="openConfirmationModal(item)" class="x_div_center" style="width:120px; height:30px; border:0; background-color:#4e0000; border-radius:4px">
                            <span class="fa-kor-Noto fs-08 fc-w1">공개여부 심사</span>
                          </button>
                        </div>
                      </td>
                      <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.candidatePhone ? (item.candidatePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)) : ''}}</span></td>
                    </template>
                  </tr>
                </template>
                <tr style="border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>후보 업체 평가 및 선정</strong></span></td>
                  <td class="text-center br_0" style="padding:1.0rem 0;">
                    <span class="ff-kor1" style="font-size:11pt"><strong>순위 선정 현황</strong></span>
                    <template v-for="(item, index) in evaluateCompanyDtoList">
                      <span :key="index">
                        <template v-if="index == 0">
                          <br>① {{item.candidateName}}
                        </template>
                        <template v-else-if="index == 1">
                          <br>② {{item.candidateName}}
                        </template>
                        <template v-else-if="index == 2">
                          <br>③ {{item.candidateName}}
                        </template>
                      </span>
                    </template>
                    <div v-if="evaluateCompanyDtoList.length" class="x_div_center py-2">
                      <button @click="confirmRank()" class="x_div_center" style="width:80px; height:30px; border:0; margin-right: 10px; background-color:#4e0000; border-radius:4px">
                        <span class="fa-kor-Noto fs-08 fc-w1">확정<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span>
                      </button>
                      <button @click="resetRank()" class="x_div_center" style="width:80px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000">
                        <span class="fa-kor-Noto fs-08">반려<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span>
                      </button>
                    </div>
                  </td>
                  <td class="text-center br_0">
                    <span class="ff-eng1" style="font-size:12pt">{{offerRsltRnkngDt ? offerRsltRnkngDt.split('T')[0] : ''}}</span>
                    <div v-if="(prtnrId && !offerRsltRnkngDt)" class="x_div_center py-2"><button @click="extendPeriod(currentTap)" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fa-kor-Noto fs-085">연장하기</span></button></div>
                  </td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                  <td class="text-center"><span class="ff-eng1" style="font-size:12pt"></span></td>
                </tr>
                <template v-for="(item, index) in evaluateCompanyResultDtoList">
                  <tr :key="`${item.candidateId}`+`${index}`" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <template v-if="item.candidateId == evaluateCompanyResultDtoList[0].candidateId">
                      <td :rowspan="getRowspan(item)" class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>제안 평가 결과 확인</strong></span></td>
                      <td :rowspan="getRowspan(item)" class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td :rowspan="getRowspan(item)" class="text-center br_0"><span class="ff-eng1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0" style="padding:1.0rem 0;">
                        <div class="x_div_center py-2" style="display:grid !important">
                          <span class="ff-kor1" style="font-size:12pt">{{item.candidateName}}</span>
                          <span class="ff-kor1 fw-bold" style="font-size:12pt">최종계약대상</span>
                        </div>
                        <div class="x_div_center py-2">
                          <button v-if="rcptList && rcptList[index] && rcptList[index].fileLink && rcptList[index].orgNm" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px">
                            <a :href="rcptList[index].fileLink" :download="rcptList[index].orgNm"><span class="fa-kor-Noto fs-08 fc-w1">영수증 조회</span></a>
                          </button>
                        </div>
                      </td>
                      <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.candidatePhone ? (item.candidatePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)) : ''}}</span></td>
                    </template>
                    <template v-else>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt"></span></td>
                      <td class="text-center br_0" style="padding:1.0rem 0;">
                        <div class="x_div_center py-2" style="display:grid !important">
                          <span class="ff-kor1" style="font-size:12pt">{{item.candidateName}}</span>
                          <span class="ff-kor1" style="font-size:12pt">- {{ index+1 }}위 -</span>
                        </div>
                        <div class="x_div_center py-2">
                          <button v-if="rcptList && rcptList[index] && rcptList[index].fileLink && rcptList[index].orgNm" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px">
                            <a :href="rcptList[index].fileLink" :download="rcptList[index].orgNm"><span class="fa-kor-Noto fs-08 fc-w1">영수증 조회</span></a>
                          </button>
                        </div>
                      </td>
                      <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{item.candidatePhone ? (item.candidatePhone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)) : ''}}</span></td>
                    </template>
                  </tr>
                </template>
                <tr style="height:55px">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약</strong></span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt"></span></td>
                  <td class="text-center br_0"><span class="ff-eng1" style="font-size:12pt">{{cntrctDt ? cntrctDt.split('T')[0] : ''}}</span></td>
                  <td class="text-center br_0"><span class="ff-kor1" style="font-size:12pt">{{fullName}}</span></td>
                  <td class="text-center"><span class="ff-eng1" style="font-size:12pt">{{phone ? phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : ''}}</span></td>
                </tr>
              </table>
            </div>

          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
    <div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
  
                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">제안서 공개여부 심사</span></div>
                        <div class="text-end pe-4" style="width:100px"><a href="javascript:void(0)" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                    </div>
  
                    <div class="x_div_center py-2">
                        <div class="x_div_center px-4 w-100">
  
                          <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                              <table class="table-borderless w-100">
                                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                    <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>심사내용</strong></span></td>
                                </tr>
                                <tr v-for="(item, index) in commentsToDisplay" :key="index">
                                    <td class="br_0" style="border-bottom: 1px solid rgb(201, 201, 201); padding: 10px" v-html="item"></td>
                                </tr>
                                <tr>
                                  <textarea class="w-100 form-control" v-model="newComment" placeholder="승인 결과, 또는 반려사유 등을 입력하세요. 해당 내용은 파트너기관에게 전달됩니다." style="height:200px;"></textarea>
                                </tr>
                              </table>
                          </div>
  
                        </div>
                    </div>
  
                    <div class="x_div_center py-4">
                        <div class="px-2"><button class="x_div_center" @click="confirmProposal(true)" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fkr-set2">승인<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                        <div class="px-2"><button class="x_div_center" @click="confirmProposal(false)" style="width:90px; height:30px; border:0; background-color:#c00; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">반려<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                    </div>
  
                    <div style="height:20px"></div>
  
                </div>
  
            </div>
        </div>
    </div>
    <!-- 참여의향 조회 -->
    <div id="modalParticipation" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
      <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

              <div class="w-100">
                  <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                      <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">지원 내용</span></div>
                      <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                  </div>

                  <div class="py-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">대표자 인사</span></div>

                  <div class="x_div_center px-4">
                      <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                          <div class="py-2 px-2" v-html="ceoMessage"></div>
                      </div>
                  </div>

                  <div class="x_div_center py-2">
                      <div class="x_div_center px-4 w-100">

                      <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                          <table class="table-borderless w-100">
                          <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                              <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>포트폴리오</strong></span></td>
                              <td class="text-center" width="50%"><span class="fkr-set1"><strong>기타홍보자료</strong></span></td>
                          </tr>
                          <tr style="height:55px;">
                              <td class="text-center br_0">
                                  <div class="x_div_center"><a :href="pfFileLink" :download="pfOrgNm"><span class="fkr-set2">{{pfOrgNm}}</span></a></div>
                              </td>
                              <td class="text-center">
                                  <div class="x_div_center"><a :href="prFileLink" :download="prOrgNm"><span class="fkr-set2">{{prOrgNm}}</span></a></div>
                              </td>
                          </tr>
                          </table>
                      </div>

                      </div>
                  </div>

                  <div class="x_div_center py-4">
                      <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                  </div>

                  <div style="height:20px"></div>

              </div>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from "@/api";

export default {
  name: "BddngPrcssMng",
  components:{
    AdminLNB
  },
  data(){
    return {
      isSearch:false,
      prcCdList: ['DS','MA','CN','IN','DE'],
      prjId: null,
      prjNm:'',
      prjList:[],
      searchKeyword : '', // 검색 키워드
      searchType:'PJ',
      processId:[],
      prtnrNm:[],
      currentTap: 0,
      ownrPhone:'',//고객 연락처
      dsgnRqst:'',
      wllngRegDt:'',//참여 의향 요청일
      wllngRegDtoList:[],//제안 참여 의향 등록 리스트
      bddngJoinDdln: '',//제안참여의향등록 마감일
      bddngNominateDdln: '',//후보업체선정 및 기획설계비용납부 마감일
      bddngProposalDdln: '',//제안마감일
      bddngCandidateInfoDtoList:[],//후보 업체 선정 리스트
      sbmsnDt:'',
      attchRegDt:'',//납부 현황 날짜(영수증 업로드 날짜)
      registerOfferBddngDtoList:[],//제안 등록 정보 리스트
      evaluateCompanyDtoList:[],//후보 업체 평가 및 선정 리스트
      offerRsltRnkngDt:'',
      evaluateCompanyResultDtoList:[],//제안 평가 결과 확인 리스트
      prtnrId:0,
      fullName:'',
      cntrctDt:'',
      phone:'',
      extendYn:'',
      attchmnt:null,//영수증
      orgNm:'',//M3가 업로드하는 영수증 원래 파일명
      rcptOrgNm: '',
      rcptFileLink: '',
      rcptList:[],
      ceoMessage: '',
      pfOrgNm: '',
      pfFileLink: '',
      prOrgNm: '',
      prFileLink: '',
      ownrNm:'',
      bsnssPrtnrNm:'',
      proposalBddngId: null,
      proposalBddngNominatedDt: null,
      proposalComments: [],
      commentsToDisplay: [],
      cntOffset: 0,
      newComment: '',
      fetching: false,
      submitting: false,
      s3region: process.env.VUE_APP_S3_REGION,
      s3bucket: process.env.VUE_APP_S3_BUCKET
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    this.prcCdList.forEach(() => {
      this.prtnrNm.push('탭 클릭시 표시');
    })
    this.currentTap = 0;
    this.prcCdList.forEach(() => {
      this.processId.push(0);
    })
    if(this.$route.query.prjId) {
      this.prjId = this.$route.query.prjId;
      this.prcCdList.forEach((prcCd, i) => {
        if(prcCd == this.$route.query.prcCd) {
          this.currentTap = i;
        }
      })
      await this.findBddngPrcss();
    }
  },

  methods: {
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    fileHandling(e){
      this.attchmnt = e.target.files[0];
      this.orgNm = e.target.files[0].name;
    },

    async uploadRcpt(){
      if(!this.attchmnt){
        alert('업로드된 파일이 없습니다.');
        return;
      }
      const formData = new FormData();
      var request = null;
      var rtnval = null;
      if(this.currentTap === 0){
        request = {
          parentId: [this.processId[0]],
          attchOrgNmList: [this.orgNm],
          attchFlCtgry: ['RT']
        };

        formData.append("attchmntList", this.attchmnt);
        formData.append(
            "request",
            new Blob([JSON.stringify(request)], { type: "application/json" })
        );
        this.submitting = true;
        rtnval = await Api.post('/api/v1/common/set/attachments', formData);
        if(rtnval.result) {
          alert("영수증이 등록되었습니다.\n파트너 설계사들에게 참여 의향 요청을 공개합니다.");
          await this.findBddngPrcss();
        }
        else {
          alert(rtnval.errormsg)
        }
        this.submitting = false;
      }
    },

    async extendPeriod(processCode){
      if(confirm('연장하시겠습니까?')) {
        if(!this.prjId){
          alert('프로젝트 id가 없습니다.');
          return;
        }
        var rtnval = null;
        rtnval = await Api.post('/api/v1/admin/' + this.prcCdList[processCode] + '/bddng/prcss/mng/update/bddng/evaluate/candidate/expr/drtn',{prjId:this.prjId});
        if(rtnval.result) {
          await this.findBddngPrcss();
        }
        else {
          alert(rtnval.errormsg)
        }
      }
    },

    openConfirmationModal(bddng) {
      this.proposalBddngId = bddng.bddngId;
      this.proposalBddngNominatedDt = bddng.nominatedDt;
      this.proposalComments = bddng.proposalComment? (bddng.proposalComment.indexOf(';') >= 0? bddng.proposalComment.split(';') : [bddng.proposalComment]) : [];
      this.commentsToDisplay = this.proposalComments.filter((v) => v != 'nbsp');
      this.cntOffset = bddng.cntOffset;
      window.$('#modalForm').modal('show');
    },

    async confirmProposal(pass) {
      if(!this.newComment) {
        alert('심사내용 입력은 필수입니다.');
        return;
      }

      if(this.newComment.indexOf(';') >= 0) {
        alert('심사내용에는 ";" 문자가 포함될 수 없습니다.');
        return;
      }

      if(!confirm(pass? '제안 제출을 승인하시겠습니까?\n승인할 경우 해당 제안서는 건축주에게 공개됩니다.' : '제안 제출을 반려하시겠습니까?')) {
        this.closeModal();
        return;
      }

      for(var i=0; i < this.cntOffset; i++) {
        this.proposalComments.push(i == 0? this.newComment.replace(/\n/g, '<br/>') : 'nbsp');
      }

      this.submitting = true;
      const rtnval = await Api.post('/api/v1/bidding/set', {
        bddngId: this.proposalBddngId,
        proposalComment: this.proposalComments.join(';'),
        proposalConfirmedDt: pass? new Date().toISOString() : null,
        nominatedDt: this.proposalBddngNominatedDt,
        evalRank: 0
      });
      if(rtnval.result) {
        alert(pass? "제안서 심사가 통과되어 건축주에게 공개되었습니다." : "제안서 심사가 반려처리 되었습니다.");
        this.closeModal();
        await this.findBddngPrcss();
      }
      else {
        alert(rtnval.errormsg)
        this.closeModal();
      }
      this.submitting = false;
    },

    closeModal() {
      this.newComment = '';
			window.$('#modalForm').modal('hide');
      window.$('#modalParticipation').modal('hide');
		},

    async confirmRank() {
      if(confirm('순위를 확정하시겠습니까?')) {
        this.evaluateCompanyDtoList.forEach((item) => {
          item.attchData = null;
          item.proposalComment = null;
          item.evalRank = -item.evalRank;
          item.evaluatedDt = new Date().toISOString();
        })
        this.submitting = true;
        const rtnval = await Api.post('/api/v1/bidding/set/multi', this.evaluateCompanyDtoList);
        this.submitting = false;
        if(rtnval.result) {
            await this.findBddngPrcss();
        }
        else {
            alert(rtnval.errormsg)
        }
      }
    },

    async resetRank() {
      this.evaluateCompanyDtoList.forEach((item) => {
        item.proposalComment = null;
        item.evalRank = 0;
      })
      this.submitting = true;
      const rtnval = await Api.post('/api/v1/bidding/set/multi', this.evaluateCompanyDtoList);
      this.submitting = false;
      if(rtnval.result) {
          await this.findBddngPrcss();
      }
      else {
          alert(rtnval.errormsg)
      }
    },

    getCandidateRowspan(){
      if(this.currentTap == 0){
        return 2;
      }else{
        return 1;
      }
    },

    getRowspan(item){
      return item.length;
    },

    async findBddngPrcss(){
      this.fetching = true;
      let returnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
      if(returnvalue.result) {
        this.prjNm = returnvalue.data.prj.prjNm;
        this.bsnssPrtnrNm = returnvalue.data.prj.bsnssPrtnrEmail;
        this.dsgnRqst = '<br><br>희망 연면적: ' + returnvalue.data.prj.totalGroundArea + '㎡' + '<br><br>예상공사비용: ' + returnvalue.data.prj.expectedCost + ' 억원';
        await this.onClickTap(this.currentTap);
      }
      else {
        alert(returnvalue.errormsg)
      }
      this.fetching = false;
    },

    async callFindPrjWrttnCntrct(prjId) {
      this.prjId = prjId;
      location.href='/admin/bddng/bddng-prcss-mng?prjId=' + prjId;
    },
    
    moveToPrj() {
        sessionStorage.setItem('prjId', this.prjId);
        window.open('about:blank').location.href='/project/summary/' + this.prjId
    },

    renderPrcType(processType) { 
      var rsp = '';
      switch(processType) {
        case 'DS' : rsp = '설계';
          break;
        case 'MA' : rsp = '제조';
          break;
        case 'CN' : rsp = '시공';
            break;
        case 'IN' : rsp = '인테리어';
          break;
        case 'DE' : rsp = '운송';
          break;
      }
      return rsp;
    },

    async findPrjList(){
      if(!this.searchKeyword){
        alert('프로젝트 검색어를 입력해주세요.');
        return;
      }
      this.isSearch = true;
      this.prjList = [{prjId:null}];
      const rtnval = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: false})
      if(rtnval.result) {
          this.prjList = rtnval.data.findPrjDtoList.sort((a, b) => {
                  return Number(Boolean(b.lastUpdatedPrcInfo.totalAmounts)) - Number(Boolean(a.lastUpdatedPrcInfo.totalAmounts))
                });
          const rtnvalue = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: true})
          if(rtnvalue.result) {
            this.prjList = this.prjList.concat(rtnvalue.data.findPrjDtoList);
          }
      }
    },

    async showDetail(bddngId, ceoMessage) {
        this.ceoMessage = ceoMessage;
        this.pfOrgNm = null;
        this.pfFileLink = null;
        this.prOrgNm = null;
        this.prFileLink = null;
        const rtnval = await Api.post('/api/v1/common/get/attachments', {
            parentId: bddngId,
            parentUnit: 'BDDNG',
            ascByCrtDt: false,
            limit: -1
        });
        if(rtnval.result) {
            if(rtnval.data.attchments) {
                rtnval.data.attchments.forEach((file) => {
                    if(file.attchFlCtgry.code == 'PF' || file.attchFlCtgry.code == 'PR') {
                        var tokens = file.attchOrgNm.split('.');
                        var mimeType = tokens[tokens.length-1].toLowerCase();
                        var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
        
                        var byteCharacters = atob(file.attchImg);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var blob = new Blob([byteArray], {type: fileType});
                        if(file.attchFlCtgry.code == 'PF') {
                            this.pfOrgNm = file.attchOrgNm;
                            this.pfFileLink = URL.createObjectURL(blob);
                        }
                        else {
                            this.prOrgNm = file.attchOrgNm;
                            this.prFileLink = URL.createObjectURL(blob);
                        }
                    }
                })
            }
            window.$('#modalParticipation').modal('show');
        }
        else {
            alert(rtnval.errormsg)
        }
    },

    async onClickTap(c) {
      this.currentTap = c;
      let rtnvalue = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.prcCdList[c]});
      if(rtnvalue.result) {
        if(rtnvalue.data.prcId) {
          this.ownrNm = rtnvalue.data.ownrInfo.fullName;
          this.processId[c] = rtnvalue.data.prcId;
          this.dsgnRqst = c? rtnvalue.data.bddngRqst : rtnvalue.data.bddngRqst + this.dsgnRqst;;
          this.wllngRegDt = rtnvalue.data.bddngCrtDt;
          this.wllngRegDtoList = rtnvalue.data.bddngs;
          this.bddngJoinDdln = rtnvalue.data.bddngJoinDdln;
          this.bddngNominateDdln = rtnvalue.data.bddngNominateDdln;
          this.bddngProposalDdln = rtnvalue.data.bddngProposalDdln;
          this.bddngCandidateInfoDtoList = [];
          this.registerOfferBddngDtoList = [];
          this.evaluateCompanyDtoList = [];
          this.evaluateCompanyResultDtoList = [];
          this.sbmsnDt = ''
          this.offerRsltRnkngDt = '';
          if(this.wllngRegDtoList) {
            this.wllngRegDtoList.forEach((bddng) => {
              if(bddng.nominatedDt) {
                this.bddngCandidateInfoDtoList.push(bddng);
                if(bddng.proposalDt) {
                  if(bddng.proposalCnt > 0) {
                    var tokens = bddng.attchNm.split('.');
                    var mimeType = tokens[tokens.length-1].toLowerCase();
                    var fileType = 'image/' + mimeType;
      
                    const byteCharacters = atob(bddng.attchData);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: fileType});
                    bddng.fileLink = URL.createObjectURL(blob);
                  }
                  const numOfComments = bddng.proposalComment? (bddng.proposalComment.indexOf(';') >=0 ? bddng.proposalComment.split(';').length : 1) : 0
                  bddng.cntOffset = Number(bddng.proposalCnt) + Number(bddng.renderedCnt) - numOfComments;
                  bddng.proposalStatus = bddng.proposalConfirmedDt? 'green' : (bddng.cntOffset > 0? 'orange' : 'red');
                  this.registerOfferBddngDtoList.push(bddng);
                }
                if(bddng.evalRank > 0) {
                  this.evaluateCompanyDtoList.push(bddng);
                  this.offerRsltRnkngDt = bddng.evaluatedDt;
                }
                if(bddng.cntrctPriority) {
                  this.evaluateCompanyResultDtoList.push(bddng);
                }
                this.sbmsnDt = bddng.nominatedDt;
              }
            })
            this.evaluateCompanyDtoList.sort((a,b) => { return a.evalRank - b.evalRank});
            this.evaluateCompanyResultDtoList.sort((a,b) => { return a.cntrctPriority - b.cntrctPriority});
          }
          this.attchRegDt = '';
          this.rcptOrgNm = '';
          this.rcptFileLink = '';
          this.cntrctDt = '';
          if(rtnvalue.data.attachFiles) {
            rtnvalue.data.attachFiles.forEach((file) => {
              if(file.prcAttchFlCtgry.code == 'RT') {
                this.attchRegDt = file.crtDt;
  
                this.rcptOrgNm = file.attchNm;
                var tokens = this.rcptOrgNm.split('.');
                var mimeType = tokens[tokens.length-1].toLowerCase();
                var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
  
                var byteCharacters = atob(file.attchData);
                var byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var blob = new Blob([byteArray], {type: fileType});
                this.rcptFileLink = URL.createObjectURL(blob);
              }
              if(file.prcAttchFlCtgry.code == 'CT') {
                this.cntrctDt = file.crtDt;
              }
            })
          }
          if(rtnvalue.data.prtnrInfo) {
            this.prtnrId = rtnvalue.data.prtnrInfo.usrId;
            this.fullName = rtnvalue.data.prtnrInfo.fullName;
            this.phone = rtnvalue.data.prtnrInfo.phone;
          }
          else {
            this.prtnrId = null;
            this.fullName = null;
            this.phone = null;
            this.cntrctDt = null;
          }
  
          this.rcptList = [];
          var rtnval = null;
          for (let i = 0; i < this.evaluateCompanyResultDtoList.length; i++) {
            rtnval = await Api.post('/api/v1/common/get/attachments',{
              parentId: this.evaluateCompanyResultDtoList[i].bddngId,
              parentUnit: 'BDDNG',
              attchFlCtgry: 'RC',
              ascByCrtDt: true,
              limit: 1
            });
            if(rtnval.result) {
              if(rtnval.data.attchments){
                var tokens = rtnval.data.attchments[0].attchOrgNm.split('.');
                var mimeType = tokens[tokens.length-1].toLowerCase();
                var fileType = 'image/' + mimeType;
  
                const byteCharacters = atob(rtnval.data.attchments[0].attchImg);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: fileType});
                const fileLink = URL.createObjectURL(blob);
                this.rcptList.push({orgNm : rtnval.data.attchments[0].attchOrgNm, attchImg : rtnval.data.attchments[0].attchImg, fileLink : fileLink});
              }else{
                this.rcptList.push({orgNm : '', attchImg : null, fileLink : null});
              }
            }
            else {
              alert(rtnval.errormsg)
            }
          }
        }
        else {
          this.dsgnRqst = ''; 
          this.wllngRegDt = ''; //참여 의향 요청일
          this.wllngRegDtoList = []; //제안 참여 의향 등록 리스트
          this.bddngCandidateInfoDtoList = []; //후보 업체 선정 리스트
          this.sbmsnDt = ''; 
          this.attchRegDt = ''; //납부 현황 날짜(영수증 업로드 날짜)
          this.registerOfferBddngDtoList = []; //제안 등록 정보 리스트
          this.evaluateCompanyDtoList = []; //후보 업체 평가 및 선정 리스트
          this.offerRsltRnkngDt = ''; 
          this.evaluateCompanyResultDtoList = []; //제안 평가 결과 확인 리스트
          this.prtnrId = 0; 
          this.prtnrNm[c] = ''; 
          this.cntrctDt = ''; 
          this.phone = ''; 
          this.extendYn = ''; 
          this.attchmnt = null; //영수증
          this.orgNm = ''; //M3가 업로드하는 영수증 원래 파일명
          this.rcptOrgNm =  ''; 
          this.rcptFileLink =  ''; 
          this.rcptList = []; 
        }
      }
      else {
        alert(rtnvalue.errormsg)
      }
    }
  }
}
</script>

<style scoped>
.x_div_center.tap {
  height:50px;
  background-color:#fff;
}
.x_div_center.tap.active {
  height:50px;
  background-color:#4e0000;
  border-top-left-radius:8px;
  border-top-right-radius:8px
}
</style>