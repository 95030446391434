<template>
    <div></div>
</template>
<script>
    import Api from '@/api'
    export default {
        name: 'EstimationDemo',
        data() {
            return {
                unitStandardList: []
            }
        },
        async mounted() {
            // 견적산출 기능을 실행할 페이지가 로드되면 아래 API를 호출하여 서버에 저장된 BIM 자재별 수량산출방식 및 필요 정보들을 조회한다.
            const rtnval = await Api.get('/api/v1/qntycalcmthd/list/qntycalcmthd');
            if(rtnval.result) {
                rtnval.data.forEach((us) => {
                    this.unitStandardList.push({
                        bimObj: us.bimObjType,
                        bimNm: us.bimMtrlNm,
                        prcNo: us.workCd,
                        prcNm: us.workCls,
                        standard: us.stndrd,
                        unit: us.unit,
                        formular: us.qntyCalcForm,
                        cost: us.unitPrice
                    })
                })
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        methods: {
            // 견적산출 버튼을 클릭하여 BIM 일람표 Excel 파일들이 업로드되면 실행되는 함수
            async exportInvoice(e) {
                var materials = [];
                const files = e.target.files;
                if(files && files.length >0) {
                    for(var i=0; i < files.length; i++) {
                        const fileData = await this.readFileAsync(files[i]);    // 각각의 Excel 파일을 JavaScript로 읽어들이는 함수 호출
                        const wb = XLSX.read(fileData, {type : 'binary'});      // 해당 파일데이터를 Excel 구조를 재현한 객체로 변환
                        const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);   // 해당 Excel파일의 첫 번째 시트상의 데이터를 JSON 객체로 변환
                        rowObj.forEach((row, i) => {    // JSON 객체의 각 레코드(Excel 시트의 행 데이터)를 순회하며 파일내의 각 BIM 자재별 객체타입(바닥, 벽, 천장), 자재명 및 소요면적 데이터를 materials 행렬에 저장
                            if(i >0) {
                                var bimObj = '';
                                var bimNm = '';
                                var area = 0;
                                Object.entries(row).forEach(([k,v]) => {
                                    if(k.indexOf('Takeoff') >= 0) {
                                        bimObj = k.split(' ')[0].replace('#', '');
                                        bimNm = v;
                                    }
                                    else {
                                        area = Number(v.split(' ')[0]);
                                    }
                                });
                                materials.push({bimObj: bimObj, bimNm: bimNm, area: area});
                            }
                        })
                    }
                    this.exportExcel(materials);    // 모든 Excel 파일 데이터의 BIM 자재별 정보를 materials 행렬에 저장한 후, 이를 토대로 견적산출 내역서 Excel 파일을 생성하는 함수를 호출
                }
            },
            
            readFileAsync(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    }
                    reader.readAsBinaryString(file);
                    reader.onerror = reject;
                })
            },

            exportExcel(materials){ 
                if(materials.length == 0) {
                    return;
                }

                var invoiceListRendered = [{'': null, 품명: null, 부재: null, 규격: null, 단위: null, 수량: null, 단가1: '단가', 금액1: '금액', 단가2: '단가', 금액2: '금액', 단가3: '단가', 금액3: '금액', 단가4: '단가', 금액4: '금액', 비고: null}];
                var curPrcNo = 0;
                var subTotalCost = 0;
                var bimNm = '';
                this.unitStandardList.forEach((us, i) => {  // BIM 자재별 수량산출방식 및 필요 정보 리스트를 순회하며 해당 자재가 입력받은 일람표(Excel 데이터)에 존재할 경우, 해당 자재의 면적값에 수량산출식을 적용하여 필요수량을 산출한다.
                    if(curPrcNo != us.prcNo) {
                        if(curPrcNo > 0) {
                            invoiceListRendered.push({'': '', 품명: '[합    계]', 부재: '', 규격: '', 단위: '', 수량: '', 단가1: '', 금액1: subTotalCost, 단가2: '', 금액2: '', 단가3: '', 금액3: '', 단가4: '', 금액4: subTotalCost, 비고: ''});    
                            invoiceListRendered.push('');
                            subTotalCost = 0;
                        }
                        curPrcNo = us.prcNo;
                        invoiceListRendered.push({'': Number(us.prcNo), 품명: us.prcNm});
                    }
                    materials.forEach((mat) => {
                        if(us.bimObj.indexOf(mat.bimObj) >= 0 && us.bimNm == mat.bimNm) {
                            const amount = Math.ceil(this.getAmountWithFormular(mat.area, us.formular));    // 자재면적과 수량산출식을 입력받아 자재별 필요수량을 계산하는 함수 호출
                            invoiceListRendered.push({'': null, 품명: bimNm == us.bimNm? '' : mat.bimNm.split('KBIMS-')[1], 부재: this.resolveBimObjNm(mat.bimObj), 규격: us.standard, 단위: us.unit, 수량: amount, 단가1: Number(us.cost), 금액1: amount * Number(us.cost), 단가2: null, 금액2: null, 단가3: null, 금액3: null, 단가4: null, 금액4: amount * Number(us.cost), 비고: null});
                            subTotalCost += amount * Number(us.cost);
                            bimNm = us.bimNm;
                        }
                    })
                    if(i+1 == this.unitStandardList.length) {
                        invoiceListRendered.push({'': '', 품명: '[합    계]', 부재: '', 규격: '', 단위: '', 수량: '', 단가1: '', 금액1: subTotalCost, 단가2: '', 금액2: '', 단가3: '', 금액3: '', 단가4: '', 금액4: subTotalCost, 비고: ''});    
                    }
                })

                //workbook 생성
                var wb = window.XLSX.utils.book_new();
                
                //시트 만들기 
                var newWorksheet = window.XLSX.utils.json_to_sheet(invoiceListRendered);

                
                
                //Header 일부 Cell 병합
                newWorksheet['!merges'] = [
                    { s: { c: 0, r: 0 }, e: { c: 0, r: 1 } }, //A1 ~ A2
                    { s: { c: 1, r: 0 }, e: { c: 1, r: 1 } }, //품명
                    { s: { c: 2, r: 0 }, e: { c: 2, r: 1 } }, //부재
                    { s: { c: 3, r: 0 }, e: { c: 3, r: 1 } }, //규격
                    { s: { c: 4, r: 0 }, e: { c: 4, r: 1 } }, //단위
                    { s: { c: 5, r: 0 }, e: { c: 5, r: 1 } }, //수량
                    { s: { c: 6, r: 0 }, e: { c: 7, r: 0 } }, //재료비
                    { s: { c: 8, r: 0 }, e: { c: 9, r: 0 } }, //노무비
                    { s: { c: 10, r: 0 }, e: { c: 11, r: 0 } }, //경비
                    { s: { c: 12, r: 0 }, e: { c: 13, r: 0 } }, //합계
                    { s: { c: 14, r: 0 }, e: { c: 14, r: 1 } }  //비고
                ];
                
                window.XLSX.utils.sheet_add_aoa(newWorksheet, [[,,,,,,'재료비',,'노무비',,'경비',,'합계',,]], {origin: 0});
                
                //Header영역 스타일 지정
                Object.entries(invoiceListRendered[0]).forEach((o, i) => {
                    var styleHeader = {
                        font: {
                            sz: 12,
                            bold: true
                        },
                        alignment: {
                            vertical: "center",
                            horizontal: "center"
                        },
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "defbff" }
                        },
                        border: {
                            left: {style: "thin", color: {rgb: "b1b1b1"}},
                            right: {style: "thin", color: {rgb: "b1b1b1"}},
                        }
                    }
                    const cell0 = newWorksheet[window.XLSX.utils.encode_cell({r: 0, c: i})];
                    const cell1 = newWorksheet[window.XLSX.utils.encode_cell({r: 1, c: i})];
                    cell0.s = styleHeader;
                    cell1.s = styleHeader;
                    if(i >= 6 && i <= 13) {
                        cell0.s.border.bottom = {style: "thin", color: {rgb: "b1b1b1"}};
                        cell1.s.border.bottom = {style: "thin", color: {rgb: "b1b1b1"}};
                    }
                })

                //데이터 서식 지정
                invoiceListRendered.forEach((v,i) => {
                    if(i >0) {
                        for(var j = 0; j <= 14; j++) {
                            if(newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})]){
                                newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})].s = {alignment: {vertical: "center"}};
                            }
                            if([2,3,4].indexOf(j) >=0) {
                                if(newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})]){
                                    newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})].s.alignment.horizontal = "center";
                                }
                            }
                            if(v.품명 == '[합    계]') {
                                newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})].s.fill = {patternType: "solid", fgColor: { rgb: "e1e1e1" }};
                                newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})].s.border = {
                                    top: {style: "thin", color: {rgb: "b1b1b1"}},
                                    left: {style: "thin", color: {rgb: "b1b1b1"}},
                                    right: {style: "thin", color: {rgb: "b1b1b1"}},
                                    bottom: {style: "thin", color: {rgb: "b1b1b1"}}
                                }
                            }
                            if(v[''] && [0,1].indexOf(j) >=0) {
                                newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})].s.font = {bold: true};
                            }
                        }
                        for(j = 6; j <= 13; j++) {
                            if(newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})])
                                newWorksheet[window.XLSX.utils.encode_cell({c: j, r: i+1})].s.numFmt = "#,##0";
                        }
                    }
                });

                //열간격 설정
                var maxLengthForCols = [];
                Object.entries(invoiceListRendered[0]).forEach(([k, v], i) => {
                    maxLengthForCols.push({wch: k? k.length : 0});
                });
                
                for(var j=0; j < invoiceListRendered.length; j++) {
                    Object.entries(invoiceListRendered[j]).forEach(([k, v], i) => {
                    if(v) {
                        maxLengthForCols[i].wch = String(v).length > maxLengthForCols[i].wch? String(v).length : maxLengthForCols[i].wch;
                    }
                    });
                }
                maxLengthForCols.forEach((v) => {
                    if(typeof v.wch !== Number) {
                        v.wch *=1.8;
                    }
                });
                newWorksheet["!cols"] = maxLengthForCols;


                
                // workbook에 새로만든 워크시트에 이름을 주고 붙인다.  
                window.XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1');

                // 내역서 엑셀 파일 만들기 
                var wbout = window.XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

                // 내역서 엑셀 파일 내보내기 
                window.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'Invoice_' + new Date().toISOString() + '.xlsx');
            },

            s2ab(s) { 
                var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
                var view = new Uint8Array(buf);  //create uint8array as viewer
                for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
                return buf;    
            },

            resolveBimObjNm(bimObj) {
                switch(bimObj) {
                    case 'Ceiling': return '천장';
                        break;
                    case 'Floor': return '바닥';
                        break;
                    case 'Wall': return '벽';
                        break;
                }
            },

            getAmountWithFormular(area, formular) {
                var rtn = Number(area);
                const tokens = formular.split('/');
                for(var i=1; i < tokens.length; i++) {
                    rtn /= Number(tokens[i]);
                }
                return rtn;
            }
        }
    }
</script>