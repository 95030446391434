<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">계약서 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="py-4">

            <div class="x_div_end d-flex">
              <select class="custom-select ff-eng1 fs-085" v-model="searchType" style="width:200px; height:37px; margin-right: 20px">
                <option value="EM">건축주명 또는 이메일 검색</option>
                <option value="PJ">프로젝트명 검색</option>
              </select>
              <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" @keydown.enter="findPrjList()" v-model="searchKeyword"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem" placeholder="프로젝트 A" ></div>
                <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="findPrjList()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              </div>
            </div>
            <div class="x_div_end">
              <div id="auto_frm" v-show="isSearch" class="p-2" style="display:none; position:absolute; top:235px; width:245px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center ls_bg1">
                  <div class="w-100 text-start py-1 px-2">
                    <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.prjId" class="fa fa-spinner fa-spin" style="color:#333"></i>
                    <a v-else href="javascript:void(0)" @click="callFindPrjWrttnCntrct(item.prjId)">{{item.prjNm.split(';')[0]}} <br>&nbsp;&nbsp;&nbsp;&nbsp; - {{ item.crrntStts }}</a>
                  </div>
                  <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-3">

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>사업 파트너</strong></span></td>
                  <td v-for="(prc, i) in prcCdList" :key="i" class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{ renderPrcType(prc) }}사</strong></span></td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{prjNm ? prjNm.split(';')[0] : ''}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{ownrNm}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{bsnssPrtnrNm}}</span></td>
                  <td v-for="(prc, i) in prcCdList" :key="i" class="text-center br_0" ><span class="ff-kor2" :style="'font-size:12pt;' + (prtnrNm[i]? '' : 'color: lightgrey')">{{prtnrNm[i]? prtnrNm[i] : findWrttnDto[i].partnerName}}</span></td>
                </tr>
              </table>
            </div>
          </div>
          <div style="height:50px"></div>

          <div v-for="(prc, i) in prcCdList" :key="i">
            <div class="d-flex" style="border-bottom:1px solid #d9d9d9">
              <div class="px-2 py-2"><span class="fa-Kor-Noto" style="font-weight:700; font-size:16pt; color:#333333">{{ renderPrcType(prc) }}</span></div>
            </div>
  
            <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
  
            <div class="pt-3" v-else>
  
              <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                <table class="table-borderless w-100">
  
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                    <td class="text-center br_0" width="25%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약일</strong></span></td>
                    <td class="text-center" width="75%">
                      <div class="x_div_center">
                        <span v-if="cntrctDt[i]" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ cntrctDt[i].split('T')[0] }}</span>
                      </div>
                    </td>
                  </tr>
  
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                    <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약금</strong></span></td>
                    <td class="text-center">
                      <div class="x_div_center">
                        <span v-if="fnlPath[i]" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ Number(totalAmounts[i]).toLocaleString() }} 원</span>
                        <input v-else type="text" :disabled="!(findWrttnDto[i].partnerName || prtnrNm[i])" v-model="totalAmountsInput[i]" @keyup="totalAmountsInput[i] = renderDigitsPunkt(totalAmountsInput[i]); $forceUpdate()" class="form-control ff-eng1 text-center" style="width:180px; height:36px">
                      </div>
                    </td>
                  </tr>
  
                  <tr style="height:55px;">
                    <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약서</strong></span></td>
                    <td class="text-center">
                      <div v-if="fnlPath[i]" class="x_div_center">
                        <form method="post" action="/api/v1/common/filedownload">
                          <input type="hidden" name="fileName" :value="fnlPath[i]" />
                          <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ fnlOrgNm[i] }} </span></a>
                        </form>
                      </div>
                      <div v-else class="x_div_center"><input type="file" :disabled="!(findWrttnDto[i].partnerName || prtnrNm[i])" @change="fileHandling($event, i)" class="form-control ff-eng1 text-center" style="width:240px; height:36px"></div>
                    </td>
                  </tr>
  
                </table>
              </div>
  
            </div>
  
            <div style="height:20px"></div>
          </div>

          <div class="x_div_end">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveWrttnCntrct()" style="width:150px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from "@/api";

export default {
  name: "WrttnCntrctMng",
  components:{
    AdminLNB,
  },
  data(){
    return {
      isSearch:false,
      prjList:[],
      prcCdList: ['DS','MA','CN','IN','DE'],
      prtnrNm:['','','','',''],
      searchKeyword : '', // 검색 키워드
      searchType:'PJ',
      prjId:0,
      prjNm:'',
      ownrNm:'',
      bsnssPrtnrNm:'',
      totalAmounts:[],
      totalAmountsInput:[],
      cntrctDt:[],
      cntrctDt:[],
      fnlPath:[],
      fnlOrgNm:[],
      findWrttnDto:[],
      fnlAttchmnt:[],
      fetching: true,
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  created() {
    this.prcCdList.forEach(() => {
      this.findWrttnDto.push({ fnlPath : '', fnlOrgNm : '', cntrctDt : null, totalAmounts : null, partnerName: null, partnerId: null, prcId: null, prcCd: null, bddngRqst: '' });
    })
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();
    this.fetching = false;
    var searchedPrjId = sessionStorage.getItem('searchedPrjId');
    if(searchedPrjId) {
      sessionStorage.removeItem('searchedPrjId');
      await this.findPrjWrttnCntrct(searchedPrjId);
    }
  },

  methods:{

    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    fileHandling(e, index) {
        this.fnlAttchmnt[index] = e.target.files[0];
        this.fnlOrgNm[index] = e.target.files[0].name;
    },

    async findPrjList(){

      if(!this.searchKeyword){
        alert('프로젝트 검색어를 입력해주세요.');
        return;
      }

      this.isSearch = true;
      this.prjList = [{prjId:null}];
      const rtnval = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: false})
      if(rtnval.result) {
          this.prjList = rtnval.data.findPrjDtoList.sort((a, b) => {
                  return Number(Boolean(b.lastUpdatedPrcInfo.totalAmounts)) - Number(Boolean(a.lastUpdatedPrcInfo.totalAmounts))
                });
          const rtnvalue = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: true})
          if(rtnvalue.result) {
            this.prjList = this.prjList.concat(rtnvalue.data.findPrjDtoList);
          }
      }
    },

    async findPrjWrttnCntrct(prjId){
      this.prjId = prjId;
      this.fetching = true;
      let returnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + prjId);
      if(returnvalue.result) {
        this.prjNm = returnvalue.data.prj.prjNm;
        this.bsnssPrtnrNm = returnvalue.data.prj.bsnssPrtnrEmail;
      }
      else {
        alert(returnvalue.errormsg)
      }

      let rtnval = await Api.get('/api/v1/admin/wrttn/cntrct/mng/find/wrttn/cntrct/list?prjId=' + prjId);
      if(rtnval.result) {
        rtnval.data.findWrttnList.forEach(async (item) => {
          let rtnvalue = await Api.post('/api/v1/process/get', {prjId: prjId, prcCd: item.prcCd});
          if(rtnvalue.result) {
            this.ownrNm = rtnvalue.data.ownrInfo.fullName;
          }
          const index = this.prcCdList.indexOf(item.prcCd);
          this.findWrttnDto[index] = item;
          if(this.findWrttnDto[index].cntrctDt){
            this.cntrctDt[index] = this.findWrttnDto[index].cntrctDt.substr(0, 10);
          }else{
            this.cntrctDt[index] = '';
          }
          if(this.findWrttnDto[index].totalAmounts){
            this.totalAmounts[index] = (this.findWrttnDto[index].totalAmounts);
            this.totalAmountsInput[index] = String(this.totalAmounts[index]);
          }else{
            this.totalAmounts[index] = 0;
          }
          this.fnlPath[index] = this.findWrttnDto[index].fnlPath;
          this.fnlOrgNm[index] = this.findWrttnDto[index].fnlOrgNm;
          this.prtnrNm[index] = rtnvalue.data.prtnrInfo? rtnvalue.data.prtnrInfo.fullName : '';
          this.$forceUpdate();
        })
      }
      else {
        alert(rtnval.errormsg)
      }
      this.fetching = false;
      this.$forceUpdate();

      this.isSearch = false;
    },

    callFindPrjWrttnCntrct(prjId) {
      sessionStorage.setItem('searchedPrjId', prjId);
      location.reload()
    },

    renderDigitsPunkt(digitsStr) {
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if(reg.exec(digitsStr)!==null) {
        digitsStr = digitsStr.replace(/[^0-9]/g,'');
      }

      // .... 만 입력하게 될 경우 체크
      if(isNaN(parseFloat(digitsStr))) {
        return '';
      }

      return digitsStr.replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    renderPrcType(processType) { 
      var rsp = '';
      switch(processType) {
        case 'DS' : rsp = '설계';
          break;
        case 'MA' : rsp = '제조';
          break;
        case 'CN' : rsp = '시공';
            break;
        case 'IN' : rsp = '인테리어';
          break;
        case 'DE' : rsp = '운송';
          break;
      }
      return rsp;
    },

    async saveWrttnCntrct(){

      var setPrcRqst = [];
      
      var request = {
        parentId: [],
        dirNmList: [],
        attchOrgNmList: [],
        attchFlCtgry: []
      }

      const formData = new FormData();

      this.prcCdList.forEach((prc, i) => {
        if(this.fnlAttchmnt[i]) {
          request.parentId.push(this.findWrttnDto[i].prcId)
          request.dirNmList.push('platform/contract/' + this.findWrttnDto[i].prcCd + '/fnl')
          request.attchOrgNmList.push(this.fnlOrgNm[i])
          request.attchFlCtgry.push('CT')
  
          formData.append("attchmntList", this.fnlAttchmnt[i]);
  
          setPrcRqst.push({
            prcId: this.findWrttnDto[i].prcId,
            bddngRqst: this.findWrttnDto[i].bddngRqst,
            prtnrId: this.findWrttnDto[i].partnerId,
            totalAmounts: Number(this.totalAmountsInput[i].replace(/[^\d]+/g, '')),
            startBid: false,
            bddngTyp: 0
          })
        }
      });

      if(request.parentId.length == 0) {
        alert('저장할 첨부 파일이 없습니다.');
        return;
      }

      formData.append(
                "request",
                new Blob([JSON.stringify(request)], { type: "application/json" })
            );

			var rtnval = null;
			rtnval = await Api.post('/api/v1/common/set/attachments', formData);
			if(rtnval.result) {
        setPrcRqst.forEach(async (rqst) => {
          var rtnvalue = await Api.post('/api/v1/process/set', rqst);
          if(!rtnvalue.result) {
            alert(rtnvalue.errormsg)
          }
        })
        this.findPrjWrttnCntrct(this.prjId);
			}
			else {
				alert(rtnval.errormsg);
			}
    }
  }
}
</script>

<style scoped>

</style>