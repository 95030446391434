<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">전체 프로젝트 비용 지급 과정 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:220px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:14px"></div>

          <div class="w-100 x_div_center" style="border-bottom:1px solid #c9c9c9">
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 0 ? ' active' : '')"><a href="#" @click="onClickTap(0)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 0 ? ' fc-w1' : '')">설계</span></a></div>
            </div>
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 1 ? ' active' : '')"><a href="#" @click="onClickTap(1)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 1 ? ' fc-w1' : '')">제조</span></a></div>
            </div>
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 2 ? ' active' : '')"><a href="#" @click="onClickTap(2)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 2 ? ' fc-w1' : '')">시공</span></a></div>
            </div>
            <div style="width:25%">
              <div :class="'x_div_center tap' + (currentTap == 3 ? ' active' : '')"><a href="#" @click="onClickTap(3)"><span :class="'fa-kor-Noto fs-10 fw-bold' + (currentTap == 3 ? ' fc-w1' : '')">인테리어</span></a></div>
            </div>
          </div>

          <div class="w-100 x_div_end py-4"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>
            <div>
              <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                <table class="table-borderless w-100">
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td colspan="4" class="text-center" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>입금 요청 대기중인 프로젝트</strong></span></td>
                  </tr>
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주 이름</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주 이메일</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주 연락처</strong></span></td>
                  </tr>

                  <tr v-for="(item, index) in question1List" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td class="text-center br_0" ><a href="javascript:void(0)" @click="moveToPrj(item.prjId);"><span class="ff-kor1" style="font-size:12pt"><strong>{{item.prjNm.split(';')[0]}}</strong></span></a></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{item.fullName}}</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{item.email}}</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{renderPhoneNumber(item.phone)}}</strong></span></td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="w-100 x_div_end py-4"></div>

            <div>
              <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                <table class="table-borderless w-100">
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td colspan="5" class="text-center" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>입금 요청 후 미입금 프로젝트(영수증 미업로드)</strong></span></td>
                  </tr>
                  <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주 이름</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주 이메일</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주 연락처</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>지급 요청 후 경과일 수</strong></span></td>
                  </tr>

                  <tr v-for="(item, index) in question2List" :key="index" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <td class="text-center br_0" ><a href="javascript:void(0)" @click="moveToPrj(item.prjId);"><span class="ff-kor1" style="font-size:12pt"><strong>{{item.prjNm.split(';')[0]}}</strong></span></a></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{item.fullName}}</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{item.email}}</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{renderPhoneNumber(item.phone)}}</strong></span></td>
                    <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>{{item.elpsdDay}}</strong></span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div style="height:50px"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
//전체 프로젝트 비용 지급 과정 관리
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from "@/api/auth";
import Api from '@/api'

const process = ['DS', 'MA', 'CN', 'IN'];

var rtnval = null;

export default {
  name: "PrjPymntPrcssMng",
  components:{
    AdminLNB
  },
  data(){
    return {
      currentTap: 0,
      question1List:[],
      question2List:[],
      question3List:[],
      question4List:[],
      question5List:[],
      fetching: true
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();
    await this.onClickTap(0);
  },

  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async onClickTap(processCode){
      this.fetching = true;
      this.currentTap = processCode;
      //입금 요청 대기중인 프로젝트
      rtnval = await Api.get('/api/v1/admin/prj/pymnt/sttstc/find/pending/deposit/request?prcType=' + process[this.currentTap]);
      if(rtnval.result){
        this.question1List = rtnval.data;
      }

      //입금 요청 후 미입금 프로젝트
      rtnval = await Api.get('/api/v1/admin/prj/pymnt/sttstc/find/pending/deposit?prcType=' + process[this.currentTap]);
      if(rtnval.result){
        this.question2List = rtnval.data;
        this.question2List.forEach((val) => {
          var dateNow = new Date();
          var deadLine = new Date(val.deadLine);
          val.elpsdDay = Math.floor((dateNow - deadLine) / (1000*60*60*24));
        })
      }
      this.fetching = false;
    },

    async moveToPrj(prjId) {
        sessionStorage.setItem('prjId', prjId);
        var rtnval = await Api.get('/api/v1/process/list/prc/tree');
        if(rtnval.result) {
          sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
        }
        else {
          alert(rtnval.errormsg)
        }
    
        rtnval = await Api.get('/api/v1/user/list/authorized/prc?usrRole=OW');
        if(rtnval.result) {
          var accessiblePrc = [];
          rtnval.data.processes.forEach((prc) => {
            accessiblePrc.push(prc.code);
          })
          sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
        }
        else {
          alert(rtnval.errormsg)
        }
        window.open('about:blank').location.href='/project/summary/' + prjId
    },

    renderPhoneNumber(phone) {
      if(phone.length == 10) {
          return phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
      }
      else{
          return phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
      }
    }
  }
}
</script>

<style scoped>
.x_div_center.tap {
  height:50px;
  background-color:#fff;
}
.x_div_center.tap.active {
  height:50px;
  background-color:#4e0000;
  border-top-left-radius:8px;
  border-top-right-radius:8px
}
</style>