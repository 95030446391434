<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">실시간 견적산출</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>견적내역 관리</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">견적내역 수: {{ estimationList.length }} 건</span>
            <!-- <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select> -->
            <div class="ps-2" style="margin-left: auto; padding-left: 650px !important"><button class="fkr-set2 fs-095" onclick="location.href = '/admin/estmt/dtl/0'" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">견적내역 초안작성</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findEstimation()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findEstimation()" style="border:0; width:170px; height:30px; padding:0 .3rem"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">고객명</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">제목</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">견적</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">골조비</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">견적일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in estimationListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.customerNm}}</span></div>
                  <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9">{{item.sbjct}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.estmtPrice? item.estmtPrice.toLocaleString() : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.framePrice.toLocaleString()}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.estmtDt? item.estmtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a :href="'/admin/estmt/dtl/' + item.estmtId"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "EstimationList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        searchWord: '',
        estimationList:[],
        estimationListOrig:[],
        estimationListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    await this.fetchEstimations(null);
    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'N'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchEstimations(searchWord) {
      this.fetching = true;
      if(!searchWord) {
        this.estimationList = [];
        const returnvalue = await Api.get('/api/v1/estmt/list/estmt');
        // const returnvalue = {result: true, data: [
        //     {
        //       customerNm: '정재복',
        //       sbjct: '양평 단독주택',
        //       estmtPrice: 0,
        //       framePrice: 0,
        //       estmtDt: null
        //     },
        //     {
        //       customerNm: '고윤희',
        //       sbjct: '창원 예비프로젝트',
        //       estmtPrice: 200000000,
        //       framePrice: 120000000,
        //       estmtDt: '2023-02-17T22:22:22'
        //     }
        //   ]}
        ////////////////////////////////////////////////////////////////////////////////////////////////////////
        if(returnvalue.result) {
            this.estimationList = returnvalue.data.findEstmtDtoList.sort((a,b) => b.estmtId - a.estmtId);
            this.estimationListOrig = this.estimationList;
        }
        else {
            alert(returnvalue.errormsg);
        }
      }
      else {
        this.estimationList = this.estimationListOrig.filter((estmt) => (estmt.customerNm.indexOf(keyword) >= 0 || estmt.sbjct.indexOf(keyword) >= 0) );
      }
      this.fetching = false;
    },

    async findEstimation() {
      await this.fetchEstimations(null, this.searchWord);
      this.setPaging();
      this.renderList();
    },

    renderList() {
      this.estimationListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.estimationList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.estimationList.length); j++) {
        this.estimationListPaged.push(this.estimationList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.estimationList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    }
  }
}
</script>

