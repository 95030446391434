<template>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 관리</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:14px"></div>

                <div class="sw-1">
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-generate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-generate'"><span class="fkr-set1 fs-095">1. 참여 의향 요청</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-candidate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-candidate'"><span class="fkr-set1 fs-095">2. 후보 업체 선정</span></div>
                        </div>
                    </div>
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-evaluate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-evaluate'"><span class="fkr-set1 fs-095">3. 후보 업체 평가 및 선정</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/contract') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/contract'"><span class="fkr-set1 fs-095">4. 계약</span></div>
                        </div>
                    </div>
				</div>

				<div style="height:20px"></div>

                <div class="x_div_center py-5">
                    <div class="pg_m_set" v-if="fetching">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div class="text-center" v-else>
                        <span class="fkr-set2 fs-10" v-if="isRequestDuedateOver">
                            다음과 같은 파트너기관들이 참여 의향을 주셨습니다. <br v-if="isMobile"/>최대 3곳을 선정하셔서 제안 제출을 요청하세요.<br v-if="isMobile"/>
                            <br><br>
                            <span class="fw-bold" style="color:#4e0000">{{this.sbmsnExprDtLocal}}</span>
                            까지 후보업체 선정을 <br v-if="isMobile"/>완료해주세요.
                        </span>
                        <span class="fkr-set2 fs-10" v-else>
                            제안 참여 의향 등록기간입니다.<br/><br/>

                            <span class="fw-bold" style="color:#4e0000">{{this.rqstDdlnLocal}}</span> 이후 의향을 표현한 파트너분들을 확인하실 수 있습니다.
                        </span>
                    </div>
                </div>

                <div v-if="!fetching && isRequestDuedateOver" class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                    <table class="table-borderless w-100">
                        <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                            <td class="text-center br_0" width="15%"><span class="ff-kor1" style="font-size:12pt"><strong>회사명</strong></span></td>
                            <td class="text-center br_0" width="23%"><span class="ff-kor1" style="font-size:12pt"><strong>주소</strong></span></td>
                            <td class="text-center br_0" width="17%"><span class="ff-kor1" style="font-size:12pt"><strong>홈페이지</strong></span></td>
                            <td class="text-center br_0" width="15%"><span class="ff-kor1" style="font-size:12pt"><strong>지원내용</strong></span></td>
                            <td class="text-center" width="10%"><span class="ff-kor1" style="font-size:12pt"><strong>{{isMobile? '선정' : '후보 업체로 선정'}}</strong></span></td>
                        </tr>

                        <tr v-for="(part, i) in participates" :key="i" style="height:55px; border-bottom:1px solid #c9c9c9">
                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{part.candidateName}}</span></td>
                            <td class="text-center br_0"><span class="ff-kor1" style="font-size:11pt">{{part.candidateAddr}}</span></td>
                            <td class="text-center br_0">
                                <a :href="part.candidateHmpg" target="_blank"><i class="fas fa-home" v-if="isMobile && part.candidateHmpg"/><span v-else class="ff-kor1" style="font-size:11pt">{{part.candidateHmpg}}</span></a>
                            </td>
                            <td class="text-center br_0"><div class="x_div_center"><button @click="showDetail(part.bddngId, part.ceoMessage)" class="x_div_center" :style="'height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;' + (isMobile? ' width:50px' : 'width:90px')"><span class="fkr-set2">보기</span></button></div></td>
                            <td class="text-center"><div class="x_div_center">
                                <div class="custom-control custom-checkbox">
                                    <input name="checkCandidate" type="checkbox" :id="'checkbox'+i" :value="part.bddngId" v-model="candidateConfirmed" :disabled="!editing" class="custom-control-input" @change="selectCandidate(part.bddngId, i)">
                                    <label :for="'checkbox'+i" class="custom-control-label"></label>
                                </div>
                            </div></td>
                        </tr>
                    </table>
                </div>

                <div style="height:20px"></div>

                <div class="x_div_end">
                    <div class="py-4" v-if="!fetching && isRequestDuedateOver">
                        <button v-if="editing" @click="confirmCandidate()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">선정 완료<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button>
                        <button v-else-if="!isSelectionDuedateOver" class="x_div_center" @click="editing = true" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fa-kor-Noto fs-085">재선정</span></button>
                    </div>
                    &nbsp;
                    <div class="py-4" v-show="!fetching && prcCd == 'DS'">
                        <button class="x_div_center" :disabled="!rcptOrgNm" :style="'width:90px; height:30px; border-radius:4px; ' + (isRequestDuedateOver? 'background-color:#fff; border:1px solid #4e0000' : 'border:0; background-color:#4e0000') + (rcptOrgNm? '' : '; opacity: 0.5')"><a id="rcptDownload"><span class="fa-kor-Noto fs-085" :style="'color:' + (isRequestDuedateOver? '#4e0000' : '#fff')">영수증 조회</span></a></button>
                    </div>
                </div>

                <div style="height:50px"></div>

                <!-- 참여의향 조회 레이어 시작 { -->
                <div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">

                            <div class="w-100">
                                <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">지원 내용</span></div>
                                    <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                                </div>

                                <div class="py-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">대표자 인사</span></div>

                                <div class="x_div_center px-4">
                                    <div class="w-100 py-3" style="border-top:1px solid #c9c9c9">
                                        <div class="py-2 px-2" v-html="ceoMessage"></div>
                                    </div>
                                </div>

                                <div class="x_div_center py-2">
                                    <div class="x_div_center px-4 w-100">

                                    <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                        <table class="table-borderless w-100">
                                        <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                            <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>포트폴리오</strong></span></td>
                                            <td class="text-center" width="50%"><span class="fkr-set1"><strong>기타홍보자료</strong></span></td>
                                        </tr>
                                        <tr style="height:55px;">
                                            <td class="text-center br_0">
                                                <div class="x_div_center"><a :href="pfFileLink" :download="pfOrgNm"><span class="fkr-set2">{{pfOrgNm}}</span></a></div>
                                            </td>
                                            <td class="text-center">
                                                <div class="x_div_center"><a :href="prFileLink" :download="prOrgNm"><span class="fkr-set2">{{prOrgNm}}</span></a></div>
                                            </td>
                                        </tr>
                                        </table>
                                    </div>

                                    </div>
                                </div>

                                <div class="x_div_center py-4">
                                    <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                                </div>

                                <div style="height:20px"></div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- } 참여의향 조회 레이어 끝 -->

            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'BiddingCustomerCandidate',
    data() {
        return {
            url : '',
            fetching : true,
            submitting: false,
            isMobile : false,
            prjId : sessionStorage.getItem('prjId'),
            prcCd : sessionStorage.getItem('prcCd'),
            prcId : sessionStorage.getItem('prcId'),
            rqstDdln : null,
            sbmsnExprDt : null,
            sggstDdln : null,
            editing : true,
            // prcssInfo : [
            //         {name: 'dsgn', id: sessionStorage.getItem('dsgnId')},
            //         {name: 'mnfct', id: sessionStorage.getItem('mnfctId')},
            //         {name: 'cntrct', id: sessionStorage.getItem('cntrctId')},
            //         {name: 'intrr', id: sessionStorage.getItem('intrrId')}
            //     ],
            isRequestDuedateOver : false,
            isSelectionDuedateOver : false,
            rqstDdlnLocal : '',
            sbmsnExprDtLocal : '',
            participates: [],
            candidateConfirmed: [],
            candidateConfirmedFromServer: [], 
            ceoMessage: '',
            pfOrgNm: '',
            pfFileLink: '',
            prOrgNm: '',
            prFileLink: '',
            rcptOrgNm: '',
            rcptFileLink: '',
            floorArea: 0,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
            buildMode: process.env.NODE_ENV
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
            if(this.$route.params.prjId) {
                this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent('/project/bidding/customer/bidding-candidate/' + this.$route.params.prjId) } });
            }
            else {
                location.href = '/member/login'
            }
        }
        else if(this.user.info.usrRole != 'OW') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        else if(!this.prjId || this.prjId == 'null') {
            if(this.$route.params.prjId) {
                var returnValue = await Api.post('/api/v1/common/get/map/talk', {usrId: this.user.id, url: '/project/bidding/customer/bidding-candidate/' + this.$route.params.prjId});
                if(returnValue.result && returnValue.data.id) {
                    this.prjId = returnValue.data.prjId;
                    this.prcCd = returnValue.data.prcCd;
                    this.prcId = returnValue.data.prcId;
                    sessionStorage.setItem('prjId', this.prjId);
                    sessionStorage.setItem('prcCd', this.prcCd);
                    sessionStorage.setItem('prcId', this.prcId);
                }
                else {
                    alert('잘못된 접근입니다.');
                    location.href = '/project/dashboard'    
                }
            }
            else {
                alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
                location.href = '/project/dashboard'
            }
        }
        else if(this.prcCd == 'null') {
            alert('프로젝트 단계를 선택하셔야 합니다.');
            location.href = '/project/summary/' + this.prjId;
        }
        this.isMobile = window.innerWidth < 768;
        this.fetchCandidateInfo();
    },
    methods: {
        async fetchCandidateInfo() {
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.prcCd});
            this.fetching = false;
            if(rtnvalue.result) {
                this.sggstDdln =  rtnvalue.data.bddngProposalDdln;
                this.rqstDdln =  rtnvalue.data.bddngJoinDdln;
                this.sbmsnExprDt = rtnvalue.data.bddngNominateDdln;

                if(!this.rqstDdln) {
                    alert('참여 의향 요청 단계를 먼저 완료해주세요.');
                    location.href = '/project/bidding/customer/bidding-generate';
                }
                else {
                    var rqstDdln = new Date(this.rqstDdln);
                    var sbmsnExprDt = new Date(this.sbmsnExprDt);
                    var curruntDate = new Date();
                    if(rqstDdln <= curruntDate) {
                        this.isRequestDuedateOver = true;
                    }
                    if(sbmsnExprDt <= curruntDate) {
                        this.isSelectionDuedateOver = true;
                    }
                    this.rqstDdlnLocal = rqstDdln.toLocaleString();
                    this.sbmsnExprDtLocal = sbmsnExprDt.toLocaleString();
                    this.participates = [];
                    this.candidateConfirmed = [];
                    this.participates = rtnvalue.data.bddngs? rtnvalue.data.bddngs.sort((a,b) => { return a.bddngId - b.bddngId }) : null;
                    if(this.participates) {
                        this.participates.forEach((item) => {
                            if(item.nominatedDt) {
                                this.candidateConfirmed.push(item.bddngId);
                                this.candidateConfirmedFromServer.push(item.bddngId);
                                this.editing = false;
                            }
                        })
                    }
                }

                if(rtnvalue.data.attachFiles && this.prcCd == 'DS') {
                    rtnvalue.data.attachFiles.forEach((file) => {
                        if(file.prcAttchFlCtgry.code == 'RT') {
                            this.rcptOrgNm = file.attchNm;
                            var tokens = this.rcptOrgNm.split('.');
                            var mimeType = tokens[tokens.length-1].toLowerCase();
                            var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;

                            var byteCharacters = atob(file.attchData);
                            var byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            var byteArray = new Uint8Array(byteNumbers);
                            var blob = new Blob([byteArray], {type: fileType});
                            this.rcptFileLink = URL.createObjectURL(blob);

                            window.$('#rcptDownload').prop('href', this.rcptFileLink);
                            window.$('#rcptDownload').prop('download', this.rcptOrgNm);
                        }
                    })
                }
            }
            else {
                alert(rtnvalue.errormsg)
            }
        },
        async showDetail(bddngId, ceoMessage) {
            this.ceoMessage = ceoMessage;
            this.pfOrgNm = null;
            this.pfFileLink = null;
            this.prOrgNm = null;
            this.prFileLink = null;
            const rtnval = await Api.post('/api/v1/common/get/attachments', {
                parentId: bddngId,
                parentUnit: 'BDDNG',
                ascByCrtDt: false,
                limit: -1
            });
            if(rtnval.result) {
                if(rtnval.data.attchments) {
                    rtnval.data.attchments.forEach((file) => {
                        if(file.attchFlCtgry.code == 'PF' || file.attchFlCtgry.code == 'PR') {
                            var tokens = file.attchOrgNm.split('.');
                            var mimeType = tokens[tokens.length-1].toLowerCase();
                            var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
            
                            var byteCharacters = atob(file.attchImg);
                            var byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            var byteArray = new Uint8Array(byteNumbers);
                            var blob = new Blob([byteArray], {type: fileType});
                            if(file.attchFlCtgry.code == 'PF') {
                                this.pfOrgNm = file.attchOrgNm;
                                this.pfFileLink = URL.createObjectURL(blob);
                            }
                            else {
                                this.prOrgNm = file.attchOrgNm;
                                this.prFileLink = URL.createObjectURL(blob);
                            }
                        }
                    })
                }
                window.$('#modalForm').modal('show');
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        selectCandidate(bddngId, i) {
            if(this.candidateConfirmed.length == 4) {
                alert("후보업체는 최대 3곳까지 선정할 수 있습니다.");
                this.candidateConfirmed = this.candidateConfirmed.filter((val) => val != bddngId);
                window.$('#checkbox'+i).prop('checked', false); 
            }
        },
        async confirmCandidate() {
            if(this.candidateConfirmed.length == 0) {
                alert('후보업체를 1곳 이상 선택하세요.');
                return 1;
            }

            var requests = [];
            this.candidateConfirmedFromServer.forEach((val) => {
                requests.push({
                    bddngId: val, 
                    nominatedDt: null,
                    evalRank: 0
                })
            })
            
            this.submitting = true;
            if(requests.length > 0) {
                const rtnval = await Api.post('/api/v1/bidding/set/multi', requests);
                if(!rtnval.result) {
                    alert(rtnval.errormsg)
                }
            }

            requests = [];
            this.candidateConfirmed.forEach((val) => {
                requests.push({
                    bddngId: val,
                    nominatedDt: new Date().toISOString(),
                    evalRank: 0
                })
            })
            const rtnvalue = await Api.post('/api/v1/bidding/set/multi', requests);
            if(rtnvalue.result) {
                alert('후보업체가 선정되었습니다.');
                this.editing = false;
                this.fetchCandidateInfo();
            }
            else {
                alert(rtnvalue.errormsg)
            }
            this.submitting = false;
        },
        closeModal() {
			window.$('#modalForm').modal('hide');
		}
    }
}
</script>