<template>
    <div class="ca_menu" :style="'min-height:100%; padding-top:65px; border-right:1px solid #c9c9c9; width:' + (collapsed? '53px' : '100%')">

        <div class="accordion-item"> 

          <div v-for="(ctgry, index) in menuList" :key="index">
            <div :id="'heading' + index" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                    <div><div :class="'ms_icon1' + (ctgry.activated? '_on' : '')"></div></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">{{ ctgry.menuName }}</span></div>
                    <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                </div>
            </div>

            <div v-show="!collapsed" :id="'collapse' + index" :class="'sm_ms2 accordion-collapse collapse' + (ctgry.activated? ' show' : '')" :aria-labelledby="'heading' + index" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <div v-for="(page, kintex) in ctgry.pageListRendered" :key="kintex" :class="'side_menu_m w-100 x_div_center ms_bg1' + (page.activated? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" :href="page.routePath">{{ page.pageName }}</a></div></div>
                </div>
            </div>
          </div>          

        </div>

        <div class="x_div_end" style="padding:50px 8px 50px 0">
            <div @click="collapsed = !collapsed" :class="'x_div_end ar_btn' + (collapsed? '' : ' sec')"></div>
        </div>

    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'LeftMenu',
    data() {
        return {
            collapsed: false,
            menuList: [],
            buildMode: process.env.NODE_ENV
        }
    },
    props: {
      user: {
          type: Object,
          default: null
      }
    },
    async created() {
      const rtnval = await Api.get('/api/v1/adminmenus/ctgry/list');
      if(rtnval.result){
        const pagesAvailable = [];
        if(this.user.info.adminYn != 'S') {
          this.user.info.adminRole.menuPolicyList.forEach((policy) => {
            pagesAvailable.push(policy.menuPage.componentName);
          })
        }

        rtnval.data.forEach((ctgry) => {
            ctgry.activated = false;
            ctgry.pageListRendered = [];
            ctgry.pageList = ctgry.pageList.sort((a,b) => a.orderInMenu - b.orderInMenu);

            ctgry.pageList.forEach((page) => {
              if(this.user.info.adminYn == 'S' || pagesAvailable.indexOf(page.componentName) >= 0) {
                if(page.lnbVisiability) {
                  var pathStatic = page.routePath.indexOf(':') >=0 ? page.routePath.split('/:')[0] : page.routePath;
                  page.activated = location.href.indexOf(pathStatic) >= 0;
                  if(page.activated) {
                    ctgry.activated = true;
                  }
                  ctgry.pageListRendered.push(page);
                }
              }
            })
            if(ctgry.pageListRendered.length >0) {
              this.menuList.push(ctgry)
            }
        })
      }
      else {
        alert(rtnval.errormsg)
      }
    }
}
</script>