<template>
    <div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">{{ attchType }} 등록</span></div>
                        <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                    </div>

                    <div class="py-3 ps-4"><span class="fkr-set2 fs-11 fw-bold">{{ step == 1? '대표자 인사' : '' }}</span></div>

                    <div class="x_div_center px-4">
                        <div class="w-100 py-3" :style="step == 1? 'border-top:1px solid #c9c9c9' : ''">
                            <div class="x_div_center">
                                <textarea v-if="step == 1" class="form-control" v-model="ceoMessage" style="width:100%; height:80px" placeholder="건축주가 파트너기관에게 직접 연락을 취할 수 있는 정보(전화번호, 이메일 등)의 기재는 허용되지 않습니다. 해당 권고를 따르지 않을 시 발생가능한, 비딩 및 계약절차상의 불이익 일체에 대하여 엠쓰리시스템즈는 책임지지 않습니다."></textarea>
                                <img v-else-if="fileToDisplay[1].fileLink" class="picture" style="width:100%" :src="fileToDisplay[1].fileLink">
                                <img v-else class="picture" style="width:100%">
                            </div>
                        </div>
                    </div>

                    <div class="x_div_center py-2">
                        <div class="x_div_center px-4 w-100" style="display:block !important">

                            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                <table class="table-borderless w-100">
                                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                    <td class="text-center br_0" width="50%"><span class="fkr-set2 fs-11"><strong>{{ step == 1? '포트폴리오' : '제안서'}}</strong></span></td>
                                    <td class="text-center" width="50%"><span class="fkr-set2 fs-11"><strong>{{ step == 1? '기타홍보자료' : '조감도'}}</strong></span></td>
                                </tr>
                                <tr style="height:55px;">
                                    <td class="text-center br_0">
                                        <div class="x_div_center" v-if="fileToDisplay[0].orgNm">
                                            <div class="x_div_center"><a :href="fileToDisplay[0].fileLink" :download="fileToDisplay[0].orgNm"><span class="fkr-set2">{{fileToDisplay[0].orgNm}}</span></a></div>
                                            <div class="x_div_center px-2"><a href="javascript:void(0)" @click="removeFile(0)" class="rm_frm"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
                                        </div>
                                        <div class="x_div_center" v-else><input type="file" id="file0" @change="fileHandling($event, 0)" class="form-control" style="width:90%; color:#4e0000"></div>
                                    </td>
                                    <td class="text-center">
                                        <div class="x_div_center" v-if="fileToDisplay[1].orgNm">
                                            <div class="x_div_center"><a :href="fileToDisplay[1].fileLink" :download="fileToDisplay[1].orgNm"><span class="fkr-set2">{{fileToDisplay[1].orgNm}}</span></a></div>
                                            <div class="x_div_center px-2"><a href="javascript:void(0)" @click="removeFile(1)" class="rm_frm"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
                                        </div>
                                        <div class="x_div_center" v-else><input type="file" id="file1" @change="fileHandling($event, 1)" class="form-control" style="width:90%; color:#4e0000"></div>
                                    </td>
                                </tr>
                                </table>
                            </div>

                            <div style="height:10px"></div>

                            <div v-if="step == 2 && proposalComments.length" class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                <table class="table-borderless w-100">
                                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                    <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>심사내용</strong></span></td>
                                </tr>
                                <tr v-for="(item, index) in commentsToDisplay" :key="index">
                                    <td class="br_0" style="border-bottom: 1px solid rgb(201, 201, 201); padding: 10px" v-html="item"></td>
                                </tr>
                                <tr style="height:55px;">
                                    <td class="text-center br_0">
                                        <div class="x_div_center"><span class="fkr-set2">문의사항은 support@m3.co.kr 또는 {{companyInfo? companyInfo.phoneNumber : ''}} 으로 <br v-if="isMobile">연락부탁드립니다.</span></div>
                                    </td>
                                </tr>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div class="x_div_center py-4">
                        <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                        <div class="px-2"><button class="x_div_center" @click="step == 1? submitParticipation() : submitAttchs()" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;"><span class="fa-kor-Noto fs-085">등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                        <div class="px-2" v-if="step == 1 && isModifiy"><button class="x_div_center" @click="cancelParticipation()" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">등록취소<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                    </div>

                    <div style="height:20px"></div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'BiddingAttchFlUploaderMulti',
    data() {
        return {
            attchType: this.step == 1? '참여의향' : '제안',
            fileToDisplay: [
				{
					bddngId: null,
					fildId: null,
					orgNm: null,
					fileLink: null
				},
				{
					bddngId: null,
					fildId: null,
					orgNm: null,
					fileLink: null
				}
			],
            proposalComments: [],
			commentsToDisplay: [],
			prcIdToParticipate: null,
			bddngIdToModify: null,
			ceoMessage: '',
			file: [null, null],
			fileName: [null, null],
			deleteFlIdList: [],
			isModifiy: false,
            submitting: false,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET
        }
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        },
        companyInfo: {
            type: Object,
            default: null
        },
        prcCd: {
            type: String,
            default: 'DS'
        },
        step: {
            type: Number,
            default: 1
        }
    },
    async created() {
    },
    methods: {
        renderPrjList(prjList) {
            var renderList = [];
			prjList.forEach((prj) => {
				if(prj.nominatedDt) {
					renderList.push(prj);		
				}
			})
            this.$emit('prjList', renderList);
        },
        async showFileUploadForm(prj, editmode) {
			for(var i=0; i<2; i++) {
				this.fileToDisplay[i].bddngId = null;
				this.fileToDisplay[i].fildId = null;
				this.fileToDisplay[i].orgNm = null;
				this.fileToDisplay[i].fileLink = null;
			}
			this.proposalComments = [];
			this.commentsToDisplay = [];
			if(editmode) {
				const rtnval = await Api.post('/api/v1/common/get/attachments', {
					parentId: prj.bddngId,
					parentUnit: "BDDNG",
					limit: -1,
					ascByCrtDt: false
				})
				if(rtnval.result) {
					if(rtnval.data.attchments) {
						rtnval.data.attchments.forEach((item) => {
							var tokens = item.attchOrgNm.split('.');
							var mimeType = tokens[tokens.length-1].toLowerCase();
							var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
                            var fileLink = '';
                            if(item.attchImg) {
                                const byteCharacters = atob(item.attchImg);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                const blob = new Blob([byteArray], {type: fileType});
                                fileLink = URL.createObjectURL(blob);
                            }
                            else {
                                fileLink = 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + item.attchWebPath;
                            }
							if((this.step == 1 && item.attchFlCtgry.code == 'PF') || (this.step == 2 && item.attchFlCtgry.code == 'PP' && this.fileToDisplay[0].bddngId == null)) {
								this.fileToDisplay[0].bddngId = prj.bddngId;
								this.fileToDisplay[0].fildId = item.id;
								this.fileToDisplay[0].orgNm = item.attchOrgNm;
								this.fileToDisplay[0].fileLink = fileLink;
							}
							else if((this.step == 1 && item.attchFlCtgry.code == 'PR') || (this.step == 2 && item.attchFlCtgry.code == 'RD' && this.fileToDisplay[1].bddngId == null)) {
								this.fileToDisplay[1].bddngId = prj.bddngId;
								this.fileToDisplay[1].fildId = item.id;
								this.fileToDisplay[1].orgNm = item.attchOrgNm;
								this.fileToDisplay[1].fileLink = fileLink;
							}
						})
					}
				}
				else {
					alert(rtnval.errormsg)
				}
				this.proposalComments = prj.proposalComment? (prj.proposalComment.indexOf(';') >= 0? prj.proposalComment.split(';') : [prj.proposalComment]) : [];
      			this.commentsToDisplay = this.proposalComments.filter((v) => v != 'nbsp');
				this.prcIdToParticipate = null;
				this.bddngIdToModify = prj.bddngId;
			}
			else {
				this.prcIdToParticipate = prj.prcId;
				this.bddngIdToModify = this.step == 1? null : prj.bddngId;
			}
			this.isModifiy = editmode;
			this.ceoMessage = prj.ceoMessage? prj.ceoMessage.replace(/<br\/>/g, '\n') : prj.ceoMessage;
			this.file = [null, null];
			this.fileName = [null, null];
			window.$('#file0').val('');
			window.$('#file1').val('');
			window.$('#modalForm').modal('show');
		},
		fileHandling(e, index) {
            var tokens = e.target.files[0].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = this.step == 2 && index == 1? ['jpg', 'jpeg', 'png'] : ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert(this.step == 2 && index == 1? '이미지파일만 업로드 가능합니다.' : '이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                window.$('#file' + index).val('')
                return 1;
            }

            this.file[index] = e.target.files[0];
			this.fileName[index] = e.target.files[0].name;
            if(this.step == 2 && index == 1) {
                window.$('.picture').attr('src', URL.createObjectURL(e.target.files[0]));
                this.fileToDisplay[1].fileLink = URL.createObjectURL(e.target.files[0]);
            }
        },
		removeFile(index) {
			this.deleteFlIdList = [];
			this.deleteFlIdList.push(this.fileToDisplay[index].fildId);
			this.fileToDisplay[index].orgNm = null;
			this.fileToDisplay[index].fileLink = null;
			if(this.step == 2 && index == 1) {
                window.$('.picture').attr('src', '');
            }
		},
		async submitParticipation() {
			this.submitting = true;
			const rtnval = await Api.post('/api/v1/bidding/set', {
				prcId: this.prcIdToParticipate,
				bddngId: this.bddngIdToModify,
				ceoMessage: this.ceoMessage.replace(/\n/g, '<br/>'),
				evalRank: 0
			})
			if(rtnval.result) {
				const formData = new FormData();
				var attchFlCtgry = [];
				var attchOrgNmList = [];
				var bddngId = [];
				if(this.file[0]) {
					formData.append("attchmntList", this.file[0]);
					attchFlCtgry.push('PF');
					attchOrgNmList.push(this.fileName[0]);
					bddngId.push(rtnval.data);
				}
				if(this.file[1]) {
					formData.append("attchmntList", this.file[1]);
					attchFlCtgry.push('PR');
					attchOrgNmList.push(this.fileName[1]);
					bddngId.push(rtnval.data);
				}
				if(attchOrgNmList.length > 0 || this.deleteFlIdList.length > 0) {
					var request = {
						attchFlCtgry: attchFlCtgry,
						attchOrgNmList: attchOrgNmList,
						deleteFlIdList: this.deleteFlIdList,
						parentId: bddngId
					}
					formData.append(
						"request",
						new Blob([JSON.stringify(request)], { type: "application/json" })
					);
					const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData)
					if(rtnvalue.result) {
						alert('참여 요청 등록이 정상적으로 처리되었습니다.')
						this.fetching = true;
						this.$emit('fileUploaded');
						this.fetching = false;
						this.submitting = false;
						this.closeModal();
					}
					else {
						alert(rtnvalue.errormsg)
					}
				}
				else {
					alert('참여 요청 등록이 정상적으로 처리되었습니다.')
					this.fetching = true;
					this.$emit('fileUploaded');
					this.fetching = false;
					this.submitting = false;
					this.closeModal();
				}
			}
			else {
				alert(rtnval.errormsg)
				this.submitting = false;
			}
		},
		async cancelParticipation() {
			if(confirm('참여 의향 등록을 취소하시겠습니까?')) {
				const rtnval = await Api.post('/api/v1/bidding/delete?bddngId=' + this.bddngIdToModify);
				if(rtnval.result) {
					alert('정상적으로 등록취소 되었습니다.')
					this.fetching = true;
					this.$emit('fileUploaded');
					this.fetching = false;
					this.ceoMessage = null;
					for(var i=0; i<2; i++) {
						this.fileToDisplay[i] = {
							bddngId: null,
							fildId: null,
							orgNm: null,
							fileLink: null
						};
						this.file[i] = null;
						this.fileName[i] = null;
					}
					this.closeModal();
				}
				else {
					alert(rtnval.errormsg);
				}
			}
		},
        async submitAttchs() {
            const formData = new FormData();
            var attchFlCtgry = [];
            var attchOrgNmList = [];
            var dirNmList = [];
            var bddngId = [];

            if(this.file[0]) {
                formData.append("attchmntList", this.file[0]);
                attchFlCtgry.push('PP');
                attchOrgNmList.push(this.fileName[0]);
                dirNmList.push(null);
                bddngId.push(this.bddngIdToModify);
            }
            if(this.file[1]) {
                formData.append("attchmntList", this.file[1]);
                attchFlCtgry.push('RD');
                attchOrgNmList.push(this.fileName[1]);
                dirNmList.push('platform/dsgn/renderedimg')
                bddngId.push(this.bddngIdToModify);
            }

            if(attchOrgNmList.length == 0) {
                alert('파일이 업로드되지 않았습니다.');
                return;
            }

			var request = {
				parentId: bddngId,
				attchOrgNmList: attchOrgNmList,
				attchFlCtgry: attchFlCtgry,
                dirNmList: dirNmList

			}

            formData.append(
                "request",
                new Blob([JSON.stringify(request)], { type: "application/json" })
            );

			var rtnval = null;
			this.submitting = true;
			rtnval = await Api.post('/api/v1/common/set/attachments', formData);
			this.submitting = false;
			if(rtnval.result) {
				alert('제안 등록이 정상적으로 처리되었습니다.\n등록하신 제안은 관리자의 심사 후 건축주에게 공개됩니다.')
                this.$emit('fileUploaded');
				this.closeModal();
			}
			else {
				alert(rtnval.errormsg);
			}
		},
		renderDateForm(datetime) {
            if (this.isMobile) {
                return datetime? datetime.split('T')[0].substring(5) : '';
            }
            else {
                return datetime? datetime.split('T')[0] : '';
            }
        },
		renderEmailForm(email) {
            if (this.isMobile) {
                return email? (email.split('@')[0] + '@\n' + email.split('@')[1]) : '';
            }
            else {
                return email;
            }
        },
		closeModal() {
			window.$('#modalDetail0').modal('hide');
			window.$('#modalDetail1').modal('hide');
			window.$('#modalForm').modal('hide');
		}
    }
}
</script>