<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">이벤트 참여자</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">총 참여인원: {{ numOfUsr }} 명</span>
            <select class="custom-select ff-eng1 fs-085" id="selectEvent" v-model="eventId" @change="switchEvent()" style="width:220px; height:37px; margin-left: 20px">
                <option v-for="(evt, index) in events" :key="index" :value="evt.id">{{ evt.eventName }}</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10명</option>
                <option value="20">페이지당 20명</option>
                <option value="-1">모두 조회</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="isReceivedFilter" @change="switchReceivedFilter()" style="width:120px; height:37px; margin-left: 20px">
              <option value=''>전체</option>
              <option value='Y'>상품 수령</option>
              <option value='N'>상품 미수령</option>
            </select>
            <div class="ps-2" style="margin-left: auto; margin-right: 20px" v-show="pageSize == -1"><button class="fkr-set2 fs-095" @click="exportExcel()" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">Excel 다운로드</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findUsr()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findUsr()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="이름 또는 전화번호 검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:25%"><span class="fkr-set1 fs-9">이름</span></div>
                <div class="x_div_center" style="width:25%"><span class="fkr-set1 fs-9">연락처</span></div>
                <div class="x_div_center" style="width:25%"><span class="fkr-set1 fs-9">쿠폰파일명</span></div>
                <div class="x_div_center" style="width:25%"><span class="fkr-set1 fs-9">수령일</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in usrList" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:25%"><span class="fkr-set2 fs-9">{{item.name}}</span></div>
                  <div class="x_div_center m_line" style="width:25%"><span class="fkr-set2 fs-9">{{item.phone}}</span></div>
                  <div class="x_div_center m_line" style="width:25%"><span class="fkr-set2 fs-9">{{item.fileName}}</span></div>
                  <div class="x_div_center m_line" style="width:25%"><span class="fkr-set2 fs-9">{{item.sentDate? dateFormater(item.sentDate) : ''}}</span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; findUsrAll()" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "EventResult",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: false,
        searchWord: '',
        numOfUsr: '',
        events: [],
        usrList:[],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        eventId: null,
        isReceivedFilter: ''
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    await this.findEvents();
    await this.findUsrAll();
    this.setPaging();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async findEvents(){
      this.fetching = true;
      var rtnval = await Api.get('/api/v1/admin/event/mng/findAll/event');
      if(rtnval.result){
        this.events = rtnval.data.findAllEventList;
        this.eventId = rtnval.data.findAllEventList? rtnval.data.findAllEventList[0].id : null;
      }
      this.fetching = false;
    },

    async findUsr(){
      this.fetching = true;
      var rtnval = await Api.post('/api/v1/admin/event/mng/findAll/usr', {eventId: this.eventId, searchWord: this.searchWord});
      if(rtnval.result){
        this.usrList = rtnval.data.findAllUsrDtoList;
      }
      this.fetching = false;
    },

    async findUsrAll(){
      this.fetching = true;
      this.usrList = [];
      var rtnval = await Api.post('/api/v1/admin/event/mng/findAll/usrAll', {page: this.currentPage, window: this.pageSize, receivedYn: this.isReceivedFilter? this.isReceivedFilter : null, eventId: this.eventId});
      if(rtnval.result){
        this.usrList = rtnval.data.findAllUsrDtoList;
        this.numOfUsr = rtnval.data.count;
      }
      this.fetching = false;
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.numOfUsr / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        await this.findUsrAll();
        this.setPaging();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      await this.setPaging();
      this.findUsrAll();
    },

    async switchReceivedFilter() {
      await this.findUsrAll();
      this.setPaging();
    },

    async switchEvent() {
      await this.findUsrAll();
      this.setPaging();
    },

    dateFormater(date) {
      var dt = new Date(date).getTime() + 9*3600*1000
      return new Date(dt).toLocaleString();

    },

    exportExcel(){ 
        // step 1. workbook 생성
        var wb = window.XLSX.utils.book_new();

        // step 2. 시트 만들기 
        var newWorksheet = window.XLSX.utils.json_to_sheet(this.usrList);
        
        // step 3. workbook에 새로만든 워크시트에 이름을 주고 붙인다.  
        window.XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1');

        // step 4. 엑셀 파일 만들기 
        var wbout = window.XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

        // step 5. 엑셀 파일 내보내기 
        window.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), window.$('#selectEvent').text() + '_' + new Date().toISOString() + '.xlsx');
    },

    s2ab(s) { 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }
  }
}
</script>

