<template>
     <div class="d-flex">
        <LNB :user="user"/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                    <div class="x_div_center pt-5">
						<div class="" style="width:75%"><span class="fkr-set1 fs-16" style="color:#333333">{{prjNm}}</span></div>
						<div class="text-end" style="width:25%"><span class="fkr-set2" style="color:#484848">{{prcNm}} > <span class="fw-bold">{{prcStpNm}}</span></span></div>
					</div>

                    <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                    <div style="height:80px"></div>

                    <div class="pg_m_set" style="margin-top: 20px" v-if="loading">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div v-else>
                        <div v-for="(item, index) in fileList" :key="index">
                            <div class="bs_tab21">
                                <table class="table-borderless w-100" style="table-layout:fixed;">
                                    <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                        <td colspan="2" class="text-center" width="50%"><span class="ff-kor1" style="font-size:12pt"><strong>{{filesCtgryList[index].name}}</strong></span></td>
                                    </tr>
                                    <tr style="border-bottom:1px solid #c9c9c9">
                                        <td colspan="2" class="text-center">
                                            <h3 v-if="fetching"><i class='fa fa-spinner fa-spin'></i></h3>
                                            <div v-else-if="fileList[index].id" class="x_div_center">
                                                <img v-if="['pdf', 'zip'].indexOf(fileList[index].mimeType) < 0" :id="'img' + index" style="width: 100%; border:0px solid #c9c9c9; border-radius:8px" :src="fileList[index].fileLink">
                                                <span v-else class="fen-set2 fs-10" style="margin:15px"><a :href="fileList[index].fileLink" :download="fileList[index].name">{{ fileList[index].name }}</a></span>
                                            </div>
                                            <div v-else class="x_div_center"><img :id="'img' + index" style="width: 100%; border:0px solid #c9c9c9; border-radius:8px" src="@/assets/img_legacy/nofileimage.jpg"></div>
                                        </td>
                                    </tr>
                                    <tr v-if="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                                        <td colspan="2" class="text-center" width="100%">
                                            <input type="file" :id="'file' + index" @change="fileHandling($event, index)" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div class="bs_tab_blank"></div>
                        </div>
                        <div class="bs_tab22" style="border:#fff" v-if="this.$route.params.prcStpCd == 'MC'">
                            <table class="table-borderless w-100" style="table-layout:fixed;">
                                <tr>
                                    <td colspan="2" class="text-center" v-if="user.info.usrRole == 'MF' && instlltnRqstYn">
                                        <div class="w-100 x_div_center pt-5"><h3 class="fkr-set1 fs-11">건축주가 제조 완료를 확인하고 운송을 요청했습니다.</h3></div>
                                        <br/>
                                        <div class="x_div_center">
                                            <span class="fa-kor-Noto" style="font-size:13pt">설치 시작 <br v-if=isMobile>예정일</span>&nbsp;&nbsp;&nbsp;
                                            <span v-if="instlltnStrtDt" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ instlltnStrtDt.split('T')[0] }}</span>
                                            <Datepicker v-else @update-date="setInstallationDate" class="form-control fkr-set2 fc-w5 text-center" style="width:160px; height:36px; border-radius:4px; border:1px solid #c9c9c9"/>&nbsp;&nbsp;&nbsp;
                                            <button v-if="!instlltnStrtDt" @click="saveInstallationDate()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fa-kor-Noto fs-085">날짜 저장</span></button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div style="height:20px"></div>

                    <div class="x_div_end" v-if="!(user.info.usrRole == 'OW' || user.info.usrRole == 'SL')">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="saveFiles()" style="width:140px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">파일 일괄 저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                        <div class="py-2 pe-1" v-if="prcStpCd == 'MC'"><button class="fkr-set2 fs-095" @click="completeManufacture()" style="width:180px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">제조 완료 및 확인 요청<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                    <div class="x_div_end" v-else-if="user.info.usrRole == 'OW' && prcStpCd == 'MC' && mnfctCmpltnCnfrmRqstYn">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="requestInstallation()" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">운송 요청<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                    <div class="x_div_end" v-else-if="user.info.usrRole == 'OW' && ['IC', 'CA'].indexOf(prcStpCd) >= 0">
                        <div class="py-2 pe-1"><button class="fkr-set2 fs-095" @click="moveToReview(prcStpCd)" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">후기 작성</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LNB from '@/components/LNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'PrcStpSingle',
    components: {
		LNB, Datepicker
	},
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            prcTree: null,
            prjId: null,
            prjNm: '',
            prcId: null,
            prcNm: '',
            prcStpId: null,
            prcStpCd: null,
            prcStpNm: '',
            status: 0,
            loading: true,
            fetching: true,
            submitting: false,
            filesCtgryList: [],
            filesCtgryIndexMap: [],
            fileList: [],
            deleteFlIdList: [],
            mnfctCmpltnCnfrmRqstYn: null,
            instlltnRqstYn: null,
            instlltnStrtDt: null,
            instlltnStrtDtInput: null
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           if(this.$route.query.prjId){
               this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent("/project" + location.href.split("/project")[1]) } });
           }
           else {
               location.href = '/member/login'
           }
        }

        if(this.$route.query.prjId){
            var rtnval = await Api.get('/api/v1/process/list/prc/tree?prjId=' + this.$route.query.prjId);
            if(rtnval.result) {
                sessionStorage.setItem('prcTree', JSON.stringify(rtnval.data));
                sessionStorage.setItem('prjId', this.$route.query.prjId);
                sessionStorage.setItem('prjNm', rtnval.data.prjNm);
                var rtnvalue = await Api.get('/api/v1/user/list/authorized/prc?usrRole=' + this.user.info.usrRole);
                if(rtnvalue.result) {
                    var accessiblePrc = [];
                    rtnvalue.data.processes.forEach((prc) => {
                        accessiblePrc.push(prc.code);
                    })
                    sessionStorage.setItem('accessiblePrc', JSON.stringify(accessiblePrc));
                    location.href = location.href.split("?")[0];
                }
                else {
                    alert(rtnvalue.errormsg)
                }
            }
            else {
                alert(rtnval.errormsg)
            }
        }
        else {
            this.prjId = sessionStorage.getItem('prjId');
            this.prjNm = sessionStorage.getItem('prjNm');
            this.prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        }
        
        this.prcTree.processList.forEach((prc) => {
            if(prc.code == this.$route.params.prcCd) {
                this.prcNm = prc.name;
                prc.prcStpList.forEach((prcStp) => {
                    if(prcStp.code == this.$route.params.prcStpCd) {
                        this.prcStpNm = prcStp.name;
                    }
                })
            }
        })
        this.prcTree.prcScndryList.forEach((prc) => {
            if(prc.code == this.$route.params.prcCd) {
                this.prcNm = prc.name;
                prc.prcStpList.forEach((prcStp) => {
                    if(prcStp.code == this.$route.params.prcStpCd) {
                        this.prcStpNm = prcStp.name;
                    }
                })
            }
        })

        if(this.prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }

        if(['MO','CO','TI','TO','TC'].indexOf(this.$route.params.prcStpCd) >= 0) {
            location.href = '/project/' + this.$route.params.prcCd + '/' + this.$route.params.prcStpCd + '/multiple';
        }

        if(this.$route.params.prcCd == 'MN') {
            location.href = '/project/maintainance';
        }

        this.prcStpCd = this.$route.params.prcStpCd;

        var prcCd = ['IS'].indexOf(this.$route.params.prcCd) >= 0? 'MA' : this.$route.params.prcCd;
        var rtnval = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: prcCd});
        if(rtnval.result) {
            this.prcId = rtnval.data.prcId;
        }
        else {
            alert(rtnval.errormsg)
        }

        rtnval = await Api.post('/api/v1/common/get/code/family', {codeCtgry: 'PRSTFC', ascByOrderNum: true});
		if(rtnval.result) {
			var index = 0;
			rtnval.data.forEach((code) => {
				if(code.upperCode == this.$route.params.prcStpCd) {
					this.filesCtgryList.push(code);
					this.filesCtgryIndexMap[code.orderNum] = index++;
				}
			})
		}
		else {
			alert(rtnval.errormsg);
		}
        this.loading = false;

        this.fetchAttchList();
    },
    methods: {
        async fetchAttchList() {
            this.fileList = [];
            this.filesCtgryList.forEach(() => {
                this.fileList.push({id: null, name: null, fileLink: null, file: null, mimeType: null});
            })
            this.fetching = true;
            this.deleteFlIdList = [];

            const rtnvalue = await Api.post('/api/v1/prcess/step/get', {prjId: this.prjId, code: this.$route.params.prcStpCd});
            if(rtnvalue.result) {
                if(rtnvalue.data.prcStpId) {
                    this.prcStpId = rtnvalue.data.prcStpId;
                    this.status = rtnvalue.data.stts;
                    if(this.$route.params.prcStpCd == 'MC') {
                        this.mnfctCmpltnCnfrmRqstYn = rtnvalue.data.strtDt;
                        this.instlltnRqstYn = rtnvalue.data.endDt;
                        this.instlltnStrtDt = new Date(rtnvalue.data.endDt) > new Date('1991-07-09 08:20:38')? rtnvalue.data.endDt : null;
                    }
    
                    const returnvalue = await Api.post('/api/v1/common/get/attachments', {
                        parentId: rtnvalue.data.prcStpId,
                        parentUnit: 'PRCSTP',
                        ascByCrtDt: true,
                        limit: -1
                    });
                    if(returnvalue.result) {
                        if(returnvalue.data.attchments) {
                            returnvalue.data.attchments.forEach((item) => {
                                var tokens = item.attchOrgNm.split('.');
                                var mimeType = tokens[tokens.length-1].toLowerCase();
                                var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
        
                                const byteCharacters = atob(item.attchImg);
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                const blob = new Blob([byteArray], {type: fileType});
                                const fileLink = URL.createObjectURL(blob);
                                this.fileList[this.filesCtgryIndexMap[item.attchFlCtgry.orderNum]] = {id: item.id, name: item.attchOrgNm, nameNew: null, file:null, fileLink: fileLink, mimeType: mimeType};
                            })
                        }
                        this.$forceUpdate();
                    }
                    else {
                        alert(returnvalue.errormsg);
                    }
                }
            }
            else {
                alert(rtnvalue.errormsg);
            }
            this.fetching = false;
        },

        fileHandling(e, index) {
            var tokens = e.target.files[0].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                window.$('#file' + index).val('')
                return 1;
            }

            this.fileList[index].file = e.target.files[0];
            this.fileList[index].nameNew = e.target.files[0].name;
            if(this.fileList[index].id && this.deleteFlIdList.indexOf(this.fileList[index].id) < 0) {
                this.deleteFlIdList.push(this.fileList[index].id);
            }

            if(['pdf', 'zip'].indexOf(fileType) < 0) {
                window.$('#img' + index).attr('src', URL.createObjectURL(e.target.files[0]));
            }
        },
        async saveFiles() {
            const formData = new FormData();
            var attchFlCtgry = [];
            var attchFlTyp = [];
            var attchOrgNmList = [];
            var prcStpId = [];
            var numOfFiles = 0;
            this.fileList.forEach((row, index) => {
                if(row.nameNew || row.id) {
                    if(row.nameNew) {
                        formData.append("attchmntList", row.file);
                        attchFlCtgry.push(this.filesCtgryList[index].code);
                        attchFlTyp.push('OR');
                        attchOrgNmList.push(row.nameNew);
                    }
                    numOfFiles++;
                }
            })
            if(attchOrgNmList.length > 0) {
                var status = numOfFiles >= this.filesCtgryList.length ? 2 : 1
                this.submitting = true;
                const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, stts: status});
                if(rtnval.result) {
                    this.status = status;
                    this.prcStpId = rtnval.data;
                    attchOrgNmList.forEach(() => {
                        prcStpId.push(this.prcStpId);
                    })
                    var request = {
                        attchFlCtgry: attchFlCtgry,
                        attchFlTyp: attchFlTyp,
                        attchOrgNmList: attchOrgNmList,
                        deleteFlIdList: this.deleteFlIdList,
                        parentId: prcStpId
                    }
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
                    const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData)
                    if(rtnvalue.result) {
                        await this.fetchAttchList();
                    }
                    else {
                        alert(rtnvalue.errormsg)
                    }
                }
                else {
                    console(rtnval.errormsg)
                }
                this.submitting = false;
            }
            else {
                alert("파일을 하나 이상 첨부해주세요.");
            }
        },
        async completeManufacture() {
            if(this.status < 2) {
                alert('자료를 먼저 업로드해주세요.')
                return;
            }
            this.submitting = true;
            const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, strtDt: new Date().toISOString()});
            this.submitting = false;
            if(rtnval.result) {
                alert("요청이 정상적으로 전송되었습니다.")
                this.fetchAttchList();
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        async requestInstallation() {
            this.submitting = true;
            const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, endDt: new Date('1989-02-17 17:20:38').toISOString()});
            this.submitting = false;
            if(rtnval.result) {
                alert("요청이 정상적으로 전송되었습니다.")
                this.fetchAttchList();
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        setInstallationDate(date) {
            this.instlltnStrtDtInput = new Date(date).toISOString();
            
        },
        async saveInstallationDate() {
            if(!this.instlltnStrtDtInput) {
                alert('시작 예정일을 지정해주세요.');
                return 1;
            }

            const rtnval = await Api.post('/api/v1/prcess/step/set', {prcStpId: this.prcStpId, prcId: this.prcId, code: this.$route.params.prcStpCd, endDt: new Date().toISOString()});
            if(rtnval.result) {
                this.fetchAttchList();
            }
            else {
                alert(rtnval.errormsg)
            }
        },
        moveToReview(prcStpCd) {
            location.href = '/project/review/' + (prcStpCd == 'IC'? 'MA' : 'CN');
        }
    }
}
</script>