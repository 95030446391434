<template>
    <div class="ca_menu" :style="'min-height:100%; padding-top:65px; border-right:1px solid #c9c9c9; width:' + (collapsed? '53px' : '100%')">

        <div class="accordion-item"> 

            <!-- MyProject -->
            <div class="x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button">
                    <div><a href="/project/dashboard"><div class="ms_icon1"></div></a></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold"><a href="/project/dashboard">My Projects</a></span></div>
                    <div v-show="!collapsed" class="sm_ms2"> </div>
                </div>
            </div>

            <!-- 개요 -->
            <div class="x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button">
                    <div><a :href="'/project/summary/' + prjId"><div :class="'ms_icon2' + (url.indexOf('/project/summary/') > 0 ? '_on' : '')"></div></a></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold"><a :href="'/project/summary/' + prjId">개요</a></span></div>
                    <div v-show="!collapsed" class="sm_ms2"> </div>
                </div>
            </div>

            <div v-if="prcAuth.indexOf(prc0.code) >= 0">
                <!-- 설계 -->
                <div id="headingOne0" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne0" aria-expanded="false" aria-controls="collapseOne">
                        <div><div :class="'ms_icon3' + (url.indexOf('/project/' + prc0.code) > 0 ? '_on' : '')"></div></div>
                        <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">{{ prc0.name }}</span></div>
                        <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                    </div>
                </div>

                <div v-show="!collapsed" id="collapseOne0" :class="'sm_ms2 accordion-collapse collapse' + (url.indexOf('/project/' + prc0.code) > 0 ? ' show' : '')" aria-labelledby="headingOne0" data-bs-parent="#m3_menu">
                    <div class="accordion-body p-3">
                        <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/project/' + prc0.code + '/summary') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prc0.code + '/summary'">개요</a></div></div>
                        <div v-for="(prcStp, index) in prc0.prcStpList" :key="index"
                             :class="'side_menu_m w-100 x_div_center ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prc0.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="height:36px; padding-left:40px">
                             <div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prc0.code + '/' + prcStp.code + '/dsgn'">{{ prcStp.name }}</a></div>
                        </div>
                        <div :class="'side_menu_m w-100 x_div_center ms_bg' + (dsgnCompleted? '1' : '2') + (url.indexOf('/project/' + prc0.code + '/dsgn-info') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prc0.code + '/dsgn-info'">설계 요약</a></div></div>
                    </div>
                </div>
            </div>

            <div v-if="prc8">
                <!-- 제조/시공/인테리어 -->
                <div id="headingOne1" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                    <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne">
                        <div><div :class="'ms_icon4' + (prcCd.indexOf(currentPrcCd) >= 0 ? '_on' : '')"></div></div>
                        <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">{{ prcTitles }}</span></div>
                        <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                    </div>
                </div>
            </div>

            <div v-show="!collapsed" id="collapseOne1" :class="'sm_ms2 accordion-collapse collapse' + (prcCd.indexOf(currentPrcCd) >= 0 ? ' show' : '')" aria-labelledby="headingOne1" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <div v-for="(prc, index) in prc" :key="index">
                        <div v-if="prcAuth.indexOf(prc.code) >= 0">
                            <div class="side_menu_m w-100 x_div_center" style="height:36px; padding-left:40px"><div class="w-100 text-start"><span class="fw-bold">{{ prc.name }}</span></div></div>

                            <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/project/' + prc.code + '/summary') > 0 ? '_on' : '')" style="height:36px; padding-left:50px"><div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prc.code + '/summary'">개요</a></div></div>
                            <div v-for="(prcStp, kintex) in prc.prcStpList" :key="kintex"
                                :class="'side_menu_m w-100 x_div_center ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prc.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="height:36px; padding-left:50px">
                                <div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prc.code + '/' + prcStp.code">{{ prcStp.name }}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 운송/설치/유지보수 -->
            <div v-for="(prcs, index) in prcs" :key="index">
                <div v-if="prcAuth.indexOf(prcs.code) >= 0">
                    <div :id="'headingOne' + prcs.orderNum" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                        <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + prcs.orderNum" aria-expanded="false" aria-controls="collapseOne">
                            <div><div :class="'ms_icon' + (prcs.orderNum < 6? prcs.orderNum+1 : 7) + (url.indexOf('/project/' + prcs.code) > 0 ? '_on' : '')"></div></div>
                            <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">{{ prcs.name }}</span></div>
                            <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                        </div>
                    </div>

                    <div v-show="!collapsed" :id="'collapseOne' + prcs.orderNum" :class="'sm_ms2 accordion-collapse collapse' + (url.indexOf('/project/' + prcs.code) > 0 ? ' show' : '')" :aria-labelledby="'headingOne' + prcs.orderNum" data-bs-parent="#m3_menu">
                        <div class="accordion-body p-3">
                            <div v-if="prcs.code == 'DE'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/project/' + prcs.code + '/summary') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prcs.code + '/summary'">개요</a></div></div>
                            <div v-for="(prcStp, kintex) in prcs.prcStpList" :key="kintex"
                                :class="'side_menu_m w-100 x_div_center ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prcs.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="height:36px; padding-left:40px">
                                <div class="w-100 text-start"><a class="ms_fa1" :href="'/project/' + prcs.code + '/' + prcStp.code">{{ prcStp.name }}</a></div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>

        <div class="x_div_end" style="padding:50px 8px 50px 0">
            <div @click="collapsed = !collapsed" :class="'x_div_end ar_btn' + (collapsed? '' : ' sec')"></div>
        </div>

    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'LeftMenu',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            prjId: null,
            url: '',
            prc0: {
                code: '',
                name: '',
                orderNum: 0,
                prcStpList: []
            },
            prc: [],
            prcs: [],
            prcCd: [],
            prcAuth: [],
            prc8: false,
            currentPrcCd: '',
            prcTitles: '',
            dsgnCompleted: false,
            collapsed: false
        }
    },
    async created() {
        this.url = location.href;
        this.currentPrcCd = this.url.split('/project/')[1].split('/')[0];
        this.prcAuth = JSON.parse(sessionStorage.getItem('accessiblePrc'));
        if(!(this.currentPrcCd == 'summary' || this.currentPrcCd == 'review' || this.currentPrcCd == 'maintainance') && (this.prcAuth != null && this.prcAuth.indexOf(this.currentPrcCd) < 0)) {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        const prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        prcTree.processList.forEach((prc, index) => {
            if(index == 0) {
                this.prc0 = prc;
            }
            else {
                this.prcTitles += prc.name + (index+1 < prcTree.processList.length? ' / ' : '');
                this.prc.push(prc);
                this.prcCd.push(prc.code);
                if(!this.prc8) {
                    this.prc8 = this.prcAuth.indexOf(prc.code) >= 0? true : false;
                }
            }
        })
        prcTree.prcScndryList.forEach((prcs) => {
            this.prcs.push(prcs);
        })
        this.prjId = sessionStorage.getItem('prjId');
        const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
        if(rtnval.result) {
            rtnval.data.prcInfoDto.forEach((dto, index) => {
                if(index == 0) {
                    this.prc0.prcStpList.forEach((prcStp) => {
                        prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
                    })
                    if(dto.prcStpOrdNumMax+1 == this.prc0.prcStpList.length && dto.prcStts.indexOf("완료") >= 0) {
                        this.dsgnCompleted = true;
                    }
                }
                else {
                    this.prc[index-1].prcStpList.forEach((prcStp) => {
                        prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
                    })
                    if(['MA', 'DE'].indexOf(dto.prcTyp) >= 0) {
                        this.prcs.forEach((prcs) => {
                            prcs.prcStpList.forEach((prcStp) => {
                                prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
                            })
                        })
                    }
                }
            })
            if(rtnval.data.prj.prjCmpltnYn) {
                this.prcs[this.prcs.length-1].prcStpList.forEach((prcStp) => {
                    prcStp.started = true;
                })
            }
            this.$forceUpdate();
        }
        else {
            alert(rtnval.errormsg)
        }
    },
    async mounted() {
    }
}
</script>

<style scoped>
.ms_bg1 a {
  font-size: .85rem;
}
.ms_bg2 a {
  font-size: .85rem;
}
.ms_bg1_on a {
  font-size: .85rem;
}
.ms_bg2_on a {
  font-size: .85rem;
}
</style>