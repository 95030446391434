<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">문의하기설정</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>문의양식 {{ $route.params.formId > 0? '수정' : '등록' }}</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>문의항목명</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="formName">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>문의항목설명</strong></span></td>
                  <td class="text-center" width="70%">
                    <input type="text" class="form-control fkr-set2" style="width:270px; height:36px; margin-left: 20px;" v-model="formDesc">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>문의항목타입</strong></span></td>
                  <td class="text-left" width="70%">
                    <select class="custom-select ff-eng1 fs-085" v-model="formType" style="width:160px; height:36px; margin-left: 20px">
                        <option value="0">텍스트입력</option>
                        <option value="1">숫자입력</option>
                        <option value="2">객관식 - 다중선택</option>
                        <option value="3">객관식 - 단일선택</option>
                        <option value="4">텍스트입력(장문)</option>
                    </select>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>사용 카테고리</strong></span></td>
                  <td class="text-center" width="70%">
                    <ul class="custom-control custom-checkbox td-flex">
                        <li class="chk_box" v-for="(ctgry, index) in ctgryList" :key="index" style="margin: 0 20px 0 20px">
                          <input type="checkbox" :id="'ctgry' + ctgry.ctgryId" name="usingCtgries" class="custom-control-input" v-model="usingCtgries" :value="ctgry.ctgryId" >
                          <label class="custom-control-label" :for="'ctgry' + ctgry.ctgryId"><span class="fkr-set2">{{ ctgry.ctgryName }}</span></label>
                        </li>
                    </ul>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9" v-if="['0', '1', '4'].indexOf(formType) >= 0">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>답변항목 표시양식샘플</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:480px; height:36px; margin-left: 20px;" v-model="placeHolder"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9" v-else>
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>답변항목(객관식)</strong></span></td>
                  <td class="text-center" width="50%" style="padding: 10px !important">
                    <div class="x_div_center ls_bg1" style="height:55px" v-for="(answer, index) in answerList" :key="index">
                        <div class="x_div px-1 td-flex" style="width:90%">
                          <input type="text" v-model="answer.label" class="form-control fkr-set2 fc-w5" style="width:30%; height:36px">
                          <input type="number" v-model="answer.orderInForm" class="form-control fkr-set2 fc-w5" style="width:15%; height:36px; margin-left: 40px" placeholder="노출순서">
                        </div>
                        <div class="x_div_center px-1" style="width:10%">
                          <a href="javascript:void(0)" class="rm_frm" @click="answerList = answerList.filter((v,i) => i != index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a>
                        </div>
                    </div>
                  </td>
                  <td class="text-center" width="20%" style="padding: 20px !important">
                    <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="answerList.push({label: ''})"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>
                  </td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveForm()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
            <div v-if="$route.params.formId > 0" class="py-2"><button class="fkr-set2 fs-095" @click="deleteForm()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="moveToList()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">{{ $route.params.formId > 0? '목록' : '취소' }}</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "QuestionFormEdit",
  components: {
		AdminLNB
	},
  data(){
    return {
      formName: '',
      formDesc: '',
      formType: '0',
      ctgryList: [],
      usingCtgries: [],
      usingCtgriesOrig: [],
      ctgryIdDelList: [],
      ctgryIdNewList: [],
      placeHolder: '',
      answerList: [],
      fetching: true,
      submitting: false,
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/form/login'
    }

    const rtrnvalue = await Api.get('/api/v1/question/ctgry/list');
    if(rtrnvalue.result) {
      this.ctgryList = rtrnvalue.data.sort((a,b) => a.orderInPage - b.orderInPage);
    }
    else {
      alert(rtrnvalue.errormsg)
    }

    if(this.$route.params.formId > 0) {
      const returnvalue = await Api.get('/api/v1/question/form/get?formId=' + this.$route.params.formId);
      if(returnvalue.result) {
        this.formName = returnvalue.data.formName;
        this.formDesc = returnvalue.data.formDesc;
        this.formType = String(returnvalue.data.formType);
        this.placeHolder = returnvalue.data.placeHolder;
        this.answerList = returnvalue.data.answerOptionList;
        returnvalue.data.ctgryList.forEach((ctgry) => {
          this.usingCtgries.push(ctgry.id);
          this.usingCtgriesOrig.push(ctgry.id);
        })
      }
      else {
        alert(returnvalue.errormsg)
      }
    }

    this.fetching = false;
  },

  methods:{
    async saveForm() {
      if(!this.formName) {
        alert('이름은 필수 입력사항입니다.');
        return;
      }

      // check question category ids to be unlinked from this form
      if(this.usingCtgriesOrig.length) {
        if(this.usingCtgries.length) {
          this.usingCtgriesOrig.forEach((fetchedId) => {
            var remaining = false;
            this.usingCtgries.forEach((ctgryId) => {
              if(ctgryId == fetchedId) {
                remaining = true;
              }
            })
            if(!remaining) {
              this.ctgryIdDelList.push(fetchedId);
            }
          })
        }
        else {
          this.ctgryIdDelList = this.usingCtgriesOrig;
        }
      }
      else {
        this.ctgryIdNewList = this.usingCtgries;
      }

      // check question category ids to be newly linked to this form
      if(this.usingCtgries.length) {
        if(this.usingCtgriesOrig.length) {
          this.usingCtgries.forEach((ctgryId) => {
            var newOne = true;
            this.usingCtgriesOrig.forEach((fetchedId) => {
              if(fetchedId == ctgryId) {
                newOne = false;
              }
            })
            if(newOne) {
              this.ctgryIdNewList.push(ctgryId);
            }
          })
        }
        else {
          this.ctgryIdNewList = this.usingCtgries;
        }
      }
      else {
        this.ctgryIdDelList = this.usingCtgriesOrig;
      }

      this.submitting = true;

      const rtnval = await Api.post('/api/v1/question/form/set', {
        id: this.$route.params.formId > 0? this.$route.params.formId : null,
        formName: this.formName,
        formDesc: this.formDesc,
        formType: Number(this.formType),
        placeHolder: this.placeHolder,
        answerOptionList: this.answerList,
        ctgryIdNewList: this.ctgryIdNewList.length? this.ctgryIdNewList : null,
        ctgryIdDelList: this.ctgryIdDelList.length? this.ctgryIdDelList : null
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        this.moveToList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    async deleteForm() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        const rtnvalue = await Api.get('/api/v1/question/form/delete?formId=' + this.$route.params.formId)
        if(rtnvalue.result) {
          alert('정상적으로 삭제되었습니다.')
          this.moveToList();
        }
        else {
          alert(rtnvalue.errormsg)
        } 
      }
    },

    moveToList(){
      location.href = '/admin/site/questionform-list';  
    }
  }
}
</script>

<style scoped>
.td-flex {
  display: flex;
  flex-wrap: wrap;
}
</style>