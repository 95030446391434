<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <!-- mypage 시작 { -->
        <div class="m_mem_box">

            <div class="pg_m_set" v-if="fetching">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else style="width:90%">

                <div class="w-100 x_div pb-3" style="display:flex; justify-content:space-between">
                    <div class="h3 ff-kor1 fw-bold fc-w6">{{prjNm}} 프로젝트</div>
                    <button class="fkr-set1 fc-w1" :style="'width:60px; height:26px; border:0; padding-top:0; background-color:' + sttsColor" disabled>{{status}}</button>
                </div>
                
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">고객명</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ customerNm }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">연락처</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ phoneDashed }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1" v-if="email">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">E-Mail</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ email }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">유입경로</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ visitChannel }} </span></div>
                </div>

                <div class="y_div_blank1rem" style="margin-bottom:10px"></div>

                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">용도</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ usage }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">건축예정지</span></div>
                    <div class="w-100"><a :href="maplink" target="_blank"><span class="fen-set2"><u> {{ addr }} </u></span></a></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">연면적</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ totalGroundAreaPy }}평, {{ totalGroundArea }}㎡ </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">층수</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ floors }}층 </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">건축 일정</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ buildScheduleMonth == 0? '미정' : (buildScheduleYear + '년 ' + buildScheduleMonthDp) }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">구조</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ structure }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">예산</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ expectedCost }}억 </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">연락선호시간</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ preferredContactTime }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">비고</span></div>
                    <div class="w-100"><span class="fen-set2" v-html="etc"></span></div>
                </div>

                <div class="y_div_blank1rem" style="margin-bottom:10px"></div>

                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">최근 연락</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ recentContactTime? recentContactTime.split('T')[0] + ' | ' + recentContactTime.split('T')[1] : '' }} </span></div>
                </div>
                <div class="x_div_center py-3 ls_bg1">
                    <div class="x_div_center" style="width:180px"><span class="fen-set1">현재 담당</span></div>
                    <div class="w-100"><span class="fen-set2"> {{ recentCnslrNm }} </span></div>
                </div>

                <div class="y_div_blank1rem" style="margin-bottom:10px"></div>

                <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#4e0000;">
                    <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">단계</span></div>
                    <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">담당자</span></div>
                    <div class="x_div_center" style="width:50%"><span class="fkr-set2 fc-w1">상담일시</span></div>
                </div>

                <div class="x_div_center ls_bg1" style="height:55px; cursor: pointer;" v-for="(task, index) in taskList" :key="index" @click="moveToTask(task.taskId, index)">
                    <div class="x_div_center br_1" style="width:25%"><span class="fkr-set2" :style="'color:' + task.sttsColor"> {{ 'TASK' + (index + 1) }} </span></div>
                    <div class="x_div_center br_1" style="width:25%"><span class="fkr-set2"> {{ task.cnslrNm }} </span></div>
                    <div class="x_div_center" style="width:50%"><span class="fen-set2" :style="task.scheduledContactTimeForToday && index+1 == taskList.length && task.sttsColor != 'green'? 'color:lightgrey' : ''">
                         {{ task.recentContactTime? task.recentContactTime.split('T')[0] + ' | ' + task.recentContactTime.split('T')[1] : 
                         (task.scheduledContactTimeForToday && index+1 == taskList.length? task.scheduledContactTimeForToday.split('T')[0] + ' (예정)' : '') }} 
                         </span></div>
                </div>

                <div class="x_div_center pt-4">
                    <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="moveToEdit()">수정</button></div>
                    <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" onclick="location.href='/panda/prj-list'">목록</button></div>
                    <div class="dropdown no-arrow" v-if="user.info.cnslrLv == 1 && stts != 4">
                        <div class="px-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><button class="fkr-set1" style="width:90px; height:37px; background-color:#c00; border:0; border-radius:4px; color:#fff;">종료<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                        <div class="dropdown-menu" style="width:120px; margin:18px 0 0 -50px; z-index: 1;">
                            <a class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9; height:40px" @click="closePrj(3)"><span class="fen-set1 rc-2">계약 완료</span></a>
                            <a class="dropdown-item x_div_center py-2" style="height:40px"><span class="fen-set1 rc-2" @click="closePrj(4)">프로젝트 종료</span></a>
                            <a class="dropdown-item x_div_center py-2" style="border-top:1px solid #d9d9d9; height:40px" @click="closePrj(5)"><span class="fen-set1 rc-2" style="color:#c00">프로젝트 삭제</span></a>
                        </div>
                    </div>
                    <div class="px-2" v-if="user.info.cnslrLv == 1 && stts == 4"><button class="fkr-set1" style="width:90px; height:37px; background-color:#4e0000; border:0; border-radius:4px; color:#fff;" @click="moveToTaskEdit()">재시작</button></div>
                </div>
                <div class="x_div_center pt-4">
                    <div class="px-2"><button class="fkr-set1" style="width:90px; height:27px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="switchToPrj(0)">이전</button></div>
                    <div class="px-2"><button class="fkr-set1" style="width:90px; height:27px; opacity: 0;"></button></div>
                    <div class="px-2"><button class="fkr-set1" style="width:90px; height:27px; background-color:#4e0000; border:0; border-radius:4px; color:#fff;" @click="switchToPrj(1)">다음</button></div>
                </div>
            </div>

        </div>
        <!-- } mypage 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'PrjPage',
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobileDevice: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fetching: true,
            submitting: false,
            myList: false,
            prjList: [],
            sortOption: [0,0,0,0],
            currentPage: 1,
            numOfPage: 0,
            pageSize: 10,
            ctgry: 0,
            prevPrjId: null,
            nextPrjId: null,
            searchWord: null,
            customerNm: null,
            phone: null,
            phoneDashed: '',
            email: null,
            visitChannel: null,
            usage: null,
            addr: null,
            maplink: '',
            totalGroundArea: null,
            totalGroundAreaPy: null,
            floors: null,
            buildScheduleYear: null,
            buildScheduleMonth: null,
            buildScheduleMonthDp: '',
            structure: null,
            expectedCost: null,
            preferredContactTime: null,
            etc: null,
            recentContactTime: null,
            recentCnslrNm: null,
            stts: null,
            status: '',
            sttsColor: '',
            taskList: []
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }

        if(!this.user.info.cnslrLv) {
            alert('접근권한이 없습니다.');
            location.href = '/';
        }

        const rtnval = await Api.post('/api/v1/primprj/find/primprj', {primPrjId: this.$route.params.prjId});
        if(rtnval.result){
            this.customerNm = rtnval.data.customerNm;
            this.phone = rtnval.data.phone;
            this.email = rtnval.data.email;
            this.visitChannel = rtnval.data.visitChannel;
            this.usage = rtnval.data.usage;
            this.addr = rtnval.data.addr;
            this.totalGroundArea = rtnval.data.totalGroundArea;
            this.floors = rtnval.data.floors;
            this.buildScheduleYear = rtnval.data.buildScheduleYear;
            this.buildScheduleMonth = rtnval.data.buildScheduleMonth;
            this.structure = rtnval.data.structure;
            this.expectedCost = rtnval.data.expectedCost;
            this.preferredContactTime = rtnval.data.preferredContactTime;
            this.etc = rtnval.data.etc;
            this.recentContactTime = rtnval.data.recentContactTime;
            this.recentCnslrNm = rtnval.data.recentCnslrNm;
            this.stts = rtnval.data.stts;
            this.taskList = rtnval.data.taskList;
        }
        else {
            alert(rtnval.errormsg);
        }

        if(sessionStorage.getItem('ctgry')) {
            this.ctgry = parseInt(sessionStorage.getItem('ctgry'));
            // this.pageSize = parseInt(sessionStorage.getItem('pageSize'));
            this.currentPage = sessionStorage.getItem('currentPage')? parseInt(sessionStorage.getItem('currentPage')) : 1;
            this.searchWord = sessionStorage.getItem('searchWord') == 'null'? null : sessionStorage.getItem('searchWord');
            this.myList = sessionStorage.getItem('myList') == 'true'? true : false;
            var sortOption = sessionStorage.getItem('sortOption')? sessionStorage.getItem('sortOption').split(',') : [0,0,0,0];
            for(var i=0; i<4; i++) {
                this.sortOption[i] = parseInt(sortOption[i]);
            }
        }

        await this.fetchPrjList(this.currentPage);
        if(this.prjList.length) {
            var index = 0;
            this.prjList.forEach((v, i) => {
                if(v.primPrjId == this.$route.params.prjId) {
                    index = i;
                }
            });
            if(index == 0) {
                this.nextPrjId = this.prjList[index+1].primPrjId;
                if(this.currentPage >1) {
                    await this.fetchPrjList(this.currentPage-1);
                    if(this.prjList.length) {
                        this.prevPrjId = this.prjList[this.pageSize-1].primPrjId;
                    }
                }
            }
            else if(index == this.pageSize-1) {
                this.prevPrjId = this.prjList[index-1].primPrjId;
                if(this.currentPage < this.numOfPage) {
                    await this.fetchPrjList(this.currentPage+1);
                    if(this.prjList.length) {
                        this.nextPrjId = this.prjList[0].primPrjId;
                    }
                }
            }
            else {
                this.nextPrjId = index < this.prjList.length-1? this.prjList[index+1].primPrjId : null;
                this.prevPrjId = index > 0? this.prjList[index-1].primPrjId : null;
            }
        }

        this.fetching = false;

        var prjNmHeadArray = this.addr? this.addr.split(' ').filter((token) => token[token.length-1] == '시' || token[token.length-1] == '군') : null
        this.prjNm = prjNmHeadArray? prjNmHeadArray[0] + ' ' + this.customerNm : this.customerNm;

        const phone = this.phone;
        if(phone){
            if(phone.length == 10) {
                this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
            }
            else{
                this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
            }
        }

        this.maplink = this.isMobileDevice? 'https://m.map.naver.com/search2/search.naver?query=' + this.addr + '#/map' : 'https://map.naver.com/search?query=' + this.addr

        this.totalGroundAreaPy = parseInt(this.totalGroundArea / 3.3);

        switch(this.stts) {
            case 1 : this.sttsColor = '#337ab7'; this.status = '상담중';
                break;
            case 2 : this.sttsColor = 'orange'; this.status = '상담중';
                break;
            case 3 : this.sttsColor = 'green'; this.status = '계약';
                break;
            case 4 : this.sttsColor = 'salmon'; this.status = '종료';
                break;
        }
        
        if(this.buildScheduleMonth %1 == 0) {
            this.buildScheduleMonthDp = parseInt(this.buildScheduleMonth) + '월';
        }
        else {
            switch(this.buildScheduleMonth + '') {
                case '3.5' : this.buildScheduleMonthDp = '1분기'
                    break;
                case '6.5' : this.buildScheduleMonthDp = '2분기'
                    break;
                case '9.5' : this.buildScheduleMonthDp = '3분기'
                    break;
                case '12.5' : this.buildScheduleMonthDp = '4분기'
                    break;
                case '6.9' : this.buildScheduleMonthDp = '상반기'
                    break;
                case '12.9' : this.buildScheduleMonthDp = '하반기'
                    break;
            }
        }

        for(var i=0; i<this.taskList.length; i++) {
        // this.taskList.forEach((task) => {
            var task = this.taskList[i];
            const prevTask = i == 0? null : this.taskList[i-1];
            
            if(!task.recentContactTime && !task.recallYn) {
                task.sttsColor = '#337ab7';
            }
            else if(!task.recentContactTime && task.recallYn) {
                task.sttsColor = 'orange';
            }
            if(task.nextCnslr) {
                task.sttsColor = 'green';
            }

            task.scheduledContactTimeForToday = prevTask? prevTask.scheduledContactTime : null;
        }

        if(this.$route.query.nextCnslrId == '-999') {
            alert("하단의 '종료' 버튼을 눌러서 프로젝트 종료 옵션을 선택하세요.")
        }
    },
    methods: {
        moveToEdit() {
            location.href = '/panda/prj-edit/' + this.$route.params.prjId;
        },
        moveToTask(taskId, index) {
            var taskIdList = [];
            this.taskList.forEach((v) => {
                taskIdList.push(v.taskId);
            })
            sessionStorage.setItem('taskIdList', taskIdList);
            location.href = '/panda/task/' + taskId + '?order=' + (index+1);
        },
        async closePrj(option) {
            for(var i=0; i<this.taskList.length; i++) {
                var task = this.taskList[i];
                if(task.sttsColor != 'green') {
                    alert('마지막 상담을 종료시킨 후 프로젝트 종료를 진행해주세요.');
                    return;
                }
            }

            var confirmMsg = '';
            switch(option) {
                case 3: confirmMsg = '계약이 확실히 성사된 프로젝트일 경우에만 진행해주세요.\n계약 완료되었습니까?';
                    break;
                case 4: confirmMsg = '프로젝트를 종료하시겠습니까?';
                    break;
                case 5: confirmMsg = '프로젝트를 삭제하면 목록에 더 이상 표시되지 않습니다.\n삭제하시겠습니까?';
                    break;
            }
            if(confirm(confirmMsg)){
                this.submitting = true;
                const returnvalue = await Api.post('/api/v1/primprj/update/primprj', {
                    primPrjId: this.$route.params.prjId,
                    phone: this.phone,
                    email: this.email,
                    stts: option
                });
                if(returnvalue.result){
                    alert('후보프로젝트가 종료되었습니다.');
                    location.href = '/panda'
                }
                else {
                    alert(returnvalue.errormsg);
                }
                this.submitting = false;
            }
        },
        moveToTaskEdit() {
            const newPrimPrjObj = {
                primPrjId: this.$route.params.prjId,
                customerNm: this.customerNm,
                phone: this.phone,
                email: this.email,
                usage: this.usage,
                addr: this.addr,
                totalGroundArea: this.totalGroundArea,
                floors: this.floors,
                buildScheduleYear: this.buildScheduleYear,
                buildScheduleMonth: this.buildScheduleMonth,
                structure: this.structure,
                expectedCost: this.expectedCost,
                preferredContactTime: this.preferredContactTime,
                etc: this.etc
            }
            sessionStorage.setItem('newPrimPrjObj', JSON.stringify(newPrimPrjObj));
            alert(this.taskList.length +1 + '번째 상담을 작성합니다.');
            location.href = '/panda/task?order=' + (this.taskList.length +1)
        },
        switchToPrj(next) {
            if(next) {
                if(this.nextPrjId) {
                    location.href = '/panda/prj/' + this.nextPrjId;
                }
                else {
                    alert('목록의 마지막 프로젝트입니다.');
                }
            }
            else {
                if(this.prevPrjId) {
                    location.href = '/panda/prj/' + this.prevPrjId;
                }
                else {
                    alert('목록의 첫번째 프로젝트입니다.');
                }
            }
        },
        async fetchPrjList(page) {
            const rtnvalue = await Api.post('/api/v1/primprj/list/primprj', {
                sortColumn: this.sortOption.indexOf(1) >0? this.sortOption.indexOf(1) : (this.sortOption.indexOf(2) >0? this.sortOption.indexOf(2) : 0),
                asc: this.sortOption.indexOf(1) >0? false : (this.sortOption.indexOf(2) >0? true : false),
                usrId: this.myList? this.user.id : null,
                ctgry: this.ctgry,
                pageSize: this.pageSize,
                page: page,
                searchWord: this.searchWord
            });
            if(rtnvalue.result){
                this.numOfPage = Math.ceil(rtnvalue.data.count / this.pageSize);
                this.prjList = rtnvalue.data.findPrimPrjDtoList;
            }
            else {
                alert(rtnvalue.errormsg);
            }
        }
    }
}
</script>

<style scoped>
.ls_bg1 {
    padding: 5px 0 5px 0 !important;
}
.ls_bg1:hover {
    background-color: #fff !important;
}
</style>