<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">실시간 견적산출</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>견적내역 등록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>고객명</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="customerNm"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>제목</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:250px; height:36px; margin-left: 20px;" v-model="sbjct"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>견적일시</strong></span></td>
                  <td class="text-start" width="70%"><span class="ff-eng1" style="font-size:12pt; margin-left:25px">{{estmtDt? estmtDt.split('T')[0] : ''}}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>건축사양목록</strong></span></td>
                  <td class="text-center" width="70%" style="padding: 20px">
                    <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                        <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">사양명</span></div>
                        <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">물량</span></div>
                        <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">분류</span></div>
                        <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">자재선택</span></div>
                    </div>

                    <div v-for="(bucket, index) in mtrlAmntBuckets" :key="index">
                      <div class="x_div_center ls_bg1" style="height:55px" v-for="(mtrlAmnt, kintex) in bucket" :key="kintex">
                          <div class="x_div_center px-1" style="width:25%"><span class="ff-kor1" style="font-size:10pt">{{ kintex? '' : mtrlAmnt.archiSpcNm }}</span></div>
                          <div class="x_div_center px-1" style="width:25%; display:flex">
                            <input type="text" class="form-control fkr-set2" style="width:120px; height:36px; margin-left: 20px;" v-model="mtrlAmnt.amnt" :disabled="kintex > 0">
                            <span class="ff-kor1" style="font-size:10pt; margin-left: 10px">{{ mtrlAmnt.unit }}</span>
                          </div>
                          <div class="x_div_center px-1" style="width:25%; display:flex">
                            <span class="ff-kor1" style="font-size:10pt; margin-right: 5px" v-for="(ctgryType, syntax) in mtrlAmnt.ctgryTypeList" :key="syntax">{{ ctgryType.mtrlCtgryTypeNm + '/' }}</span>
                          </div>
                          <div class="x_div_center px-1" style="width:25%"><span class="ff-eng1" style="font-size:12pt">{{ mtrlAmnt.stdMtrlNm }}</span></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>골조비</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="framePrice"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>견적</strong></span></td>
                  <td class="text-start" width="70%"><span class="ff-eng1" style="font-size:10pt; margin-left:25px">{{ Number(estmtPrice).toLocaleString() }}</span></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>비고</strong></span></td>
                  <td class="text-center" width="70%"><textarea class="form-control fkr-set2" style="margin: 20px; width: 95%" v-model="etc"></textarea></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>비공개 비고</strong></span></td>
                  <td class="text-center" width="70%"><textarea class="form-control fkr-set2" style="margin: 20px; width: 95%; background-color: rgb(255, 243, 245);" v-model="etcClssfd"></textarea></td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveEstimation()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
            <div v-if="$route.params.estmtId > 0" class="py-2"><button class="fkr-set2 fs-095" @click="deleteEstimation()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="moveToList()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">{{ $route.params.partnerId > 0? '목록' : '취소' }}</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "EstimationEdit",
  components: {
		AdminLNB
	},
  watch: {
      framePrice(val){
          const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
          // 한글, 영문 체크
          if(reg.exec(val)!==null) val = String(val).replace(/[^0-9]/g,'');
          this.framePrice = String(val).replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          // .... 만 입력하게 될 경우 체크
          if(isNaN(parseFloat(val))) this.framePrice = '';
      },
  },
  data(){
    return {
      customerNm: '',
      sbjct: '',
      estmtDt: null,
      framePrice: 0,
      estmtPrice: 0,
      etc: '',
      etcClssfd: '',
      mtrlAmntBuckets: [],
      mtrlCtgryTypesMarker: [],
      mtrlAmntAddList: [],
      mtrlAmntUpdateList: [],
      mtrlAmntDeleteList: [],
      fetching: true,
      submitting: false
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const returnval = await Api.get('/api/v1/mtrl/list/mtrlbyarchispc');
    // const returnval = {
    //   result: true,
    //   data: {
    //     resultList : [
    //       {archiSpcId: 1, archiSpcNm: '총창길이', unit: '자', stdMtrlList: [
    //         {stdMtrlNm: '살라만더', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 1, mtrlCtgryTypeNm: '창호'}, order: 1}]},
    //         {stdMtrlNm: '와이번', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 1, mtrlCtgryTypeNm: '창호'}, order: 1}]},
    //         {stdMtrlNm: '드래곤', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 1, mtrlCtgryTypeNm: '창호'}, order: 1}]}
    //       ]},
    //       {archiSpcId: 2, archiSpcNm: '벽량', unit: '㎡', stdMtrlList: [
    //         {stdMtrlNm: '실크벽지', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 2, mtrlCtgryTypeNm: '마감'}, order: 1}, {mtrlCtgryType: {mtrlCtgryTypeId: 3, mtrlCtgryTypeNm: '내장'}, order: 2}]},
    //         {stdMtrlNm: '대리석', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 2, mtrlCtgryTypeNm: '마감'}, order: 1}, {mtrlCtgryType: {mtrlCtgryTypeId: 3, mtrlCtgryTypeNm: '내장'}, order: 2}]},
    //         {stdMtrlNm: '페인트', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 2, mtrlCtgryTypeNm: '마감'}, order: 1}, {mtrlCtgryType: {mtrlCtgryTypeId: 3, mtrlCtgryTypeNm: '내장'}, order: 2}]},
    //         {stdMtrlNm: '알루미늄판넬', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 2, mtrlCtgryTypeNm: '마감'}, order: 1}, {mtrlCtgryType: {mtrlCtgryTypeId: 4, mtrlCtgryTypeNm: '외장'}, order: 2}]},
    //         {stdMtrlNm: '세라믹사이딩', mtrlCtgryList: [{mtrlCtgryType: {mtrlCtgryTypeId: 2, mtrlCtgryTypeNm: '마감'}, order: 1}, {mtrlCtgryType: {mtrlCtgryTypeId: 4, mtrlCtgryTypeNm: '외장'}, order: 2}]}
    //       ]}
    //     ]
    //   }
    // }
    ///////////////////////////////////////////////////////////////////////////////////
    if(returnval.result) {
      returnval.data.findArchiSpcMtrlsDtoList.forEach((spec) => {
        spec.findStdMtrlDtoList.forEach((mtrl) => {
          mtrl.listMtrlCtgryList = mtrl.listMtrlCtgryList.sort((a,b) => a.ctgryOrder - b.ctgryOrder);
          if(!this.mtrlCtgryTypesMarker[mtrl.listMtrlCtgryList[mtrl.listMtrlCtgryList.length-1].mtrlCtgryTypeId]){
            this.mtrlCtgryTypesMarker[mtrl.listMtrlCtgryList[mtrl.listMtrlCtgryList.length-1].mtrlCtgryTypeId] = true;
            if(!this.mtrlAmntBuckets[spec.archiSpcId]) {
              this.mtrlAmntBuckets[spec.archiSpcId] = [];
            }
            this.mtrlAmntBuckets[spec.archiSpcId].push({
              mtrlAmntId: null,
              estmtId: null, 
              archiSpcId: spec.archiSpcId, 
              archiSpcNm: spec.archiSpcNm, 
              unit: spec.unit,
              stdMtrlId: null, 
              stdMtrlNm: '', 
              lastMtrlCtgryTypeId: mtrl.listMtrlCtgryList[mtrl.listMtrlCtgryList.length-1].mtrlCtgryTypeId,
              amnt: 0, 
              ctgryTypeList: mtrl.listMtrlCtgryList
            });
          }
        })
      });

      if(this.$route.params.estmtId > 0) {
        const returnvalue = await Api.get('/api/v1/estmt/find/estmt?estmtId=' + this.$route.params.estmtId);
        if(returnvalue.result) {
          this.customerNm = returnvalue.data.customerNm;
          this.sbjct = returnvalue.data.sbjct;
          this.estmtDt = returnvalue.data.estmtDt;
          this.framePrice = returnvalue.data.framePrice;
          this.estmtPrice = returnvalue.data.estmtPrice;
          this.etc = returnvalue.data.etc;
          this.etcClssfd = returnvalue.data.etcClssfd;

          returnvalue.data.mtrlAmntDtoList.forEach((item) => {
            this.mtrlAmntBuckets[item.archiSpcId].forEach((bucket) => {
              if(bucket.lastMtrlCtgryTypeId == item.lastMtrlCtgryTypeId) {
                bucket.mtrlAmntId = item.mtrlAmntId;
                bucket.estmtId = this.$route.params.estmtId;
                bucket.stdMtrlId = item.stdMtrlId;
                bucket.stdMtrlNm = item.stdMtrlNm;
                bucket.amnt = item.amnt;
              }
            })
          })
        }
        else {
          alert(returnvalue.errormsg)
        }
      }
    }
    else {
        alert(returnval.errormsg);
    }
    
    this.fetching = false;
  },

  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'N'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async saveEstimation() {
      if(!this.customerNm && !this.sbjct) {
        alert('고객명과 제목 둘 중 하나의 정보를 입력하세요.');
        return;
      }
      if(!this.framePrice || Number(this.framePrice.replace(/[^\d]+/g, '')) <= 0) {
        alert('골조비는 필수 입력사항입니다.');
        return;
      }

      this.submitting = true;

      var mtrlAmntAddList = [];
      var mtrlAmntUpdateList = [];
      this.mtrlAmntBuckets.forEach((bucket) => {
        if(bucket) {
          var bucketAmnt = 0;
          bucket.forEach((mtrlAmnt, index) => {
            if(index == 0) {
              bucketAmnt = mtrlAmnt.amnt;
            }
            else {
              mtrlAmnt.amnt = bucketAmnt;
            }

            if(mtrlAmnt.mtrlAmntId) {
              mtrlAmntUpdateList.push(mtrlAmnt)
            }
            else {
              mtrlAmntAddList.push(mtrlAmnt)
            }
          })
        }
      })

      const rtnvalue = await Api.post('/api/v1/estmt/' + (this.$route.params.estmtId > 0? 'update' : 'set') + '/estmt', {
        estmtId: this.$route.params.estmtId > 0? this.$route.params.estmtId : null,
        customerNm: this.customerNm,
        sbjct: this.sbjct,
        framePrice: Number(this.framePrice.replace(/[^\d]+/g, '')),
        etc: this.etc,
        etcClssfd: this.etcClssfd,
        mtrlAmntAddList: mtrlAmntAddList,
        mtrlAmntUpdateList: mtrlAmntUpdateList
      });
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.href = '/admin/estmt/list';  
      }
      else {
        alert(rtnvalue.errormsg)
      }
      this.submitting = false;
    },

    async deleteEstimation() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        const rtnvalue = await Api.get('/api/v1/estmt/delete/estmt?estmtId=' + this.$route.params.estmtId)
        if(rtnvalue.result) {
          alert('정상적으로 삭제되었습니다.')
          location.href = '/admin/estmt/list';  
        }
        else {
          alert(rtnvalue.errormsg)
        } 
      }
    },

    moveToList(){
      location.href = '/admin/estmt/list';  
    }
  }
}
</script>