<template>
  <div class="x_div_center">
    <div class="pg_m_set" style="padding: 20px">
      <i class='h3 fa fa-spinner fa-spin'></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProxyPage",
  props: {
      user: {
          type: Object,
          default: null
      }
  },
  async created() {
    if(!this.user){
      alert('로그인이 필요합니다.');
      window.close();
    }
    else {
      if(!this.user.info.adminYn || this.user.info.adminYn == 'N') {
        alert('접근권한이 없습니다.');
        window.close();
      }
      else {
        if(this.$route.query.redirect) {
          location.href = decodeURIComponent(this.$route.query.redirect);
        }
        else {
          if(this.user.info.adminYn == 'S') {
            location.href = '/admin/usr/usr-mng';
          }
          else if(this.user.info.adminRole) {
            this.user.info.adminRole.menuPolicyList.forEach((policy) => {
              if(policy.isMainPage) {
                location.href = policy.menuPage.routePath;
              }
            })
          }
          else {
            alert('관리자 메뉴접근권한을 획득 후 접속바랍니다.');
          }
        }
      }
    }
  }
}
</script>

