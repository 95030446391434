<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">후보프로젝트 조회</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <div class="x_div_center" style="width:270px; height:34px; border:1px solid #d6d6d6; border-radius:4px; margin-left: 20px; margin-right: 20px;">
              <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findPrj()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              <div class="x_div_center">
                  <Datepicker :endDate="findEndDtInput" @update-date="setStrtDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:30px; border-radius:4px; border:1px solid #c9c9c9" placeholder="시작일자"/>
                  <span>&nbsp;~&nbsp;</span>
                  <Datepicker :startDate="findStrtDtInput" @update-date="setEndDt" class="form-control fkr-set2 fc-w5 text-center" style="width:95px; height:30px; border-radius:4px; border:1px solid #c9c9c9" placeholder="종료일자"/>
              </div>
            </div>
            <select class="custom-select ff-eng1 fs-085" v-model="ascending" @change="switchFilter()" style="width:120px; height:37px; margin-right: 20px">
              <option value=true>날짜순</option>
              <option value=false>최신순</option>
            </select>
            <span class="fkr-set2 fs-10" style="padding-top: 5px">&nbsp;검색결과: {{ numOfPrj }} 건</span>
            <div class="ps-2" style="margin-left: auto; margin-right: 20px"><button class="fkr-set2 fs-095" @click="exportExcel()" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">Excel 다운로드</button></div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">주소</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">용도</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">고객 이름</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">고객 이메일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">전화번호</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">유입경로</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center" style="width:30%"><span class="fkr-set2 fs-9">{{item.addr}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.usage}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.crtDt.split('T')[0]}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.customerNm}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.email}}</span></div>
                  <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9">{{item.phone}}</span></div>
                  <div class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{item.visitChannel}}</span></div>
                </div>
              </div>



            </div>



          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "PrimPrjMng",
  components: {
		AdminLNB, Datepicker
	},
  data(){
    return {
        prjList:[],
        numOfPrj: 0,
        findStrtDtInput: '',
        findEndDtInput: '',
        ascending: true,
        fetching: false
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async findPrj(){
      if(!this.findStrtDtInput || !this.findEndDtInput){
        alert('조회기간을 선택하세요.');
        return;
      }

      this.fetching = true;
      var rtnval = await Api.post('/api/v1/primprj/list/primprj/daterange', {startDt: this.findStrtDtInput, endDt: this.findEndDtInput, asc: this.ascending});
      if(rtnval.result){
        this.prjList = rtnval.data;
        this.numOfPrj = rtnval.data.length;
      }
      else {
        alert(rtnval.errormsg);
      }
      this.fetching = false;
    },
    
    setStrtDt(date) {
        this.findStrtDtInput = new Date(date).toISOString();
    },
    setEndDt(date) {
        var d = new Date(date);
        d.setDate(d.getDate() +1);
        this.findEndDtInput = d.toISOString();
    },
    switchFilter() {
      this.findPrj();
    },
    exportExcel(){ 
      const primPrjListRendered = [
        {
            주소: null,
            용도: null,
            등록일: null,
            상태: null,
            이름: '이름',
            이메일: '이메일',
            전화번호: '전화번호',
            유입경로: null,
            연락선호시간: null,
            최근연락일자: null,
            최근상담자: null,
            '연면적(㎡)': null,
            층수: null,
            건축일정: null,
            구조: null,
            '예산(억원)': null,
            비고: null
        }
      ];

      this.prjList.forEach((item) => {
        var status = ''

        switch(item.stts) {
            case 1 : status = '상담중';
                break;
            case 2 : status = '상담중';
                break;
            case 3 : status = '계약';
                break;
            case 4 : status = '종료';
                break;
            case 5 : status = '삭제';
                break;
        }

        var buildScheduleMonthDp = '';
        if(item.buildScheduleMonth %1 == 0) {
            buildScheduleMonthDp = parseInt(item.buildScheduleMonth) + '월';
        }
        else {
            switch(item.buildScheduleMonth + '') {
                case '3.5' : buildScheduleMonthDp = '1분기'
                    break;
                case '6.5' : buildScheduleMonthDp = '2분기'
                    break;
                case '9.5' : buildScheduleMonthDp = '3분기'
                    break;
                case '12.5' : buildScheduleMonthDp = '4분기'
                    break;
                case '6.9' : buildScheduleMonthDp = '상반기'
                    break;
                case '12.9' : buildScheduleMonthDp = '하반기'
                    break;
            }
        }

        const prj = {
            주소: item.addr,
            용도: item.usage,
            등록일: item.crtDt.split('T')[0],
            상태: status,
            이름: item.customerNm,
            이메일: item.email,
            전화번호: item.phone,
            유입경로: item.visitChannel,
            연락선호시간: item.preferredContactTime,
            최근연락일자: item.recentContactTime.split('T')[0] + '   ' + item.recentContactTime.split('T')[1],
            최근상담자: item.recentCnslrNm,
            '연면적(㎡)': item.totalGroundArea,
            층수: item.floors,
            건축일정: item.buildScheduleYear + '년 ' + buildScheduleMonthDp,
            구조: item.structure,
            '예산(억원)': item.expectedCost,
            비고: item.etc? item.etc.replace(/<br>/g, '\n') : ''
        }
        primPrjListRendered.push(prj);
      })

      //workbook 생성
      var wb = window.XLSX.utils.book_new();

      //시트 만들기 
      var newWorksheet = window.XLSX.utils.json_to_sheet(primPrjListRendered);

      //Header 일부 Cell 병합
      newWorksheet['!merges'] = [
        { s: { c: 0, r: 0 }, e: { c: 0, r: 1 } }, //주소
        { s: { c: 1, r: 0 }, e: { c: 1, r: 1 } }, //용도
        { s: { c: 2, r: 0 }, e: { c: 2, r: 1 } }, //등록일
        { s: { c: 3, r: 0 }, e: { c: 3, r: 1 } }, //상태
        
        { s: { c: 7, r: 0 }, e: { c: 7, r: 1 } }, //유입경로
        { s: { c: 8, r: 0 }, e: { c: 8, r: 1 } }, //연락선호시간
        { s: { c: 9, r: 0 }, e: { c: 9, r: 1 } }, //최근연락일자
        { s: { c: 10, r: 0 }, e: { c: 10, r: 1 } }, //최근상담자
        { s: { c: 11, r: 0 }, e: { c: 11, r: 1 } }, //연면적
        { s: { c: 12, r: 0 }, e: { c: 12, r: 1 } }, //층수
        { s: { c: 13, r: 0 }, e: { c: 13, r: 1 } }, //건축일정
        { s: { c: 14, r: 0 }, e: { c: 14, r: 1 } }, //구조
        { s: { c: 15, r: 0 }, e: { c: 15, r: 1 } }, //예산
        { s: { c: 16, r: 0 }, e: { c: 16, r: 1 } },  //비고

        { s: { c: 4, r: 0 }, e: { c: 6, r: 0 } }, //고객정보
      ];

      window.XLSX.utils.sheet_add_aoa(newWorksheet, [[,,,,'건축주 정보']], {origin: 0});

      //Header영역 스타일 지정
      Object.entries(primPrjListRendered[0]).forEach((o, i) => {
        var styleHeader = {
          font: {
            sz: 12,
            bold: true
          },
          alignment: {
            vertical: "center",
            horizontal: "center"
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "defbff" }
          },
          border: {
            left: {style: "thin", color: {rgb: "b1b1b1"}},
            right: {style: "thin", color: {rgb: "b1b1b1"}},
          }
        }
        const cell0 = newWorksheet[window.XLSX.utils.encode_cell({r: 0, c: i})];
        const cell1 = newWorksheet[window.XLSX.utils.encode_cell({r: 1, c: i})];
        cell0.s = styleHeader;
        cell1.s = styleHeader;
        if(i >= 4 && i <=6) {
          cell0.s.border.bottom = {style: "thin", color: {rgb: "b1b1b1"}};
          cell1.s.border.bottom = {style: "thin", color: {rgb: "b1b1b1"}};
        }
      })

      //데이터 서식 지정
      for(var j=0; j < primPrjListRendered.length; j++) {
        Object.entries(primPrjListRendered[j]).forEach((o, i) => {
          const cell = newWorksheet[window.XLSX.utils.encode_cell({c: i, r: j+1})];
          if([4,5,6].indexOf(i) >=0 && j==0) {
            ;
          }
          else {
            cell.s = {
              alignment: {
                vertical: "center"
              }
            }
            if([2,9].indexOf(i) >=0) {
              cell.s.alignment.horizontal = "center";
            }
            if([4,5,6].indexOf(i) >=0 && j>0) {
              cell.s.alignment.horizontal = "center";
            }
          }
        });
      }

      //열간격 설정
      var maxLengthForCols = [];
      Object.entries(primPrjListRendered[1]).forEach(([k, v], i) => {
        maxLengthForCols.push({wch: k? k.length : 0});
      });

      for(var j=0; j < primPrjListRendered.length; j++) {
        Object.entries(primPrjListRendered[j]).forEach(([k, v], i) => {
          if(v) {
            maxLengthForCols[i].wch = String(v).length > maxLengthForCols[i].wch? String(v).length : maxLengthForCols[i].wch;
          }
        });
      }
      maxLengthForCols.forEach((v,i) => {
        if(i+1 < maxLengthForCols.length) {
          v.wch *=1.5;
        }
        else {
          v.wch = 30;
        }
      });
      newWorksheet["!cols"] = maxLengthForCols;
      
      //workbook에 새로만든 워크시트에 이름을 주고 붙인다.  
      window.XLSX.utils.book_append_sheet(wb, newWorksheet, 'Sheet1');

      //엑셀 파일 만들기 
      var wbout = window.XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

      //엑셀 파일 내보내기 
      window.saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), 'PrimitiveProject_List_' + new Date().toISOString() + '.xlsx');
    },

    s2ab(s) { 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }
  }
}
</script>

