<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">회원관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">총 회원: {{ numOfUsr }} 명</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10명</option>
                <option value="20">페이지당 20명</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="userRoleFilter" @change="findUsrWithFilter()" style="width:120px; height:37px; margin-left: 20px">
              <option value=''>전체</option>
              <option value='OW'>고객</option>
              <option value='DS'>설계사</option>
              <option value='MF'>제조사</option>
              <option value='IN'>인테리어사</option>
              <option value='DE'>운송사</option>
              <!-- <option value='IS'>설치사</option> -->
              <option value='CT'>시공사</option>
              <option value='SL'>사업파트너</option>
              <option value='M3'>M3관계자</option>
              <option value='OT'>기타</option>
            </select>
            <div class="ps-2" v-if="isGA && userRoleFilter == 'M3'" style="margin-left: auto; padding-left: 290px !important;"><button class="fkr-set2 fs-095" @click="setPositions();" style="width:100px; height:35px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;">직책 저장</button></div>
            <div class="ps-2" v-if="isGA && !editingRoles" :style="'margin-left: auto; padding-left: ' + (userRoleFilter == 'M3'? '10px' : '400px') + ' !important;'"><button class="fkr-set2 fs-095" @click="editingRoles = true;" style="width:120px; height:35px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;">회원유형 편집</button></div>
            <div class="ps-2" v-else-if="isGA && editingRoles" :style="'margin-left: auto; padding-left: ' + (userRoleFilter == 'M3'? '10px' : '380px') + ' !important;'"><i class="fa fa-undo" style="cursor:pointer; margin-right:10px" @click="editingRoles = false"/><button class="fkr-set2 fs-095" @click="saveModification()" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">변경사항 저장</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="userRoleFilter = ''; findUsrWithFilter()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="userRoleFilter = ''; findUsrWithFilter()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="회원검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" :style="'width:' + (userRoleFilter == 'M3'? '20%' : '30%')"><span class="fkr-set1 fs-9">이메일</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">이름</span></div>
                <div class="x_div_center" style="width:10%" v-if="userRoleFilter == 'M3'"><span class="fkr-set1 fs-9">직책</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">연락처</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">유형</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">가입일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">대신 로그인</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in usrList" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" :style="'width:' + (userRoleFilter == 'M3'? '20%' : '30%')"><span class="fkr-set2 fs-9"><a href="#" @click="findUsrDtl(item.id)">{{item.email}}</a></span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.fullName}}</span></div>
                  <div class="x_div_center m_line" style="width:10%" v-if="userRoleFilter == 'M3'"><input type="text" class="form-control fa-kor-Noto" v-model="item.position" style="width:75px; height:27px; padding: 1px 0 1px 5px; margin:0"></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.phone}}</span></div>
                  <div class="x_div_center m_line" style="width:15%">
                    <span v-if="!editingRoles" class="fkr-set2 fs-9">{{renderUsrRole(item.usrRole)}}</span>
                    <select v-else class="custom-select ff-eng1 fs-085" v-model="item.usrRoleSelected" style="width:100px; height:27px; padding: 1px 0 1px 5px; margin:0">
                      <option :value="option.code" v-for="(option, index) in (item.usrType == 'PA'? roleOptionsPA : roleOptionsCU)" :key="index">{{ option.name }}</option>
                    </select>
                  </div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a href="javascript:void(0)" @click="zombieLogin(item.email)"><i class="fa fa-share-from-square"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; findUsrAll()" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import store from "@/store"
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "UsrMng",
  components: {
		AdminLNB
	},
  props: {
      user: {
          type: Object,
          default: null
      },
  },
  data(){
    return {
        fetching: false,
        searchWord: null,
        numOfUsr: '',
        usrList:[],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        userRoleFilter: '',
        editingRoles: false,
        isGA: false,
        roleOptionsCU : [
          {code: 'OW', name: '고객'},
          {code: 'M3', name: 'M3'}
        ],
        roleOptionsPA : [
          {code: 'DS', name: '설계사'},
          {code: 'MF', name: '제조사'},
          {code: 'IN', name: '인테리어사'},
          {code: 'DE', name: '운송사'},
          {code: 'IS', name: '설치사'},
          {code: 'CT', name: '시공사'},
          {code: 'SL', name: '사업파트너'},
          {code: 'OT', name: '기타'}
        ],
    }
  },
  created() {
    if(process.env.NODE_ENV !== 'production' || ((this.user.info.adminRole && this.user.info.adminRole.id == 1) || this.user.info.adminYn == 'S')) {
      this.isGA = true; 
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    await this.findUsrAll();
    this.setPaging();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async zombieLogin(email) {
      if(email != 'ops@m3.co.kr') {
        this.fetching = true;
        const result = await Api.post('/api/v1/user/login/zombie', {email: email});
        if(result.result){
          localStorage.removeItem('vuex');
          localStorage.removeItem("loginFlag");
          sessionStorage.clear();
          const rtnval = await Api.get('/api/v1/user/find/usr/info');
          if(rtnval.result){
              store.commit('setUserInfo', {id: result.data.id, info: rtnval.data})
              location.href = '/';
          }
        }
        else{
            alert(result.errormsg);
        }
        this.fetching = false;
      }
    },

    async findUsrAll(){
      this.fetching = true;
      this.usrList = [];
      var rtnval = await Api.post('/api/v1/admin/usr/mng/findAll/usrAll', {page: this.currentPage, window: this.pageSize, role: this.userRoleFilter? this.userRoleFilter : null, searchWord: this.searchWord});
      if(rtnval.result){
        this.usrList = rtnval.data.findAllUsrDtoList;
        this.usrList.forEach((usr) => {
          usr.usrRoleSelected = usr.usrRole;
        })
        this.numOfUsr = rtnval.data.count;
      }
      this.fetching = false;
    },
    
    async countUsrAll(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/findAll/count');
      if(rtnval.result){
        this.numOfUsr = rtnval.data;
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.numOfUsr / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        await this.findUsrAll();
        this.setPaging();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      await this.setPaging();
      this.findUsrAll();
    },

    async findUsrWithFilter() {
      await this.findUsrAll();
      this.currentPage = 1;
      this.setPaging();
    },

    renderUsrRole(usrRoleCd) { 
      var rsp = '';
      switch(usrRoleCd) {
        case 'OW' : rsp = '고객';
          break;
        case 'DS' : rsp = '설계사';
          break;
        case 'MF' : rsp = '제조사';
          break;
        case 'IN' : rsp = '인테리어사';
          break;
        case 'DE' : rsp = '운송사';
          break;
        case 'IS' : rsp = '설치사';
          break;
        case 'CT' : rsp = '시공사';
          break;
        case 'SL' : rsp = '사업파트너';
          break;
        case 'M3' : rsp = 'M3';
          break;
        case 'OT' : rsp = '기타';
          break;
      }
      return rsp;
    },

    async saveModification() {
      const request = [];
      this.usrList.forEach((usr) => {
        if(usr.usrRole != usr.usrRoleSelected) {
          request.push({usrId: usr.id, usrRole: usr.usrRoleSelected});
        }
      })
      if(request.length > 0) {
        this.fetching = true;
        var rtnval = await Api.post('/api/v1/admin/usr/mng/update/roles', request);
        if(rtnval.result){
          await this.findUsrAll();
          this.setPaging();
        }
        else {
          alert(rtnval.errormsg)
        }
        this.fetching = false;
        this.editingRoles = false;
      }
    },

    async setPositions() {
      const request = [];
      this.usrList.forEach((usr) => {
        request.push({usrId: usr.id, position: usr.position});
      })
      if(request.length > 0) {
        this.fetching = true;
        var rtnval = await Api.post('/api/v1/admin/usr/mng/update/positions', request);
        if(rtnval.result){
          await this.findUsrAll();
          this.setPaging();
        }
        else {
          alert(rtnval.errormsg)
        }
        this.fetching = false;
        this.editingRoles = false;
      }
    },

    async findUsrDtl(usrId){
      location.href='/admin/usr/usr-mng/' + usrId + '?p=' + this.currentPage + '&w=' + this.pageSize;
    }
  }
}
</script>

