<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">시스템관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>관리자 접근권한 목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">항목 수: {{ allowedList.length }} 개</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="roleFilter" @change="switchRole()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in roleList" :key="i" :value="item.id">{{ item.roleName }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setRole(0)" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="roleFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setRole(1)" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="roleFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeRole()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div v-if="roleFilter && editing" class="ps-2" style="margin-left: 20px;">
              <i class="fa fa-undo" style="cursor:pointer; margin-right:10px" @click="saveModification(false)"/><button class="fkr-set2 fs-095" @click="saveModification(true)" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">변경사항 저장</button>
            </div>
            <div v-if="roleFilter" class="ps-2" style="margin-left: auto; padding-left: 10px !important;"><button class="fkr-set2 fs-095" onclick="window.$('#addPage').modal('show');" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">권한페이지 추가</button></div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">메뉴페이지명</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">메뉴카테고리</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">메인페이지여부</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">제거</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in allowedListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9">{{item.menuPage.pageName}}</span></div>
                  <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9">{{item.menuPage.menuCategoryName}}</span></div>
                  <div class="x_div_center m_line" style="width:15%">
                    <span class="fkr-set2 fs-9">
                      <div class="custom-control custom-radio">
                        <input name="dsgnRadio" :checked="item.isMainPage" type="radio" :id="'radio'+index" @change="setMainPage(item)" class="custom-control-input">
                        <label :for="'radio'+index" class="custom-control-label"></label>
                      </div>
                    </span>
                  </div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><i class="fa fa-trash" :style="'cursor:pointer; ' + (item.menuPage.flagToDel? 'color: salmon' : '')" @click="editing = true; setDeleteFlag(item)"/></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="addPage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">접근권한을 허용할 페이지를 추가하세요.</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                  <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div class="w-100" v-else>
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">메뉴카테고리</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px; margin-left: 20px;" v-model="ctgryPageList">
                          <option v-for="(item, i) in ctgryList" :key="i" :value="item.pageList">{{ item.menuName }}</option>
                        </select>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">메뉴페이지</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <div class="table-responsive w-100" style="border:1px solid #c9c9c9; border-radius:8px; width:60% !important; margin-left: 20px;">
                          <table class="table-borderless w-100" style="table-layout:fixed; display:block; width:100% !important; max-height: 200px; overflow-y: auto;">
                            <tr v-for="(item, i) in ctgryPageList" :key="i" style="display: table; width:100% !important; height:40px; border-bottom:1px solid #c9c9c9;">
                              <td class="text-center" style="width:70%;"><span class="ff-kor1" style="font-size:10pt">{{item.pageName}}</span></td>
                              <td class="text-center" style="width:30%;"><span class="ff-kor1" style="font-size:10pt; cursor: pointer;" @click="addNewPage(item)"><i class="fas fa-plus-circle"></i></span></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">권한이 추가될 페이지</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <div class="table-responsive w-100" style="border:1px solid #c9c9c9; border-radius:8px; width:60% !important; margin-left: 20px;">
                          <table class="table-borderless w-100" style="table-layout:fixed; display:block; width:100% !important; max-height: 200px; overflow-y: auto;">
                            <tr v-for="(item, i) in pageNewList" :key="i" style="display: table; width:100% !important; height:40px; border-bottom:1px solid #c9c9c9;">
                              <td class="text-center" style="width:70%;"><span class="ff-kor1" style="font-size:10pt">{{item.menuPage.pageName}}</span></td>
                              <td class="text-center" style="width:30%;"><span class="ff-kor1" style="font-size:10pt; cursor: pointer;" @click="removeNewPage(item)"><i class="fa fa-trash"></i></span></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2"><button @click="cancelAddingPage()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">취소</span></button></div>
                <div class="px-2"><button @click="commitAddingPage()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">반영</span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "AdminRoleList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        searchWord: '',
        ctgryList: [],
        roleList: [],
        allowedList:[],
        allowedListOrig:[],
        allowedListPaged: [],
        ctgryPageList: [],
        pageNewList: [],
        pageSetList: [],
        pageDelList: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        editing: false,
        roleFilter: null,
        selectedRoleName: ''
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    const rtnval = await Api.get('/api/v1/adminmenus/ctgry/list');
    if(rtnval.result) {
        this.ctgryList = rtnval.data;
        const rtnvalue = await Api.get('/api/v1/adminmenus/role/list');
        if(rtnvalue.result) {
            this.roleList.push({id: null, roleName: '관리자권한목록'})
            this.roleList = this.roleList.concat(rtnvalue.data);
        }
        else {
            alert(rtnvalue.errormsg);
        }
    }
    else {
        alert(rtnval.errormsg);
    }
    this.fetching = false;
  },
  methods:{
    renderList() {
      this.allowedListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.allowedList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.allowedList.length); j++) {
        this.allowedListPaged.push(this.allowedList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.allowedList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    },

    setMainPage(pol) {
      this.allowedList.forEach((policy) => {
        policy.isMainPage = false;
      })

      this.allowedListPaged.forEach((policy) => {
        policy.isMainPage = false;
      })

      pol.isMainPage = true;
      this.allowedList.forEach((policy) => {
        if(policy.menuPage.id == pol.menuPage.id) {
          policy.isMainPage = true;
        }
      })
    },

    setDeleteFlag(pol) {
      pol.menuPage.flagToDel = true;
      this.allowedList.forEach((policy) => {
        if(policy.menuPage.id == pol.menuPage.id) {
          policy.menuPage.flagToDel = true;
        }
      })
    },

    async saveModification(option) {
      if(option) {
        var pageNewList = [];
        var pageSetList = [];
        var setListFlag = null;
        this.allowedList.forEach((policy) => {
          if(policy.id) {
            if(policy.menuPage.flagToDel) {
              if(policy.isMainPage) {
                setListFlag = -1;
                return;
              }
              this.pageDelList.push(policy.id);
            }
            else {
              pageSetList.push(policy);
              if(policy.isMainPage) {
                setListFlag = 1;
              }
            }
          }
          else {
            if(policy.menuPage.flagToDel) {
              if(policy.isMainPage) {
                setListFlag = -1;
                return;
              }
            }
            else {
              if(policy.isMainPage) {
                setListFlag = 0;
              }
              pageNewList.push(policy);
            }
          }
        })
        this.pageNewList = pageNewList;

        if(setListFlag == null) {
          alert('메인페이지 하나를 지정하세요.');
          this.pageDelList = [];
          return;
        }
        else if(setListFlag > 0) {
          this.pageSetList = pageSetList;
        }
        else if(setListFlag < 0) {
          alert('삭제대상 페이지는 메인페이지로 지정할 수 없습니다.');
          this.pageDelList = [];
          return;
        }

        this.fetching = true;
        const returnvalue = await Api.post('/api/v1/adminmenus/role/update', {
          id: this.roleFilter,
          roleName: this.selectedRoleName,
          pageNewList: this.pageNewList,
          pageSetList: this.pageSetList,
          pageDelList: this.pageDelList
        });
        if(returnvalue.result) {
          location.reload();
        }
        else {
          alert(returnvalue.errormsg);
        }
        this.fetching = false;
      }
      else {
        location.reload();
      }
      this.editing = false;
    },

    async switchRole() {
      this.currentPage = 1;
      if(this.roleFilter) {
        this.roleList.forEach((role) => {
          if(role.id == this.roleFilter) {
            this.selectedRoleName = role.roleName;
            this.allowedList = role.menuPolicyList.sort((a,b) => a.menuPage.menuCategoryId - b.menuPage.menuCategoryId);
          }
        })
      }
      else {
        this.allowedList = [];
      }

      this.pageNewList = [];
      this.editing = false;
      this.setPaging();
      this.renderList();
    },

    async setRole(option) {
      var roleNameAsIs = '';
      if(option) {
        this.roleList.forEach((role) => {
          if(role.id == this.roleFilter) {
            roleNameAsIs = role.roleName;
          }
        })
      }
      var roleName = prompt((option? '수정할' : '등록할') + ' 권한명을 입력하세요.', roleNameAsIs);
      if(!roleName) {
        alert('권한명은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/adminmenus/role/' + (option? 'update' : 'create'), {
        id: this.roleFilter? this.roleFilter : null,
        roleName: roleName
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removeRole() {
      const rtnvalue = await Api.get('/api/v1/adminmenus/ctgry/delete?roleId=' + this.roleFilter)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    addNewPage(page) {
      var already = false;
      this.pageNewList.forEach((policy) => {
        if(policy.menuPage.id == page.id) {
          alert('이미 추가된 페이지입니다.')
          already = true;
        }
      })

      this.allowedList.forEach((policy) => {
        if(policy.menuPage.id == page.id) {
          alert('이미 추가된 페이지입니다.')
          already = true;
        }
      })

      if(already) {
        return;
      }
      this.pageNewList.push({id: null, isMainPage: false, menuPage: page});
    },

    removeNewPage(page) {
      if(this.pageNewList.length >0) {
        this.pageNewList = this.pageNewList.filter((v) => v.menuPage.id != page.menuPage.id);
      }
    },

    commitAddingPage() {
      if(this.pageNewList.length >0) {
        this.allowedList = this.allowedList.concat(this.pageNewList);
        this.editing = true;
        this.setPaging();
        this.renderList();
      }
      this.closeModal();
    },

    cancelAddingPage() {
      this.pageNewList = [];
      this.closeModal();
    },

    closeModal() {
			window.$('#addPage').modal('hide');
		},
  }
}
</script>

