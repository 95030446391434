<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">식샤를 합시다</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>파티원 관리</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">{{ partyNm }}</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10명</option>
                <option value="20">페이지당 20명</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="storeSelected" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in storeList" :key="i" :value="item.id">{{ item.storeName }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="addStore()" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="storeSelected" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="editStore()" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="storeSelected" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeStore()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="ps-2" :style="'margin-left: auto; padding-left: ' + (storeSelected? '100px' : '250px') + ' !important;'"><button class="fkr-set2 fs-095" @click="saveModification()" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">변경사항 저장</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findUsr()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findUsr()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="회원검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:45%"><span class="fkr-set1 fs-9">이메일</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">이름</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">연락처</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">가입일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">파티원 지정</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in usrListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:45%"><span class="fkr-set2 fs-9">{{item.email}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.fullName}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.phone}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%">
                    <div class="custom-control custom-checkbox">
                      <input name="checkAdmin" type="checkbox" :id="'checkbox'+index" :checked="partyMembers.indexOf(item.usrId) >= 0" @change="clickCheckbox(item.usrId)" class="custom-control-input">
                      <label :for="'checkbox'+index" class="custom-control-label"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; findUsrAll()" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

            <div class="x_div_end py-4">
              <div class="py-2"><button class="fkr-set2 fs-095" style="width:120px; height:37px; margin-right: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;" onclick="location.href = '/admin/site/party-list'">파티 목록</button></div>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "PartyEnlist",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: false,
        searchWord: '',
        numOfUsr: '',
        usrList:[],
        usrListPaged:[],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        partyNm: '',
        partyMembers: [],
        originalMembers: [],
        newMembers: [],
        deleteList: [],
        storeList: [],
        storeSelected: ''
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    await this.findUsrAll();
    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async findUsr(){
      this.fetching = true;
      var rtnval = await Api.post('/api/v1/admin/usr/mng/findAll/usrAll', {page: this.currentPage, window: this.pageSize, searchWord: this.searchWord});
      if(rtnval.result){
        rtnval.data.findAllUsrDtoList.forEach((usr) => {
          usr.usrId = usr.id
        })
        this.usrList = rtnval.data.findAllUsrDtoList;
      }
      this.fetching = false;
      this.setPaging();
      this.renderList();
    },

    async findUsrAll(){
      this.fetching = true;
      this.usrList = [];
      this.partyMembers = [];
      var rtnval = await Api.get('/api/v1/sicksha/party/get?partyId=' + this.$route.params.partyId);
      if(rtnval.result){
        this.usrList = rtnval.data.memberInfoList;
        this.partyNm = rtnval.data.partyNm;
        this.usrList.forEach((usr) => {
          this.partyMembers.push(usr.usrId);
          this.originalMembers.push(usr.usrId);
        })
        this.storeList = rtnval.data.storeList.filter((store) => store.storeLink.length >1);
        this.storeList = [{id: '', storeName: '밥집목록'}].concat(this.storeList);
      }
      this.fetching = false;
    },

    renderList() {
      this.usrListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.usrList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.usrList.length); j++) {
        this.usrListPaged.push(this.usrList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.numOfUsr / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    },

    clickCheckbox(usrId){
      if(this.partyMembers.indexOf(usrId) >=0) {
        //check off
        this.partyMembers = this.partyMembers.filter((val) => val != usrId);
        if(this.newMembers.indexOf(usrId) >=0) {
          this.newMembers = this.newMembers.filter((val) => val != usrId);
        }
        if(this.originalMembers.indexOf(usrId) >=0) {
          this.deleteList.push(usrId);
        }
      }
      else {
        //check on
        this.partyMembers.push(usrId);
        if(this.deleteList.indexOf(usrId) >=0) {
          this.deleteList = this.deleteList.filter((val) => val != usrId);
        }
        if(this.originalMembers.indexOf(usrId) <0) {
          this.newMembers.push(usrId);
        }
      }
    },

    async saveModification() {
      let usrIdList = [];
      let partyIdList = [];
      this.newMembers.forEach((usr) => {
        usrIdList.push(usr);
        partyIdList.push(parseInt(this.$route.params.partyId));
      })
      this.deleteList.forEach((usr) => {
        usrIdList.push(usr);
        partyIdList.push(null);
      })

      var rtnval = await Api.post('/api/v1/sicksha/party/enlist', {usrIdList : usrIdList, partyIdList: partyIdList});
      if(rtnval.result){
        alert('저장되었습니다.');
        location.reload();
      }
    },

    async addStore() {
      var storeName = prompt('등록할 가게명을 입력하세요.');
      var storeLink = prompt('등록할 가게의 공유 링크를 입력하세요.');
      if(!storeName || !storeLink) {
        alert('가게명 및 가게 링크는 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/sicksha/store/create?partyId=' + this.$route.params.partyId, {
        storeName: storeName,
        storeLink: storeLink,
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async editStore() {
      var storeName = prompt('변경할 가게명을 입력하세요.');
      var storeLink = prompt('변경할 가게의 공유 링크를 입력하세요.');
      if(!storeName || !storeLink) {
        alert('가게명 및 가게 링크는 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/sicksha/store/update', {
        storeName: storeName,
        storeLink: storeLink,
        id: this.storeSelected
      })
      if(rtnvalue.result) {
        alert('정상적으로 수정되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removeStore() {
      const rtnvalue = await Api.get('/api/v1/sicksha/store/delete?storeId=' + this.storeSelected)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    }
  }
}
</script>

