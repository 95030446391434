<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>뉴스목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">등록 기사 수: {{ newsList.length }}</span>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <button class="fkr-set2 fs-095" @click="openEditNews(null)" style="width:80px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">뉴스 등록</button>
            </div>
          </div>

            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:50%"><span class="fkr-set1 fs-9">제목</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">신문사</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">발행일</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:5%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in newsListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div m_line" style="width:50%; padding-left: 20px;"><span class="fkr-set2 fs-9">{{item.title}}</span></div>
                  <div class="x_div_center m_line" style="width:15%;"><span class="fkr-set2 fs-9">{{item.publisher}}</span></div>
                  <div class="x_div_center m_line" style="width:15%;"><span class="fkr-set2 fs-9">{{item.publishedDate? item.publishedDate.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:15%;"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:5%;"><span class="fkr-set2 fs-9"><a href="javascript:void(0)" @click="openEditNews(item)"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="editNews" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">뉴스 편집</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="w-100">
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">제목</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:460px; height:36px" v-model="titleInput"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">신문사</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="publisherInputed"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">발행일</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <span v-show="editMode && !editDate" class="fkr-set2" style="margin-left:5px">{{publishedDate? publishedDate.split('T')[0] : null}}<i class="fa fa-edit" style="margin-left:15px; cursor:pointer" @click="editDate = true;"></i></span>
                        <Datepicker v-show="!editMode || editDate" @update-date="setPublishedDate" class="form-control fkr-set2 fc-w5 text-center" style="width:180px; height:30px; border-radius:4px; border:1px solid #c9c9c9"/>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">기사 URL</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:320px; height:36px" v-model="linkInputed"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">썸네일이미지</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <img id="thumbImg" style="width: 50%; margin: 20px" :src="thumbnailFlPath">
                        <input type="file" @change="fileHandling" class="form-control fkr-set2 fc-w5 text-center" style="width:40%; height:36px; margin: 0 0 10px 20px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                      </div>
                  </div>

                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2" v-if="editMode"><button @click="removeArticle()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">삭제</span></button></div>
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                <div class="px-2"><button @click="setArticle()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">{{ editMode? '수정' : '등록' }}<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "NewsList",
  components: {
		AdminLNB, Datepicker
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        newsList: [],
        newsListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        newsId: null,
        titleInput: '',
        publisherInputed: '',
        publishedDateInput: null,
        publishedDate: null,
        linkInputed: '',
        thumbnailFlPath: null,
        thumbFileToUpload: null,
        thumbNameToUpload: '',
        editMode: true,
        editDate: false,
        s3region: process.env.VUE_APP_S3_REGION,
        s3bucket: process.env.VUE_APP_S3_BUCKET
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);
    window.$('.datepicker').css('z-index', 10002)

    await this.fetchNews();
    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchNews() {
      this.fetching = true;
      this.newsList = [];
      this.ctgryList = [];
      const returnvalue = await Api.get('/api/v1/news/news/list');
      if(returnvalue.result) {
          this.newsList = returnvalue.data.sort((a,b) => b.newsId - a.newsId);
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    renderList() {
      this.newsListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.newsList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.newsList.length); j++) {
        this.newsListPaged.push(this.newsList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.newsList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    openEditNews(newsToEdit) { 
      this.editDate = false;
      if(newsToEdit) {
        this.newsId = newsToEdit.newsId;
        this.titleInput = newsToEdit.title;
        this.publisherInputed = newsToEdit.publisher;
        this.publishedDateInput = null;
        this.publishedDate = newsToEdit.publishedDate;
        this.linkInputed = newsToEdit.link;
        this.thumbnailFlPath = 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + newsToEdit.thumbnailFlPath;
        this.editMode = true;
      }
      else {
        this.newsId = null;
        this.titleInput = '';
        this.publisherInputed = '';
        this.publishedDateInput = null;
        this.publishedDate = null;
        this.linkInputed = '';
        this.thumbnailFlPath = null;
        this.editMode = false;
      }
      window.$('#editNews').modal('show');
    },

    setPublishedDate(date) {
        this.publishedDateInput = new Date(date).toISOString();
    },

    fileHandling(e) {
      this.thumbFileToUpload = e.target.files[0];
      this.thumbNameToUpload = e.target.files[0].name;
      this.thumbnailFlPath = URL.createObjectURL(e.target.files[0]);
    },

    async setArticle() {
      if(!this.publishedDateInput) {
        alert('발행일은 필수항목입니다.');
        return;
      }

      this.submitting = true;

      var newThumbFlId = null;

      if(this.thumbFileToUpload) {
        const formData = new FormData();
        var attchFlCtgry = [];
        var attchOrgNmList = [];
        var dirNmList = [];

        if(this.thumbFileToUpload) {
          formData.append("attchmntList", this.thumbFileToUpload);
          attchFlCtgry.push(null);
          attchOrgNmList.push(this.thumbNameToUpload);
          dirNmList.push('platform/news');
        }

        var request = {
            attchFlCtgry: attchFlCtgry,
            attchOrgNmList: attchOrgNmList,
            dirNmList: dirNmList,
        }
        formData.append(
            "request",
            new Blob([JSON.stringify(request)], { type: "application/json" })
        );
        const rtnval = await Api.post('/api/v1/common/set/attachments', formData)
        if(rtnval.result) {
          newThumbFlId = rtnval.data[0];
        }
        else {
          alert(rtnval.errormsg)
          this.submitting = false;
          return;
        }
      }

      const rtnval = await Api.post('/api/v1/news/news/' + (this.editMode? 'update' : 'create'), {
        newsId: this.newsId,
        title: this.titleInput,
        publisher: this.publisherInputed,
        publishedDate: this.publishedDateInput,
        link: this.linkInputed,
        thumbnailFlId: newThumbFlId
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        window.$('#editNews').modal('hide');
        await this.fetchNews();
        this.setPaging();
        this.renderList();
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    async removeArticle() {
      if(confirm('해당 기사를 삭제하시겠습니까?')) {
        this.submitting = true;
        const rtnval = await Api.get('/api/v1/news/news/delete?newsId=' + this.newsId)
        if(rtnval.result) {
          alert('정상적으로 삭제되었습니다.');
          window.$('#editNews').modal('hide');
          await this.fetchNews();
          this.setPaging();
          this.renderList();
        }
        else {
          alert(rtnval.errormsg)
        }
        this.submitting = false;
      }
    },

    closeModal() {
			window.$('#editNews').modal('hide');
		},
  }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
</style>
