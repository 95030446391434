<template>
  <div class="d-flex">
    <AdminLNB :user="user"/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">실시간 견적산출</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>자재정보 등록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="pg_m_set" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>자재명</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="stdMtrlNm"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>대표업체</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="rprsntCmpny"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>대표 이미지</strong></span></td>
                  <td class="">
                    <img id="logoImg" style="width: 50%; margin: 20px" :src="logoPath">
                    <input type="file" @change="fileHandling" class="form-control fkr-set2 fc-w5 text-center" style="width:40%; height:36px; margin: 0 0 10px 20px; border-radius:4px; background-color:#f6f2f2; border:1px solid #4e0000" placeholder="">
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>관련 건축사양</strong></span></td>
                  <td class="">
                    <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px; margin-left: 20px;" v-model="archiSpcId">
                      <option v-for="(item, i) in archiSpcList" :key="i" :value="item.archiSpcId">{{ item.archiSpcNm }}</option>
                    </select>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>단가</strong></span></td>
                  <td class="text-center" width="70%"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px; margin-left: 20px;" v-model="price"></td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>분류</strong></span></td>
                  <td class="text-center" width="50%" style="padding: 10px !important">
                    <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                        <div class="x_div_center" style="width:50%"><span class="fkr-set2 fc-w1">분류제목</span></div>
                        <div class="x_div_center" style="width:40%"><span class="fkr-set2 fc-w1">분류단계</span></div>
                        <div class="x_div_center" style="width:10%"><span class="fkr-set2 fc-w1"></span></div>
                    </div>

                    <div class="x_div_center ls_bg1" style="height:55px" v-for="(ctgry, index) in ctgryList" :key="index">
                        <div class="x_div_center px-1" style="width:50%">
                          <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px; margin-left: 20px;" v-model="ctgry.mtrlCtgryTypeId">
                            <option v-for="(item, i) in ctgryTypeList" :key="i" :value="item.mtrlCtgryTypeId">{{ item.mtrlCtgryTypeNm }}</option>
                          </select>
                        </div>
                        <div class="x_div_center px-1" style="width:40%">
                          <input type="number" v-model="ctgry.ctgryOrder" class="form-control fkr-set2 fc-w5 text-center" style="width:180px; height:36px">
                        </div>
                        <div class="x_div_center px-1" style="width:10%">
                          <a href="javascript:void(0)" class="rm_frm" @click="removeCtgry(ctgry, index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a>
                        </div>
                    </div>
                  </td>
                  <td class="text-center" width="20%" style="padding: 20px !important">
                    <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="addCtgry()"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>
                  </td>
                </tr>
                <tr style="height:55px; border-bottom:1px solid #c9c9c9">
                  <td class="text-center br_0" width="30%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>자재사양</strong></span></td>
                  <td class="text-center" width="70%"><textarea class="form-control fkr-set2" style="margin: 10px" v-model="specDesc"></textarea></td>
                </tr>
              </table>
            </div>

          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveMtrlInfo()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
            <div v-if="$route.params.stdMtrlId > 0" class="py-2"><button class="fkr-set2 fs-095" @click="deleteMtrlInfo()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="moveToList()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">{{ $route.params.stdMtrlId > 0? '목록' : '취소' }}</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'

export default {
  name: "UnitPriceEdit",
  components: {
		AdminLNB
	},
  watch: {
      price(val){
          const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
          // 한글, 영문 체크
          if(reg.exec(val)!==null) val = String(val).replace(/[^0-9]/g,'');
          this.price = String(val).replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          // .... 만 입력하게 될 경우 체크
          if(isNaN(parseFloat(val))) this.price = '';
      },
  },
  data(){
    return {
      stdMtrlNm: '',
      rprsntCmpny: '',
      archiSpcId: null,
      price: 0,
      specDesc: '',
      logoPath: '',
      logoFileToUpload: null,
      logoNameToUpload: null,
      archiSpcList: [],
      ctgryList: [],
      ctgryTypeList: [],
      orderValidate: [],
      deleteList: [],
      updateList: [],
      newList: [],
      fetching: true,
      submitting: false
    }
  },
  props: {
    user: {
        type: Object,
        default: null
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    const rtnval = await Api.get('/api/v1/mtrl/list/mtrlctgrytype');
    // const rtnval = {result: true, data: [{id: 1, mtrlCtgryTypeNm: '창호', crtDt: null}, {id: 2, mtrlCtgryTypeNm: '마감', crtDt: null}, {id: 3, mtrlCtgryTypeNm: '내장', crtDt: null}, {id: 4, mtrlCtgryTypeNm: '외장', crtDt: null}]}
    ///////////////////////////////////////////////////////////
    if(rtnval.result) {
        this.ctgryTypeList = rtnval.data.findMtrlCtgryTypeDtoList.sort((a,b) => b.mtrlCtgryTypeId - a.mtrlCtgryTypeId);
        this.ctgryTypeList = [{mtrlCtgryTypeId: '', mtrlCtgryTypeNm: '분류', crtDt: null}].concat(this.ctgryTypeList);
        const returnval = await Api.get('/api/v1/mtrl/list/archispc');
        // const returnval = {result: true, data: {resultList: [
        //   {archiSpcId: 1, archiSpcNm: '총창길이', unit: '자', recentUpdateDt: '2024-05-31T12:12:12', recentUpdater: '엠몽이'},
        //   {archiSpcId: 2, archiSpcNm: '벽량', unit: '㎡', recentUpdateDt: '2024-05-25T12:12:12', recentUpdater: '강백훈'}
        // ]}}
        ///////////////////////////////////////////////////////////////////////////////////
        if(returnval.result) {
          this.archiSpcList = returnval.data.findArchiSpcDtoList.sort((a,b) => b.archiSpcId - a.archiSpcId);
          if(this.$route.params.stdMtrlId > 0) {
            const returnvalue = await Api.get('/api/v1/mtrl/find/stdmtrl?stdMtrlId=' + this.$route.params.stdMtrlId);
            if(returnvalue.result) {
              this.stdMtrlNm = returnvalue.data.stdMtrlNm;
              this.rprsntCmpny = returnvalue.data.rprsntCmpny;
              this.archiSpcId = returnvalue.data.archiSpcId;
              this.price = returnvalue.data.price;
              this.specDesc = returnvalue.data.specDesc;
              this.ctgryList = returnvalue.data.listMtrlCtgryList;
              this.ctgryList.forEach((ctgry) => {
                ctgry.stdMtrlId = this.$route.params.stdMtrlId;
              })

              if(returnvalue.data.imgFileNm) {
                var tokens = returnvalue.data.imgFileNm.split('.');
                var mimeType = tokens[tokens.length-1].toLowerCase();
                var fileType = 'image/' + mimeType;
  
                const byteCharacters = atob(returnvalue.data.img);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: fileType});
                this.logoPath = URL.createObjectURL(blob);
              }
            }
            else {
              alert(returnvalue.errormsg)
            }
          }
        }
        else {
            alert(returnval.errormsg);
        }
    }
    else {
        alert(rtnval.errormsg);
    }
    this.fetching = false;
  },

  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'N'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    fileHandling(e) {
      this.logoFileToUpload = e.target.files[0];
      this.logoNameToUpload = e.target.files[0].name;

      window.$('#logoImg').attr('src', URL.createObjectURL(e.target.files[0]));
    },

    addCtgry() {
        this.ctgryList.push({mtrlCtgryId: null, mtrlCtgryTypeId: null, ctgryOrder: null, stdMtrlId: this.$route.params.stdMtrlId > 0? this.$route.params.stdMtrlId : null})
    },
    removeCtgry(ctgry, index) {
        if(ctgry.mtrlCtgryId) {
          this.deleteList.push(ctgry)
        }
        this.ctgryList = this.ctgryList.filter((v,i) => i != index );
    },

    async saveMtrlInfo() {
      if(!this.stdMtrlNm) {
        alert('자재명은 필수 입력사항입니다.');
        return;
      }
      if(!this.price || this.price <= 0) {
        alert('단가는 필수 입력사항입니다.');
        return;
      }
      if(!this.archiSpcId) {
        alert('관련 건축사양을 지정해야합니다.');
        return;
      }
      if(this.ctgryList.length == 0) {
        alert('분류를 하나 이상 지정해야합니다.');
        return;
      }
      else {
        this.orderValidate = [];
        for(var i=0; i < this.ctgryList.length; i++) {
          if(!this.ctgryList[i].ctgryOrder) {
            alert('각 분류단계는 1 이상의 값을 가져야합니다.');
            return;
          }
          else if(this.orderValidate[this.ctgryList[i].ctgryOrder]) {
            alert('분류단계가 서로 중복될 수 없습니다.');
            return;
          }
          this.orderValidate[this.ctgryList[i].ctgryOrder] = true;
        } 
      }

      this.ctgryList.forEach((ctgry) => {
        if(!ctgry.mtrlCtgryId) {
          this.newList.push(ctgry);
        }
        else {
          this.updateList.push(ctgry);
        }
      })

      const formData = new FormData();
      formData.append("attchmnt", this.logoFileToUpload);
      var request = {
        stdMtrlNm: this.stdMtrlNm,
        rprsntCmpny: this.rprsntCmpny,
        imgFileNm: this.logoNameToUpload,
        archiSpcId: this.archiSpcId,
        price: Number(this.price.replace(/[^\d]+/g, '')),
        specDesc: this.specDesc,
        mtrlCtgryTypeAddList: this.newList,
        mtrlCtgryTypeUpdateList: this.updateList,
        mtrlCtgryTypeDeleteList: this.deleteList,
        stdMtrlId: this.$route.params.stdMtrlId > 0? this.$route.params.stdMtrlId : null
      }

      formData.append(
          "request",
          new Blob([JSON.stringify(request)], { type: "application/json" })
      );

      this.submitting = true;

      const rtnvalue = await Api.post('/api/v1/mtrl/' + (this.$route.params.stdMtrlId > 0? 'update' : 'set') + '/stdmtrl', formData);
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.href = '/admin/estmt/unit-price';  
      }
      else {
        alert(rtnvalue.errormsg)
      }
      this.submitting = false;
    },

    async deleteMtrlInfo() {
      if(confirm('정말로 삭제하시겠습니까?')) {
        const rtnvalue = await Api.get('/api/v1/mtrl/delete/stdmtrl?stdMtrlId=' + this.$route.params.stdMtrlId)
        if(rtnvalue.result) {
          alert('정상적으로 삭제되었습니다.')
          location.href = '/admin/estmt/unit-price';  
        }
        else {
          alert(rtnvalue.errormsg)
        } 
      }
    },

    moveToList(){
      location.href = '/admin/estmt/unit-price';  
    }
  }
}
</script>